import React from 'react';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import useStyles from './ResRec.styles'
import CircularProgress from '@material-ui/core/CircularProgress';
import {  useHistory, useLocation } from 'react-router-dom';
import { UserContext } from 'containers/App/UserContext'

const RegistrationFail = () => {
    const classes = useStyles();
    const location = useLocation();
    const history = useHistory();
    const userContext = React.useContext(UserContext);

    React.useEffect(() => {
        const searchParams = new URLSearchParams(location.search);

        const statusType = searchParams.get('t');
        const statusCode = searchParams.get('c');
        const redirectTo = searchParams.get('r');

        userContext.response.status = { code: statusCode, type: statusType };

        history.push(redirectTo);
    }, []);

    return (
        <Container component="main" maxWidth="xs">
            <Box className={classes.paper}>
                <Box display="flex" justifyContent="center">
                    <Box className={classes.logo} />
                </Box>
                <Box className={classes.loaderContainer} display="flex" alignItems="center">
                    <CircularProgress />
                </Box>
                <Box>
                    <Typography variant="h6" color="textSecondary" align="center">
                        Loading...
                    </Typography>
                </Box>
            </Box>
        </Container>
    );
}

export default RegistrationFail;