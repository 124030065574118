import React from 'react';

import { ApolloProvider } from '@apollo/react-hooks';
import ApolloClient from 'apollo-boost';

//import adminTheme from 'adminTheme';
//import userTheme from 'userTheme';
import theme from 'theme';

import {
    HashRouter,
    Switch,
    Route,
    Redirect
} from 'react-router-dom';

import {
    CssBaseline,
} from '@material-ui/core';

import {
    ThemeProvider
} from '@material-ui/core/styles';

import { gql } from "apollo-boost";

import PublicRoute from 'containers/App/PublicRoute';
import PrivateRoute from 'containers/App/PrivateRoute';
//import LandingContainer from 'containers/Landing';
import Login from 'containers/Login';
import Registration from 'containers/Registration';
import AuthEmail from 'containers/AuthEmail/index';
import ResRec from 'containers/AuthEmail/ResRec'
import Dashboard from 'containers/Dashboard';
import Users from 'containers/Users';
import Settings from 'containers/Settings';
import Platform from 'containers/Platform';
import ManagementPortal from 'containers/ManagementPortal';
import APIKeys from 'containers/APIKeys';
import Master from 'containers/Master';
import ForgotPassword from 'containers/ForgotPassword';
import ResetPassword from 'containers/ForgotPassword/ResetPassword';
import { UserContext } from './UserContext';

const API_HOST = window._env_.REACT_APP_API_HOST !== undefined ? window._env_.REACT_APP_API_HOST : 'http://localhost:4000';

console.log('process.env.REACT_APP_API_HOST-',  process.env.REACT_APP_API_HOST);

console.log('API_HOST-', API_HOST);

const client = new ApolloClient({
    uri: `${API_HOST}/graphql`,
    request: (operation) => {
        const authUser = JSON.parse(sessionStorage.getItem('amz-user'))
        operation.setContext({
            headers: {
                authorization: authUser?.token ? `Bearer ${authUser.token}` : ''
            }
        })
    },
    onError: ({ graphQLErrors, networkError }) => {
        if (graphQLErrors) {
            console.log(graphQLErrors, "graphQLErrors");
        }

        if (networkError) {
            console.log("networkError");
            console.dir(networkError);
            if (networkError.statusCode == 401) {
                sessionStorage.clear();
                window.location.assign("#/login");
            }
        }
    },
});

const App = () => {
    const [isAdmin, setIsAdmin] = React.useState(false);
    const [responseStatus, setResponseStatus] = React.useState(undefined);

    const response = {
        get status() {
            return responseStatus;
        },
        set status(value) {
            setResponseStatus(value)
        }
    }

    window.addEventListener('storage', () => {
        console.log("Storage Changed...");
        const authUser = JSON.parse(sessionStorage.getItem('amz-user'));
        const isAdminUser = authUser?.roles ? (authUser.roles).indexOf('admin') > -1 : false;
        setIsAdmin(isAdminUser);
    });

    console.log(isAdmin, "isAdmin");

    return (
        <ApolloProvider client={client}>
            <ThemeProvider theme={theme}>
                <CssBaseline />
                <UserContext.Provider value={{ response }}>
                    <HashRouter>
                        <Switch>
                            <PublicRoute exact path={'/login'} component={Login} />
                            <PublicRoute exact path={'/registration'} component={Registration} />
                            <PublicRoute exact path={'/forgot-password'} component={ForgotPassword} />
                            <PublicRoute exact path={'/auth-email'} component={AuthEmail} />
                            <PublicRoute exact path={'/res-rec'} component={ResRec} />
                            <PublicRoute exact path={'/reset-password'} component={ResetPassword} />
                            <Master>
                                <PrivateRoute exact path={'/users'} component={Users} />
                                <PrivateRoute exact path={'/settings'} component={Settings} />
                                <PrivateRoute exact path={'/platform'} component={Platform} />
                                <PrivateRoute exact path={'/management-portal'} component={ManagementPortal} />
                                <PrivateRoute exact path={'/api-keys'} component={APIKeys} />
                                <PrivateRoute exact path={'/'} component={Dashboard} />
                                {/* <PrivateRoute exact path={'/'} component={LandingContainer} /> */}
                            </Master>
                        </Switch>
                    </HashRouter>
                </UserContext.Provider>
            </ThemeProvider>
        </ApolloProvider>
    );
};

export default App;

