import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import { Formik, Form, Field } from 'formik';
import { TextField } from 'formik-material-ui';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import { Redirect, useLocation, useHistory } from "react-router-dom";
import { FormattedMessage, defineMessages, useIntl } from 'react-intl';
import useAlert, { Success, Error } from 'components/Alert/CustomAlert';
import { regex } from 'config';

import { gql } from 'apollo-boost';
import { useQuery, useMutation } from '@apollo/react-hooks';

import useStyles from './styles';
import { CircularProgress } from '@material-ui/core';

const AUTH_EMAIL = gql`
    mutation authEmail($token:String!) {
    authEmail(token:$token){
        code
        success
        message
    }
}
`;

const messages = defineMessages({
    verificationEmail: {id: 'AuthEmail.VerificationEmail', description: 'AuthEmail VerificationEmail', defaultMessage: 'Please check your inbox to verify your account'},
});

const Copyright = () => {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            {'Copyright © '}
            {new Date().getFullYear()}{' '}
            {'AmZetta Technologies, All Rights Reserved.'}
        </Typography>
    );
}

export default function AuthEmail() {
    const classes = useStyles();
    const location = useLocation();
    const history = useHistory();
    const {open: openSuccess, message: messageSuccess, handleOpen: showSuccess, handleClose: closeSuccess} = useAlert();
    const {open: openError, message: messageError, handleOpen: showError, handleClose: closeError} = useAlert();

    const [authEmail, { loading, error, data }] = useMutation(AUTH_EMAIL, {
        onCompleted(data) {
            if (data && data.authEmail.success === true) {
                showSuccess(messages.VerificationEmail);
                history.push(`/res-rec?t=SUCCESS&c=${data.authEmail.code}&r=/login`);
            }
            else {
                //showError(data?.authEmail);
                if(data.authEmail.code === "E_AUTH_EMAIL_TOKEN_RESEND") {
                    history.push(`/res-rec?t=ERROR&c=${data.authEmail.code}&r=/login`);
                }
                else {
                    history.push(`/res-rec?t=ERROR&c=${data.authEmail.code}&r=/registration`);
                }
            }
        },
        onError(error) {
            showError(error);
            history.push(`/res-rec?t=ERROR&c=E_AUTH_EMAIL_FAILED&r=/registration`);
        }
    });

    const verifyEmail = async() => {
        const searchParams = new URLSearchParams(location.search);
        const token = searchParams.get('t') ? searchParams.get('t') : '';
        await authEmail({ variables: { token } });
    }

    React.useEffect(() => {
        verifyEmail();
    }, []);

    return (
        <Container component="main" maxWidth="xs">
            <Success open={openSuccess} message={messageSuccess} onClose={closeSuccess} />
            <Error open={openError} message={messageError} onClose={closeError} />
            <CssBaseline />
            <Box className={classes.paper}>
                <Box display="flex" justifyContent="center">
                    <Box className={classes.logo} />
                </Box>
                <Box className={classes.loaderContainer} display="flex" alignItems="center">
                    <CircularProgress />
                </Box>
                <Box>
                    <Typography variant="h6" color="textSecondary" align="center">
                        Loading...
                    </Typography>
                </Box>
                <Box mt={5}>
                    <Copyright />
                </Box>
            </Box>
        </Container>
    );
}