import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import CloseIcon from '@material-ui/icons/Close';
import { Formik, Form, Field } from 'formik';
import { TextField } from 'formik-material-ui';
import { FormattedMessage, defineMessages, useIntl } from 'react-intl';

import {
    Dialog,
    IconButton,
    Typography,
    Button,
    Grid,
    Tooltip,
    MenuItem,
    Checkbox,
    ListItemText,
    CircularProgress,
    FormControlLabel,
} from '@material-ui/core';

import { gql } from 'apollo-boost';
import { useQuery, useMutation } from '@apollo/react-hooks';
import FormikMultiAutocomplete from 'components/FormikAutoComplete';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';

import useStyles from './AssignManagementPortal.styles';

const styles = theme => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});

const DialogTitle = withStyles(styles)(props => {
    const { children, classes, onClose, ...other } = props;
    const { formatMessage } = useIntl();
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
        <Typography variant="h6">{children}</Typography>
        {onClose ? (
            <Tooltip title={formatMessage(messages.toolTipClose)}>
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            </Tooltip>
        ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles(theme => ({
    root: {
        padding: theme.spacing(3),
    },
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
    },
}))(MuiDialogActions);

const icon = <CheckBoxOutlineBlankIcon />;
const checkedIcon = <CheckBoxIcon />;

const ASSIGN_MANAGEMENT_PORTAL = gql`
    mutation assignPlatformsToMP($mpUUID: String!, $platformKeys: [String!]!) {
        assignPlatformsToMP(mpUUID: $mpUUID, platformKeys: $platformKeys) {
            code
            success
            message
        }
    }
`;

const messages = defineMessages({
    AssignManagementPortalDialogHeader: {id: 'AssignManagementPortal.dialog.header', description: 'Assign Management Portal dialog header', defaultMessage:'Assign Management Portal'},

    AssignManagementPortalLabelManagementPortalName: {id: 'AssignManagementPortal.label.ManagementPortalName', description: 'Assign Management Portal label - ManagementPortalName', defaultMessage:'Management Portal Name'},
    AssignManagementPortalLabelPlatforms: {id: 'AssignManagementPortal.label.Platforms', description: 'Assign Management Portal label - Platforms', defaultMessage:'Platforms'},

    AssignManagementPortalButtonAssign: {id: 'AssignManagementPortal.button.assign', description: 'Assign Management Portal button - Assign', defaultMessage:'Assign'},
    AssignManagementPortalButtonCancel: {id: 'AssignManagementPortal.button.cancel', description: 'Assign Management Portal button - Cancel', defaultMessage:'Cancel'},
    
    AssignManagementPortalSuccess: {id: 'AssignManagementPortal.Success', description: 'Assign Management Portal Success', defaultMessage: 'Management Portal assigned successfully.'},

    toolTipClose: {id: 'ToolTip.Close', description: 'ToolTip For Close Icon', defaultMessage: 'Close'},
});

const AssignManagementPortal = props => {
    const classes = useStyles();
    const { formatMessage } = useIntl();
    const [open, setOpenDialog] = React.useState(false);
    const { mpData, showSuccess, showError, notAssignedPlatforms } = props;

    const [assignPlatformsToMP, { loading, error, data }] = useMutation(ASSIGN_MANAGEMENT_PORTAL, {
        onCompleted(data) {
            console.log(data, 'onCompleted assignPlatformsToMP');
            if (data && data.assignPlatformsToMP.success === true) {
                showSuccess(messages.AssignManagementPortalSuccess);
            }
            else {
                showError(data?.assignPlatformsToMP);
            }
            setOpenDialog(false);
        },
        onError(error) {
            showError(error);
            setOpenDialog(false);
        }
    });

    const handleClickOpen = event => {
        event.stopPropagation();
        setOpenDialog(true);
    };

    const handleClose = () => {
        setOpenDialog(false);
    };

    return (
        <div onClick={e => e.stopPropagation()}>
        <Button variant="outlined" color="primary" onClick={handleClickOpen}>
            <FormattedMessage {...messages.AssignManagementPortalDialogHeader} />
        </Button>
        <Formik
            initialValues={{
                mpUUID: "",
                platformKeys: [],
            }}
            validate={values => {
                const errors = {};

                if (!values.mpUUID) {
                    errors.mpUUID = 'Required';
                }
                
                if (!values.platformKeys.length) {
                    errors.platformKeys = 'Required';
                }
                
                return errors;
            }}
            onSubmit={async (values, { setSubmitting, resetForm }) => {

                await assignPlatformsToMP({ variables: { ...values } });

                setSubmitting(false);
                resetForm();
            }}
        >
        {({ submitForm, isSubmitting, values, setFieldValue }) => (
            <Form>
                <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open} classes={{ paper: classes.dialogPaper }} onKeyPress= {(e) => { if(!isSubmitting && e.which === 13) {submitForm()}}}>
                    <DialogTitle id="customized-dialog-title" onClose={handleClose}>
                        <FormattedMessage {...messages.AssignManagementPortalDialogHeader} />
                    </DialogTitle>
                    <DialogContent dividers>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Field component={TextField} type="text" name="mpUUID" label={formatMessage(messages.AssignManagementPortalLabelManagementPortalName)} select fullWidth variant="outlined" margin="dense" InputLabelProps={{ shrink: true, }}>
                                    {
                                        mpData?.map(mp => (
                                            <MenuItem key={mp._id} value={mp._id}>
                                                {mp.mpName}
                                            </MenuItem>
                                        ))
                                    }
                                </Field>
                            </Grid>
                            <Grid item xs={12}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            edge="start"
                                            checked={values?.platformKeys?.length && values?.platformKeys?.length === notAssignedPlatforms?.length}
                                            indeterminate={values?.platformKeys?.length && values?.platformKeys?.length !== notAssignedPlatforms?.length}
                                            tabIndex={-1}
                                            disableRipple
                                            inputProps={{ 'aria-labelledby': `checkbox-select-all` }}
                                            onClick={
                                                (event) => {
                                                    if (event.target.checked) {
                                                        setFieldValue('platformKeys', notAssignedPlatforms);
                                                    }
                                                    else {
                                                        setFieldValue('platformKeys', []);
                                                    }
                                                }
                                            }
                                        />
                                    }
                                    label={values?.platformKeys?.length && values?.platformKeys?.length === notAssignedPlatforms?.length ? "Remove All Platforms" : "Select All Platforms"}
                                    className={classes.formControl}
                                />
                            </Grid>
                            
                            <Grid item xs={12}>
                                <Field
                                    name="platformKeys"
                                    component={FormikMultiAutocomplete}
                                    options={notAssignedPlatforms}
                                    getOptionLabel={(option) => option}
                                    renderOption={(option, { selected }) => (
                                        <React.Fragment>
                                            <Checkbox
                                                icon={icon}
                                                checkedIcon={checkedIcon}
                                                style={{ marginRight: 8 }}
                                                checked={selected}
                                            />
                                            {option}
                                        </React.Fragment>
                                    )}
                                    textFieldProps={{ fullWidth: true, variant: 'outlined', margin: 'dense', label: 'Platforms' }}
                                />
                            </Grid>
                            {/* <Grid item xs={12}>
                                <Field component={TextField} type="text" name="platformKeys" label={formatMessage(messages.AssignManagementPortalLabelPlatforms)} select fullWidth variant="outlined" margin="dense" InputLabelProps={{ shrink: true, }} inputProps={{ multiple: true, renderValue: (selected) => selected.join(', ') }}>
                                    {
                                        notAssignedPlatforms.map(pKey => (
                                            <MenuItem key={pKey} value={pKey}>
                                                <Checkbox checked={(values.platformKeys).indexOf(pKey) > -1} />
                                                <ListItemText primary={pKey} />
                                            </MenuItem>
                                        ))
                                    }
                                </Field>
                            </Grid> */}
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        {isSubmitting && <CircularProgress size={24} className={classes.actionSpacer} />}
                        <Button variant="text" onClick={handleClose}>
                            <FormattedMessage {...messages.AssignManagementPortalButtonCancel} />
                        </Button>
                        <Button varient="text" onClick={submitForm} disabled={isSubmitting} color="primary">
                            <FormattedMessage {...messages.AssignManagementPortalButtonAssign} />
                        </Button>
                    </DialogActions>
                </Dialog>
            </Form>
        )}
        </Formik>
        </div>
    );
}

export default AssignManagementPortal;