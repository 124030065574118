import React from 'react';

import {
    Box,
    AppBar,
    Toolbar,
    Typography,
    Container,
    Grow,
    Paper,
    Popper,
    MenuItem,
    MenuList,
    ListItemIcon,
    ListItemText,
    CircularProgress,
    ClickAwayListener,
    Tooltip
} from '@material-ui/core';

import {
    useLocation,
    useHistory,
} from 'react-router-dom';

import IconButton from '@material-ui/core/IconButton';

import {
    Person,
    PeopleOutline,
    DesktopMacOutlined,
    BusinessOutlined,
    VpnKeyOutlined,
    FileCopyOutlined,
} from '@material-ui/icons';
import MenuIcon from '@material-ui/icons/Menu';
import MenuOpenIcon from '@material-ui/icons/MenuOpen';
import useStyles from './styles';
import AppDrawer from 'components/AppDrawer';
import useAuthUser from 'hooks/useAuthUser';

import { gql } from 'apollo-boost';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { FormattedMessage, defineMessages, useIntl } from 'react-intl';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import useAlert, { Success, Error } from 'components/Alert/CustomAlert';

let allPages = [
    //{id: "dashboard", name: "Dashboard", path: "/"},
    //{id: "settings", name: "Settings", path: "/settings"},
    {id: "platform", name: "Platform", path: "/platform", icon: DesktopMacOutlined, isUser: true},
    {id: "managementPortal", name: "Management Portal", path: "/management-portal", icon: BusinessOutlined, isUser: true},
    {id: "apiKeys", name: "API Keys", path: "/api-keys", icon: VpnKeyOutlined, isUser: false},
    {id: "users", name: "Users", path: "/users", icon: PeopleOutline, isUser: false},
];

const GENERATE_ACCESS_KEY = gql`
    mutation generateAccessKey {
        generateAccessKey {
            accessKey
            createdAt
            code
            success
            message
        }
    }
`;

const GET_ACCESS_KEY = gql`
    query {
        getAccessKey {
            accessKey
        }
    }
`;

const messages = defineMessages({
    MasterLabelAccessKey: {id: 'Master.label.AccessKey', description: 'Master label - AccessKey', defaultMessage:'Access Key'},
    MasterButtonGetAccessKey: {id: 'Master.button.GetAccessKey', description: 'Master button - GetAccessKey', defaultMessage:'Get Access Key'},

    MasterGenerateSuccess: {id: 'Master.Generate.Success', description: 'Master Generate Success', defaultMessage: 'Access key is generated successfully.'},
    MasterLabelAccessKeyCopyToClipboardSuccess: {id: 'Master.AccessKey.CopyToClipboard.Success', description: 'Master Access Key Copied To Clipboard Success', defaultMessage: 'Access key is copied successfully'},
});

const Master = (props) => {
    const classes = useStyles();
    const { pathname } = useLocation();
    const history = useHistory();
    const [ selectedPage, setSelectedPage ] = React.useState({});
    const [open, setOpen] = React.useState(false);
    const [drawerOpen, setDrawerOpen] = React.useState(true);
    const anchorRef = React.useRef(null);
    const { logout } = useAuthUser();
    const authUser = JSON.parse(sessionStorage.getItem('amz-user'));
    const fullName = authUser ? `${authUser.firstName} ${authUser.lastName}` : '';
    const isAdmin = authUser?.roles ? (authUser.roles).indexOf('admin') > -1 : false;
    const {open: openSuccess, message: messageSuccess, handleOpen: showSuccess, handleClose: closeSuccess} = useAlert();
    const {open: openError, message: messageError, handleOpen: showError, handleClose: closeError} = useAlert();
    const [aKey, setAKey] = React.useState(null);

    const { loading, error } = useQuery(GET_ACCESS_KEY, {
        notifyOnNetworkStatusChange: true,
        fetchPolicy: 'no-cache',
        onCompleted(data) {
            setAKey(data?.getAccessKey?.accessKey ?? null);
        },
    });

    const [generateAccessKey, { loading: mLoading, error: mError, data: mData }] = useMutation(GENERATE_ACCESS_KEY, {
        onCompleted(data) {
            if (data?.generateAccessKey?.success === true) {
                showSuccess(messages.MasterGenerateSuccess);
                setAKey(data?.generateAccessKey?.accessKey ?? null);
            }
            else {
                showError(data?.generateAccessKey);
            }
        },
        onError(error) {
            showError(error);
        }
    });

    let pages = [];
    if (!isAdmin) {
        pages = allPages.filter(page => page.isUser);
    }
    else {
        pages = allPages.filter(page => page.id !== "managementPortal");
    }
    
    if (authUser && (pathname === '/')) {
        history.push(pages[0].path);
    }
    
    if (authUser && !pages.find(page => page.path === pathname)) {
        history.push(pages[0].path);
    }

    const getInitSelected = (url) => {
        for (let page of pages) {
            if (page.path === url) return page;
        }
        return pages[0];
    };

    React.useEffect(() => {
        setSelectedPage(getInitSelected(pathname));
    }, [pathname]);

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        setOpen(false);
    };

    const handleListKeyDown = (event) => {
        if (event.key === 'Tab') {
            event.preventDefault();
            setOpen(false);
        }
    }

    const handleAccessKey = (event) => {
        event.preventDefault();
        if (aKey) {
            // Copy to Clip-board.
        }
        else {
            // Generate access key.
            generateAccessKey();
        }
    };

    return (
        <>
            <Success open={openSuccess} message={messageSuccess} onClose={closeSuccess} />
            <Error open={openError} message={messageError} onClose={closeError} />
            <AppDrawer pages={pages}  open={drawerOpen} selectedPage={selectedPage} setSelectedPage={setSelectedPage} />
            <Box className={drawerOpen ? classes.contentShift : classes.contentMargin}>
                <AppBar color="default" position="sticky">
                    <Toolbar>
                        <Box>
                            <IconButton
                            aria-label="open drawer"
                            onClick={() => setDrawerOpen(!drawerOpen)}
                            edge="start"
                            >
                                {
                                    drawerOpen ?
                                    <MenuOpenIcon /> :
                                    <MenuIcon />
                                }
                            </IconButton>
                        </Box>
                        <Box flexGrow={1}>
                            <Typography variant="h6" noWrap>
                                {selectedPage.name}
                            </Typography>
                        </Box>
                        <Box pr={1}>
                            <Tooltip title={fullName}>
                                <IconButton ref={anchorRef} aria-controls={open ? 'menu-list-grow' : undefined} aria-haspopup="true" onClick={handleToggle} className={classes.iconButton}>
                                    <Person />
                                </IconButton>
                            </Tooltip>
                            <Popper placement='bottom-end' open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
                            {({ TransitionProps, placement }) => (
                                <Grow
                                {...TransitionProps}
                                // style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
                                >
                                <Paper>
                                    <ClickAwayListener onClickAway={handleClose}>
                                    <MenuList autoFocusItem={open} id="menu-list-grow" onKeyDown={handleListKeyDown}>
                                        <MenuItem onClick={handleClose} disabled>Profile</MenuItem>
                                        <MenuItem onClick={handleClose} disabled>My account</MenuItem>
                                        {
                                            !isAdmin && 
                                                <MenuItem onClick={handleAccessKey} disabled={loading || mLoading}>
                                                <ListItemText>
                                                {
                                                    aKey ?
                                                        'Access Key' : 
                                                        'Get Access Key'
                                                }
                                                </ListItemText>
                                                {
                                                    aKey && 
                                                    <ListItemIcon>
                                                        <CopyToClipboard
                                                            text={aKey}
                                                            onCopy={() => showSuccess(messages.MasterLabelAccessKeyCopyToClipboardSuccess)}
                                                        >
                                                            <IconButton className={classes.copyButton}>
                                                                <FileCopyOutlined fontSize="small" />
                                                            </IconButton>
                                                        </CopyToClipboard>
                                                    </ListItemIcon>
                                                }
                                                {
                                                    (loading || mLoading) && 
                                                    <CircularProgress size={16} className={classes.circularProgress} />
                                                }
                                            </MenuItem>
                                        }
                                        <MenuItem onClick={(event) => {
                                            handleClose(event);
                                            logout();
                                        }}>Logout</MenuItem>
                                    </MenuList>
                                    </ClickAwayListener>
                                </Paper>
                                </Grow>
                            )}
                            </Popper>
                        </Box>
                    </Toolbar>
                </AppBar>
                <Box className={classes.content}>
                    {/* <Box className={classes.toolbar} /> */}
                    <Container maxWidth="xl">
                        {console.log(props)}
                        {props.children}
                    </Container>
                </Box>
            </Box>
        </>
    );
};

export default Master;

