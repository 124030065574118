import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => {
    return {
        gridContainer: {
            marginTop: theme.spacing(1)
        },
        showActionsOnHover: {
            '& td:last-of-type *': {
                visibility: 'hidden'
            },
            '&:hover td:last-of-type *': {
                visibility: 'visible'
            }
        },
        selected: {
            background: theme.palette.action.selected,
            '& td:last-of-type *': {
                visibility: 'visible',
            }
        },
        tableSorticonColor: {
            color: `${theme.palette.common.white} !important`,
            "&:hover": {
                color:`${theme.palette.text.primary} !important`,
            },
        },
        tableSortlabelColor: {
            color: `${theme.palette.common.white} !important`,
            "&:hover": {
                color:`${theme.palette.text.primary} !important`,
            },
        },
    }
}, { index: 1 });