import React from 'react';
import { Route, useHistory } from 'react-router-dom';

export const PrivateRoute = ({ component: Component, ...rest }) => {
    const history = useHistory();

    if (!sessionStorage.getItem('amz-user')) {
        history.push('/login');
    }

    return (
        <>
            <Route
                {...rest}
                component={Component}
            />
        </>
    )
};

export default PrivateRoute;
