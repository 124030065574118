import React from 'react';
import { FormattedMessage, defineMessages, useIntl } from 'react-intl';

import {
    Box,
    CircularProgress,
    Grid,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Button,
    AppBar,
    Toolbar,
    Typography,
    Container
} from '@material-ui/core';

import useStyles from './styles';
import useAlert, { Success, Error } from 'components/Alert/CustomAlert';

import { gql } from 'apollo-boost';
import { useQuery, useMutation } from '@apollo/react-hooks';

const LIST_API_KEYS = gql`
    query {
        getSecretKey {
            apiKeys {
                accessKey
                secretKey
                createdAt
            }
        }
    }
`;

const CREATE_API_KEY = gql`
    mutation createAPIKey {
        createAPIKey {
            accessKey
            secretKey
            createdAt
            code
            success
            message
        }
    }
`;

const messages = defineMessages({
    APIKeysLabelAccessKey: {id: 'APIKeys.label.AccessKey', description: 'API Keys label - AccessKey', defaultMessage:'Access Key'},
    APIKeysLabelSecretKey: {id: 'APIKeys.label.SecretKey', description: 'API Keys label - SecretKey', defaultMessage:'Secret Key'},
    APIKeysLabelCreatedOn: {id: 'APIKeys.label.CreatedOn', description: 'API Keys label - CreatedOn', defaultMessage:'Created On'},

    APIKeysButtonGenerate: {id: 'APIKeys.button.Generate', description: 'API Keys button - Generate', defaultMessage:'Generate'},
    
    APIKeysGenerateSuccess: {id: 'APIKeys.Generate.Success', description: 'API Keys Generate Success', defaultMessage: 'API keys generated successfully.'},

    NoRecordsLabel: { id: 'APIKeys.label.NoRecords', description: 'API Keys label - NoRecords', defaultMessage: 'No Records Found' },
});

const APIKeys = () => {
    const classes = useStyles();
    const {open: openSuccess, message: messageSuccess, handleOpen: showSuccess, handleClose: closeSuccess} = useAlert();
    const {open: openError, message: messageError, handleOpen: showError, handleClose: closeError} = useAlert();
    
    const { loading, error, data, refetch, networkStatus } = useQuery(LIST_API_KEYS, { notifyOnNetworkStatusChange: true, fetchPolicy: 'no-cache', });
    
    const [createAPIKey, { loading: mLoading, error: mError, data: mData }] = useMutation(CREATE_API_KEY, {
        onCompleted(data) {
            if (data && data.createAPIKey.success === true) {
                showSuccess(messages.APIKeysGenerateSuccess);
            }
            else {
                showError(data?.createAPIKey);
            }
        },
        onError(error) {
            showError(error);
        }
    });
    
    React.useEffect(() => {
        if(openSuccess) {
            refetch();
        }
    }, [openSuccess]);
    
    // Not the best way to move progress spinner but it works for now
    /*if ((loading) || (networkStatus === 4)) return (
        <Container maxWidth="xl">
            <Grid container direction="column" justify="center" alignItems="center" spacing={8}>
                <Grid item />
                <Grid item >
                    <CircularProgress />
                </Grid>
            </Grid>
        </Container>
    );*/

    return (
        <Box>
            <Success open={openSuccess} message={messageSuccess} onClose={closeSuccess} />
            <Error open={openError} message={messageError} onClose={closeError} />
            <Grid container direction="column" justify="flex-start" alignItems="center" spacing={2} className={classes.gridContainer}>
                <Grid item container direction="column" justify="flex-start" alignItems="center" spacing={2}>
                    <Grid container direction="row" justify="flex-end" alignItems="center" spacing={2}>
                        <Grid item>
                            {mLoading && <CircularProgress size={24} className={classes.actionSpacer} />}
                        </Grid>
                        <Grid item>
                                <Button variant="outlined" onClick={createAPIKey} disabled={(data?.getSecretKey?.apiKeys?.length > 0) || mLoading} color="primary">
                                <FormattedMessage {...messages.APIKeysButtonGenerate} />
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item container direction="column" justify="flex-start" alignItems="center" spacing={2} className={classes.gridContainer}>
                    <TableContainer component={Paper} className={classes.container}>
                        <Table stickyHeader className={(data?.getSecretKey?.apiKeys?.length) ? classes.table : ""} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell><FormattedMessage {...messages.APIKeysLabelAccessKey} /></TableCell>
                                    {/* <TableCell><FormattedMessage {...messages.APIKeysLabelSecretKey} /></TableCell> */}
                                    <TableCell><FormattedMessage {...messages.APIKeysLabelCreatedOn} /></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody className={classes.wordWrap}>
                            {
                                data === undefined ?
                                (
                                    <TableRow>
                                        <TableCell colSpan={7} display="flex" align="center">
                                            <Box mt={1} mb={1}>
                                                <CircularProgress size={24} />
                                            </Box>
                                        </TableCell>
                                    </TableRow>
                                ) :
                                (
                                    data?.getSecretKey?.apiKeys?.length ?
                                    (
                                        data?.getSecretKey?.apiKeys?.map((row, i) => (
                                            <TableRow key={i} hover className={classes.showActionsOnHover}>
                                                <TableCell>{row.accessKey}</TableCell>
                                                {/* <TableCell>{row.secretKey}</TableCell> */}
                                                <TableCell>{new Date(row.createdAt).toLocaleString()}</TableCell>
                                            </TableRow>
                                        ))
                                    ) :
                                    (
                                        <TableRow>
                                            <TableCell colSpan={7} display="flex">
                                                <Typography variant="body1" display="block">
                                                    <Box fontWeight="fontWeightBold" m={1} display="flex" justifyContent="center">
                                                        <FormattedMessage {...messages.NoRecordsLabel} />
                                                    </Box>
                                                </Typography>
                                            </TableCell>
                                        </TableRow>
                                    )
                                )
                            }
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            </Grid>
        </Box>
    );
};

export default APIKeys;

