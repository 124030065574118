import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import { Formik, Form, Field } from 'formik';
import { TextField } from 'formik-material-ui';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import { Redirect } from "react-router-dom";
import { FormattedMessage, defineMessages, useIntl } from 'react-intl';
import useAlert, { Success, Error } from 'components/Alert/CustomAlert';
import { regex } from 'config';
import { CircularProgress } from '@material-ui/core';

import { gql } from 'apollo-boost';
import { useQuery, useMutation } from '@apollo/react-hooks';

import useStyles from './styles';
import { UserContext } from 'containers/App/UserContext'

const SIGN_UP = gql`
    mutation signup($email: String!, $firstName: String!, $lastName: String!, $password: String!) {
        signup(email: $email, firstName: $firstName, lastName: $lastName, password: $password) {
            code
            success
            message
        }
    }
`;

const messages = defineMessages({
    SignUpSuccess: {id: 'SignUp.Success', description: 'SignUp Success', defaultMessage: 'User is registered successfully.'},
    VerificationEmail: {id: 'SignUp.VerificationEmail', description: 'SignUp VerificationEmail', defaultMessage: 'We have sent an email with a confirmation link to your email address. Please check and confirm.'},
    E_AUTH_EMAIL_FAILED: {id: 'SignUP.InvalidErrorMessage', description: 'SignUp Invalid Error Message', defaultMessage: 'Email verification link is invalid.'},
    E_AUTH_EMAIL_TOKEN_EXPIRED: {id: 'login.TokenExpiredErrorMessage', description: 'login Token Expired Error Message', defaultMessage: 'User not found'},
});

const Copyright = () => {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            {'Copyright © '}
            {new Date().getFullYear()}{' '}
            {'AmZetta Technologies, All Rights Reserved.'}
        </Typography>
    );
}

export default function Registration() {
    const classes = useStyles();
    const userContext = React.useContext(UserContext);
    const [isSignUpSuccess, setSignUpSuccess] = React.useState(false);
    const {open: openSuccess, message: messageSuccess, handleOpen: showSuccess, handleClose: closeSuccess} = useAlert();
    const {open: openError, message: messageError, handleOpen: showError, handleClose: closeError} = useAlert();
    
    const [signup, { loading, error, data }] = useMutation(SIGN_UP, {
        onCompleted(data) {
            console.log(data, "onCompleted signup");
            if (data?.signup?.success === true) {
                userContext.response.status = { code: data?.signup?.code , type: 'SUCCESS' };
                setSignUpSuccess(true);
            }
            else {
                showError(data?.signup);
            }
        },
        onError(error) {
            showError(error);
        }
    });
    
    React.useEffect(() => {
        if(userContext?.response?.status?.type === 'ERROR') {
            showError(messages[userContext?.response?.status?.code]);
            userContext.response.status = undefined;
        }
    }, []);

    if (isSignUpSuccess) {
        return <Redirect to="/login" />;
    }

    return (
        <Container component="main" maxWidth="xs">
            <Success open={openSuccess} message={messageSuccess} onClose={closeSuccess} />
            <Error open={openError} message={messageError} onClose={closeError} />
            <CssBaseline />
            <Formik
                enableReinitialize={true}
                initialValues={{
                    firstName: "",
                    lastName: "",
                    email: "",
                    password: ""
                }}
                validate={values => {
                    const errors = {};

                    const regexPassword = /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,15}$/;

                    if (!values.firstName) {
                        errors.firstName = 'Required';
                    }

                    if (!values.lastName) {
                        errors.lastName = 'Required';
                    }

                    if (!values.email) {
                        errors.email = 'Required';
                    }
                    else if (!regex.Mail.test(values.email)) {
                        errors.email = 'Please enter valid email';
                    }

                    if (!values.password) {
                        errors.password = 'Required';
                    }
                    else if (values.password && !regexPassword.test(values.password)) {
                        errors.password = 'Password length must be minimum 8 char with one uppercase, one lower case and with one special character';
                    }

                    return errors;
                }}
                onSubmit={async (values, { setSubmitting, resetForm }) => {

                    await signup({ variables: values });

                    setSubmitting(false);
                    resetForm();
                }}
            >
            {({ submitForm, isSubmitting, values, setFieldValue, }) => (
                <Form>
                    <Box className={classes.paper}>
                        <Box display="flex" justifyContent="center">
                            <Box className={classes.logo} />
                        </Box>
                        {/* <Avatar className={classes.avatar}>
                            <LockOutlinedIcon />
                        </Avatar>
                        <Typography component="h1" variant="h5">
                            Sign up
                        </Typography> */}
                        <Grid container className={classes.form} spacing={1}>
                            <Grid item xs={12} sm={6}>
                                <Field component={TextField} type="text" name="firstName" label="First Name" margin="normal" variant="outlined" fullWidth />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Field component={TextField} type="text" name="lastName" label="Last Name" margin="normal" variant="outlined" fullWidth />
                            </Grid>
                            <Grid item xs={12}>
                                <Field component={TextField} type="text" name="email" label="Email Address" margin="normal" variant="outlined" fullWidth />
                            </Grid>
                            <Grid item xs={12}>
                                <Field component={TextField} type="password" name="password" label="Password" margin="normal" variant="outlined" fullWidth />
                            </Grid>
                            {/*<Grid item xs={12}>
                                <FormControlLabel
                                    control={<Checkbox value="allowExtraEmails" color="primary" />}
                                    label="I want to receive inspiration, marketing promotions and updates via email."
                                />
                            </Grid>*/}
                        </Grid>
                        {
                            !loading ?
                                <Button
                                    type="submit"
                                    fullWidth
                                    variant="contained"
                                    color="primary"
                                    className={classes.submit}
                                    onClick={submitForm} 
                                    disabled={isSubmitting}
                                >
                                    Sign Up
                                </Button>
                            :
                                <Box mt={1} mb={1}>
                                    <CircularProgress size={24} />
                                </Box>
                        }
                        <Grid container justify="flex-end">
                            <Grid item>
                                <Link  href={!loading ? "#" : null} variant="body2">
                                    Already have an account? Sign in
                                </Link>
                            </Grid>
                        </Grid>
                    </Box>
                    <Box mt={5}>
                        <Copyright />
                    </Box>
                </Form>
            )}
            </Formik>
        </Container>
    );
}