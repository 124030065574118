import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, defineMessages, useIntl } from 'react-intl';

import {
    Button,
    CircularProgress,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions
} from '@material-ui/core';

import useStyles from './ConfirmRemove.styles';

// Messages for ConfirmRemove.
const messages = defineMessages({
    removeButton: {id: 'Button.remove', description: 'Remove button name', defaultMessage:'Yes'},
    cancelButton: {id: 'Button.cancel', description: 'Cancel button name', defaultMessage:'Cancel'},
    title: {id: 'ConfirmRemove.title', description: 'Confirm Remove title', defaultMessage:'Confirm Remove'},
    content: {id: 'ConfirmRemove.content', description: 'Confirm Remove content', defaultMessage:'Are you sure you want to remove this item?'}
});

const ConfirmRemove = props => {
    const { open, title, content, loading, onClose, onRemove } = props;
    const classes = useStyles();
    
    return (
        <Dialog open={open} onClose={onClose} aria-labelledby="ConfirmRemove-dialog" classes={{ paper: classes.dialogPaper }} onKeyPress= {(e) => { if(!loading && e.which === 13) {onRemove()}}}>
            <DialogTitle id="ConfirmRemove-dialog-title">
                {
                    title ?
                        <FormattedMessage {...title} /> :
                        <FormattedMessage {...messages.title} />
                }
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                {
                    content ?
                        <FormattedMessage {...content} /> :
                        <FormattedMessage {...messages.content} />
                }
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                {loading && <CircularProgress size={24} className={classes.actionSpacer} />}
                <Button onClick={onClose} color="primary">
                    <FormattedMessage {...messages.cancelButton} />
                </Button>
                <Button onClick={onRemove} disabled={loading} color="primary">
                    <FormattedMessage {...messages.removeButton} />
                </Button>
            </DialogActions>
        </Dialog>
    );
}

ConfirmRemove.propTypes = {
    open: PropTypes.bool.isRequired,
    loading: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onRemove: PropTypes.func.isRequired
};

export default ConfirmRemove;