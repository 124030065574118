import React from 'react';

import {
    Box,
    AppBar,
    Toolbar,
    Typography,
    Container
} from '@material-ui/core';

import useStyles from './styles';

const Settings = () => {
    const classes = useStyles();

    return (
        <Box display="flex" m={3} alignItems="center" justifyContent="center">
            <Typography variant="h6" noWrap>
                Settings
            </Typography>
        </Box>
    );
};

export default Settings;

