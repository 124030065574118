import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import useStyles from './styles'
import { Paper } from '@material-ui/core';
import { Formik, Form, Field } from 'formik';
import { TextField } from 'formik-material-ui';
import useAuthUser from 'hooks/useAuthUser';
import { Redirect } from "react-router-dom";
import useAlert, { Success, Error } from 'components/Alert/CustomAlert';
import { regex } from 'config';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

import { gql } from 'apollo-boost';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { UserContext } from 'containers/App/UserContext';
import { FormattedMessage, defineMessages, useIntl } from 'react-intl';

const SIGN_IN = gql`
    mutation signin($email: String!, $password: String!) {
        signin(email: $email, password: $password) {
            code
            success
            message
            token
            user {
                firstName
                lastName
                email
                roles
            }
        }
    }
`;
const RESEND_EMAIL_TOKEN = gql`
    mutation ResendEmailToken ($email: String!) {
        ResendEmailToken (email:$email) {
            code
            success
            message
        }
    }
`;

const messages = defineMessages({
    I_SIGNUP_SUCCESS: {id: 'login.Signup.Success', description: 'login Signup Success', defaultMessage: 'We have sent an email with a confirmation link to your email address. Please check and confirm.'},
    I_AUTH_EMAIL_SUCCESS: {id: 'login.Success', description: 'login Success', defaultMessage: 'Email Address has been verified successfully'},
    I_RESET_USER_PASSWORD_SUCCESS: {id: 'login.Success', description: 'login Success', defaultMessage: 'Password has been reset successfully'},
    E_SIGNIN_EMAIL_VERIFIED_FAILED: {id: 'login.Error', description: 'login Error', defaultMessage: 'Email is not verified. We have sent an email with a confirmation link to your email address. Please check and confirm.'},
    E_AUTH_EMAIL_TOKEN_RESEND: {id: 'login.TokenExpiredErrorMessage', description: 'login Token Expired Error Message', defaultMessage: 'Email verification link has been expired. We have sent an another email with a confirmation link to your email address. Please check and confirm.'},
});

const Copyright = () => {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            {'Copyright © '}
            {new Date().getFullYear()}{' '}
            {'AmZetta Technologies, All Rights Reserved.'}
        </Typography>
    );
}

const Login = () => {
    const classes = useStyles();
    const [isLoggedIn, setLoggedIn] = React.useState(false);
    const userContext = React.useContext(UserContext);
    const { setAuthUser } = useAuthUser();
    const {open: openSuccess, message: messageSuccess, handleOpen: showSuccess, handleClose: closeSuccess} = useAlert();
    const {open: openError, message: messageError, handleOpen: showError, handleClose: closeError} = useAlert();
    const [showPassword, setShowPassword] = React.useState(false);
    const [email, setEmail] = React.useState(null);


    const [ResendEmailToken, { loading: tokenLoading, error: tokenError, data: tokenData }] = useMutation(RESEND_EMAIL_TOKEN, {
        onCompleted(data) {
            if (data?.ResendEmailToken?.success === true) {
               //showSuccess(messages.VerificationEmail);
            }
            else {
                showError(data?.ResendEmailToken);
            }
        },
        onError(error) {
            showError(error);
        }
    });
    const [signin, { loading, error, data }] = useMutation(SIGN_IN, {
        onCompleted(data) {
            console.log(data, "onCompleted signin");
            if (data && data.signin.success === true) {
                setAuthUser(data.signin);
                setLoggedIn(true);
                setEmail(null);
            }
            else {
                if (data?.signin?.code === 'E_SIGNIN_EMAIL_VERIFIED_FAILED') {
                    showError(messages[data?.signin?.code]);
                    ResendEmailToken({ variables: {email} });
                }
                else {
                    showError(data?.signin);
                }
            }
        },
        onError(error) {
            showError(error);
        }
    });
    
    React.useEffect(() => {
        if(userContext?.response?.status?.type === 'SUCCESS') {
            showSuccess(messages[userContext?.response?.status?.code]);
            userContext.response.status = undefined;
        }
        else if(userContext?.response?.status?.type === 'ERROR') {
            showError(messages[userContext?.response?.status?.code]);
            userContext.response.status = undefined;
        }
    }, []);

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword)
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    if (isLoggedIn) {
        return <Redirect to="/" />;
    }

    return (
        <Container component="main" maxWidth="xs">
            <Success open={openSuccess} message={messageSuccess} onClose={closeSuccess} />
            <Error open={openError} message={messageError} onClose={closeError} />
            <Formik
                enableReinitialize={true}
                initialValues={{
                    email: "",
                    password: ""
                }}
                validate={values => {
                    const errors = {};

                    if (!values.email) {
                        errors.email = 'Required';
                    }
                    else if (!regex.Mail.test(values.email)) {
                        errors.email = 'Please enter valid email';
                    }
                    
                    if (!values.password) {
                        errors.password = 'Required';
                    }
                    return errors;
                }}
                onSubmit={async (values, { setSubmitting, resetForm }) => {
                    setEmail(values.email);
                    await signin({ variables: values });

                    setSubmitting(false);
                    resetForm();
                }}
            >
            {({ submitForm, isSubmitting, values, setFieldValue, }) => (
                <Form>
                    <Box className={classes.paper}>
                        <Box display="flex" justifyContent="center">
                            <Box className={classes.logo} />
                        </Box>
                        <Grid container className={classes.form}>
                            <Grid item xs={12}>
                                <Field component={TextField} type="text" name="email" label="Email Address" margin="normal" variant="outlined" fullWidth />
                            </Grid>
                            <Grid item xs={12}>
                                <Field component={TextField}
                                    type={showPassword ? 'text' : 'password'}
                                    value={values.password}
                                    name="password" label="Password"
                                    margin="normal"
                                    variant="outlined" fullWidth
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={handleClickShowPassword}
                                                    onMouseDown={handleMouseDownPassword}
                                                >
                                                    {showPassword ? <Visibility /> : <VisibilityOff />}
                                                </IconButton>
                                            </InputAdornment>
                                        )
                                    }}
                                />
                            </Grid>
                            {/* <Grid item xs={12}>
                                <FormControlLabel control={<Checkbox value="remember" color="primary" />} label="Remember me" />
                            </Grid> */}
                            <Grid item xs={12}>
                                <Button
                                    type="submit"
                                    fullWidth
                                    variant="contained"
                                    color="primary"
                                    className={classes.submit}
                                    onClick={submitForm}
                                    disabled={isSubmitting}
                                >
                                    Login
                                </Button>
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container justify='flex-end'>
                                    <Grid item xs>
                                        <Link href="/#/forgot-password" variant="body2">
                                            Forgot Password?
                                        </Link>
                                    </Grid>
                                    <Grid item>
                                        <Link href="/#/registration" variant="body2">
                                            {"Don't have an account? Sign Up"}
                                        </Link>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Box>
                    <Box mt={8}>
                        <Copyright />
                    </Box>
                </Form>
            )}
            </Formik>
        </Container>
    );
}

export default Login;