import React from 'react';
import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import useStyles from './styles'
import { CircularProgress, Paper } from '@material-ui/core';
import { Formik, Form, Field } from 'formik';
import { TextField } from 'formik-material-ui';
import useAlert, { Success, Error } from 'components/Alert/CustomAlert';
import { regex } from 'config';

import { gql } from 'apollo-boost';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { UserContext } from 'containers/App/UserContext';
import { FormattedMessage, defineMessages, useIntl } from 'react-intl';
import {  useHistory, useLocation } from 'react-router-dom';

const SEND_RESET_PASSWORD_LINK = gql`
    mutation sendResetPasswordLink($email:String!){
        sendResetPasswordLink (email: $email) {
            code
            success
            message
        }
}`;

const messages = defineMessages({
    PasswordResetEmail: {id: 'ForgotPassword.PasswordResetEmail', description: 'ForgotPassword Password Reset Email', defaultMessage: 'Password reset link has been sent. Please check your email.'},
    E_VERIFY_RESET_PASSWORD_TOKEN_EXPIRED: {id: 'ForgotPassword.Token Expired Error Message', description: 'ForgotPassword Token Expired Error Message', defaultMessage: 'Password reset link has been expired'},
    E_VERIFY_RESET_PASSWORD_TOKEN_FAILED:  {id: 'ForgotPassword.Invalid Error Message', description: 'ForgotPassword Invalid Error Message', defaultMessage: 'Invalid password reset link'},
    E_RESET_USER_PASSWORD_FAILED: {id: 'ForgotPassword.Reset Error Message', description: 'ForgotPassword reset Error Message', defaultMessage: 'Failed to reset password'}
});

const Copyright = () => {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            {'Copyright © '}
            {new Date().getFullYear()}{' '}
            {'AmZetta Technologies, All Rights Reserved.'}
        </Typography>
    );
}

const ForgotPassword = () => {
    const classes = useStyles();
    const userContext = React.useContext(UserContext);
    const {open: openSuccess, message: messageSuccess, handleOpen: showSuccess, handleClose: closeSuccess} = useAlert();
    const {open: openError, message: messageError, handleOpen: showError, handleClose: closeError} = useAlert();

    const [sendResetPasswordLink, { loading, error, data }] = useMutation(SEND_RESET_PASSWORD_LINK, {
        onCompleted(data) {
            if (data?.sendResetPasswordLink?.success === true) {
                showSuccess(messages.PasswordResetEmail);
            }
            else {
                showError(data?.sendResetPasswordLink);
            }
        },
        onError(error) {
            showError(error);
        }
    });

    React.useEffect(() => {
        if(userContext?.response?.status?.type === 'ERROR') {
            showError(messages[userContext?.response?.status?.code]);
            userContext.response.status = undefined;
        }
    }, []);

    return (
        <Container component="main" maxWidth="xs">
            <Success open={openSuccess} message={messageSuccess} onClose={closeSuccess} />
            <Error open={openError} message={messageError} onClose={closeError} />
            <Formik
                enableReinitialize={true}
                initialValues={{
                    email: "",
                }}
                validate={values => {
                    const errors = {};

                    if (!values.email) {
                        errors.email = 'Required';
                    }
                    else if (!regex.Mail.test(values.email)) {
                        errors.email = 'Please enter valid email';
                    }

                    return errors;
                }}
                onSubmit={async (values, { setSubmitting, resetForm }) => {

                    await sendResetPasswordLink ({ variables: values });

                    setSubmitting(false);
                    resetForm();
                }}
            >
            {({ submitForm, isSubmitting, values, setFieldValue, }) => (
                <Form>
                    <Box className={classes.paper}>
                        <Box display="flex" justifyContent="center">
                            <Box className={classes.logo} />
                        </Box>
                        <Grid container className={classes.form} spacing={1}>
                            <Grid item xs={12}>
                                <Field
                                    component={TextField}
                                    type="text"
                                    name="email"
                                    label="Email Address"
                                    margin="normal"
                                    variant="outlined"
                                    fullWidth
                                />
                            </Grid>
                        </Grid>
                        {
                            !loading ?
                                <Button
                                type="submit"
                                variant="contained"
                                color="primary"
                                className={classes.submit}
                                onClick={submitForm}
                                disabled={isSubmitting}
                                fullWidth
                                >
                                    Reset Password
                                </Button>
                            :
                                <Box mt={1} mb={1} >
                                    <CircularProgress size={24} />
                                </Box>
                        }
                        <Grid container justify="flex-end">
                            <Grid item>
                                <Link  href={!loading ? "/#/login" : null} variant="body2">
                                    Back to Sign In
                                </Link>
                            </Grid>
                        </Grid>
                    </Box>
                    <Box mt={8}>
                        <Copyright />
                    </Box>
                </Form>
            )}
            </Formik>
        </Container>
    );
}

export default ForgotPassword;