import { makeStyles } from '@material-ui/core/styles';
import zwanLogo from 'assets/zwan-logo.svg';

export default makeStyles((theme) => {
    return {
        drawer: {
            width: theme.custom.drawerWidth,
            flexShrink: 0,
            whiteSpace: 'nowrap',
        },
        drawerOpen: {
            width: theme.custom.drawerWidth,
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
        },
        drawerClose: {
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
            overflowX: 'hidden',
            width: `calc(100% - ${theme.custom.drawerMinWidth}px)`,
            [theme.breakpoints.up('sm')]: {
                width: theme.custom.drawerMinWidth
            },
        },
        drawerPaper: {
            //width: theme.custom.drawerWidth,
            background: theme.custom.drawerBg,
            //overflow: 'hidden',
        },
        drawerIcon: {
            color: theme.palette.primary.contrastText,
            minWidth: theme.spacing(4),
        },
        logo: {
            backgroundImage: `url(${zwanLogo})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            height: 24,
            width: 24
        },
        toolbar: theme.mixins.toolbar,
        list: {
            width: '100%',
        },
        bold: {
            fontWeight: 'bold'
        },
        nested: {
            paddingLeft: theme.spacing(4),
            paddingTop: theme.spacing(1.5),
            paddingBottom: theme.spacing(1.5),
        },
        selected: {
            color: theme.palette.primary.main,
            fontWeight: 'bold'
        },
        scrollArea: {
            overflowY: 'auto',
            overflowX: 'hidden',
            '&::-webkit-scrollbar': {
                width: '0.4em'
            },
            '&::-webkit-scrollbar-track': {
                boxShadow: 'inset 0 0 6px rgba(0, 0, 0, 0.00)',
                webkitBoxShadow: 'inset 0 0 6px rgba(0, 0, 0, 0.00)'
            },
            '&::-webkit-scrollbar-thumb': {
                backgroundColor: theme.palette.grey[700],
                /* outline: '1px solid slategrey' */
            }
        },
        removePad: {
            padding: 0
        },
        h6: {
            color: theme.palette.primary.contrastText
        },
        onHover: {
            '&:hover': {
               backgroundColor: theme.palette.grey[700],
            },
        },
        bold: {
            // not ideal way of forcing bold - but jss priority is messed up after build
            fontWeight: 'bold !important',
            color: theme.palette.primary.contrastText,
        },
        icon: {
            color: `${theme.palette.primary.contrastText} !important`
        }
    }
}, { index: 1 });