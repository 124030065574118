import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => {
    return {
        contentShift: {
            marginLeft: theme.custom.drawerWidth,
        },
        contentMargin: {
            marginLeft: theme.spacing(9),
        },
        appBar: {
            width: `calc(100% - ${theme.custom.drawerWidth}px)`,
            marginLeft: theme.custom.drawerWidth,
        },
        toolbar: theme.mixins.toolbar,
        content: {
            flexGrow: 1,
            backgroundColor: theme.palette.background.default,
            padding: theme.spacing(3),
        },
        iconButton: {
            color: theme.palette.primary.main,
            padding: 10,
            marginLeft: theme.spacing(0.5)
        },
        copyButton: {
            padding: theme.spacing(1),
            marginLeft: theme.spacing(0.5)
        },
        circularProgress: {
            marginLeft: theme.spacing(1.5),
            marginRight: theme.spacing(1),
        },
    }
}, { index: 1 });