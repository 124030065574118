import React, { useState } from 'react';
import useStyles from './AppDrawer.styles';
import { defineMessages, useIntl, FormattedMessage } from 'react-intl';
import clsx from 'clsx';

import {
    Link,
    useLocation
} from 'react-router-dom';

import {
    Drawer,
    Box,
    List,
    ListItem,
    ListItemText,
    ListItemIcon,
    Divider,
    Typography,
    Toolbar,
    Collapse,
    ListItemSecondaryAction,
    IconButton,
    FormControl,
    Select,
    MenuItem,
    Grow
} from '@material-ui/core';

import {
    ExpandLess,
    ExpandMore,
    LinkOutlined
} from '@material-ui/icons';

const messages = defineMessages({
    drawerHeader: {id: 'Provision.AppDrawer.header', description: 'AppDrawer Provision Header', defaultMessage:'zWAN Provision'},

    dashboard: {id: 'Provision.AppDrawer.dashboard', description: 'Name of device management section in Provision AppDrawer', defaultMessage:'Dashboard'},
    users: {id: 'Provision.AppDrawer.users', description: 'Name of device management section in Provision AppDrawer', defaultMessage:'Users'},
    settings: {id: 'Provision.AppDrawer.settings', description: 'Name of device management section in Provision AppDrawer', defaultMessage:'Settings'},
    platform: {id: 'Provision.AppDrawer.platform', description: 'Name of device management section in Provision AppDrawer', defaultMessage:'Platform'},
    managementPortal: {id: 'Provision.AppDrawer.managementPortal', description: 'Name of device management section in Provision AppDrawer', defaultMessage:'Management Portal'},
    apiKeys: {id: 'Provision.AppDrawer.apiKeys', description: 'Name of device management section in Provision AppDrawer', defaultMessage:'API Keys'},
});

const AppDrawer = ({ pages, open, selectedPage, setSelectedPage }) => {
    const classes = useStyles();
    const { formatMessage } = useIntl();

    return (
        <Drawer
            variant='permanent'
            anchor='left'
            className={clsx(classes.drawer, {
                [classes.drawerOpen]: open,
                [classes.drawerClose]: !open,
            })}
            classes={{
                paper: clsx(classes.drawerPaper, {
                    [classes.drawerOpen]: open,
                    [classes.drawerClose]: !open,
                }),
            }}
            open={open}
        >
            <Toolbar disableGutters={true} className={classes.drawerPaper}>
                <Box display="flex" justifyContent="center" m={2}>
                    <Box className={classes.logo} mt={0.5}/>
                </Box>
                <Grow in={open}>
                    <Box flexGrow={1} m={2}>
                        <FormControl>
                            <Select value="zwan-Provision" variant="standard" disableUnderline disabled classes={{ icon: classes.icon }}>
                                <MenuItem value="zwan-Provision" defaultValue>
                                    <Typography variant="h6" noWrap classes={{ h6: classes.h6 }}>
                                        <FormattedMessage {...messages.drawerHeader} />
                                    </Typography>
                                </MenuItem>
                            </Select>
                        </FormControl>
                    </Box>
                </Grow>
            </Toolbar>
            <Divider fullWidth light />
            <List className={classes.scrollArea}>
                {
                    pages.map((page) => (
                        <ListItem
                            key={page.id}
                            dense
                            button
                            component={Link} to={`${page.path}`}
                            classes={{root: classes.onHover}}
                            >
                                {
                                    (page.icon) &&
                                    <Box display="flex" justifyContent="flex-start">
                                        <ListItemIcon size={20} classes={{ root: classes.drawerIcon }}>
                                            <page.icon />
                                        </ListItemIcon>
                                    </Box>
                                }
                            <Grow in={open}>
                                <ListItemText
                                    primary={formatMessage(messages[page.id])}
                                    classes={{ primary: (page.id === selectedPage.id) ? classes.selected : classes.bold }}
                                />
                            </Grow>
                        </ListItem>
                    ))
                }
            </List>
        </Drawer>
    );
};

export default AppDrawer;