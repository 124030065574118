import React from 'react';
import clsx from 'clsx';
import { FormattedMessage, defineMessages, useIntl } from 'react-intl';

import {
    Box,
    CircularProgress,
    Grid,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Button,
    IconButton,
    AppBar,
    Toolbar,
    Typography,
    Tooltip,
    Container,
    InputAdornment
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import SettingsBackupRestoreOutlinedIcon from '@material-ui/icons/SettingsBackupRestoreOutlined';

import useStyles from './styles';
import useAlert, { Success, Error, Warning } from 'components/Alert/CustomAlert';
import AssignManagementPortal from './AssignManagementPortal';
import ImportPlatform from './ImportPlatform';
import ConfirmRemove from 'components/ConfirmRemove';

import { gql } from 'apollo-boost';
import { useQuery, useLazyQuery ,useMutation} from '@apollo/react-hooks';
import { UserContext } from 'containers/App/UserContext';
import { global } from 'config';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import SearchIcon from '@material-ui/icons/Search';
import TextField from '@material-ui/core/TextField';
import Pagination from 'components/Pagination/CustomPagination';

const LIST_MANAGEMENT_PORTALS = gql`
    query {
        managementPortals {
            managementPortals {
                _id
                mpName
            }
        }
    }
`;

const LIST_PLATFORM = gql`
query getPlatforms($page: Page, $sort: Sort, $searchFilter: SearchFilter){
    getPlatforms(page: $page, sort: $sort, searchFilter: $searchFilter) {
            offset
            limit
            totalCount
            result{
                _id
                platformKey
                platformType
                cpuSN
                mac
                managementPortalId
                userId
                createdAt
            }
        }
    }
`;

const DELETE_PLATFORM = gql`
    mutation removePlatforms($platformKeys: [String!]!) {
        removePlatforms(platformKeys: $platformKeys) {
            code
            success
            message
        }
    }
`;

const UNASSIGN_MANAGEMENT_PORTAL = gql`
    mutation removePlatformsFromMP($platformKeys: [String!]!) {
        removePlatformsFromMP(platformKeys: $platformKeys) {
            code
            success
            message
        }
    }
`;

const UNASSIGN_PLATFORM = gql`
    mutation removePlatformsFromUser($platformKeys: [String!]!) {
        removePlatformsFromUser(platformKeys: $platformKeys) {
            code
            success
            message
        }
    }
`;

const TOTAL_PLATFORM = gql`
    query getPlatforms {
        getPlatforms {
            result {
                platformKey
                managementPortalId
            }
        }
    }
`;

const messages = defineMessages({
    PlatformLabelPlatformKey: {id: 'Platform.label.PlatformKey', description: 'Platform label - Platform Key', defaultMessage:'Platform Key'},
    PlatformLabelPlatformType: {id: 'Platform.label.PlatformType', description: 'Platform label - Platform Type', defaultMessage:'Platform Type'},
    PlatformLabelCPUSN: {id: 'Platform.label.CPUSN', description: 'Platform label - CPU SN', defaultMessage:'CPU SN'},
    PlatformLabelMACAddress: {id: 'Platform.label.MACAddress', description: 'Platform label - MAC Address', defaultMessage:'MAC Address'},
    PlatformLabelManagementPortalName: {id: 'Platform.label.ManagementPortalName', description: 'Platform label - Management Portal Name', defaultMessage:'Management Portal Name'},
    PlatformLabelCreatedOn: {id: 'Platform.label.CreatedOn', description: 'Platform label - Created On', defaultMessage:'Created On'},
    
    DeletePlatformSuccess: {id: 'DeletePlatform.Success', description: 'Delete Platform Success', defaultMessage: 'Platform has been deleted successfully'},
    UnassignManagementPortalSuccess: {id: 'UnassignManagementPortal.Success', description: 'Unassign ManagementPortal Success', defaultMessage: 'Management Portal has been unassigned successfully'},

    NoRecordsLabel: { id: 'Platform.label.NoRecords', description: 'Platform label - NoRecords', defaultMessage: 'No Records Found' },
    toolTipDelete: {id: 'ToolTip.Delete', description: 'ToolTip For Delete Icon', defaultMessage: 'Delete'},
    toolTipUnassign: {id: 'ToolTip.Unassign', description: 'ToolTip For Unassign Icon', defaultMessage: 'Unassign'},
    PlatformRemoveContent: {id: 'Platform.Remove.content', description: 'Content displaying on the remove dialogue', defaultMessage: 'Are you sure you want to delete the Platform ?'},
    PlatformRemoveTitle: {id: 'Platform.Remove.Title', description: 'Title displaying on the remove dialogue', defaultMessage: 'Delete Platform'},
    ManagementPortalUnassignContent: {id: 'ManagementPortal.Unassign.content', description: 'Content displaying on the unassign dialogue', defaultMessage: 'Are you sure you want to unassign the Management Portal ?'},
    ManagementPortalUnassignTitle: {id: 'ManagementPortal.Unassign.Title', description: 'Title displaying on the unassign dialogue', defaultMessage: 'Unassign Management Portal'},
});

const Platform = () => {   
    const userContext = React.useContext(UserContext);
    const classes = useStyles();
    const { formatMessage } = useIntl();
    const {open: openSuccess, message: messageSuccess, handleOpen: showSuccess, handleClose: closeSuccess} = useAlert();
    const {open: openError, message: messageError, handleOpen: showError, handleClose: closeError} = useAlert();
    const {open: openWarning, message: messageWarning, handleOpen: showWarning, handleClose: closeWarning} = useAlert();
    const authUser = JSON.parse(sessionStorage.getItem('amz-user'));
    const isAdmin = authUser?.roles ? (authUser.roles).indexOf('admin') > -1 : false;
    const [ confirmRemoveOpen, setConfirmRemoveOpen ] = React.useState(false);
    const [ platformKey, setPlatformKey ] = React.useState(null);
    const [activeRow, setActiveRow] = React.useState(null);
    const [action, setAction] = React.useState(null);
    const [removeContent, setRemoveContent] = React.useState(null);
    const [removeTitle, setRemoveTitle] = React.useState(null);
    const [platforms, setPlatforms] = React.useState(0);
    const [totalPageCount, setTotalPageCount] = React.useState(0);
    const [offset, setOffset] = React.useState(0);
    const [sortModel, setSortModel] = React.useState({ column:'', type: 'asc' });
    const [searchFilter, setSearchFilter] = React.useState('');
    const [page, setPage] = React.useState(1);
    const [selectedRowCount, setSelectedRowCount] = React.useState(10);

    //const selectedRowCount = userContext?.rowCount ? userContext?.rowCount : global?.page?.defaultRowCount;
    const { loading: mpLoading, error: mpError, data: mpData, refetch: mpRefetch } = useQuery(LIST_MANAGEMENT_PORTALS, {
        notifyOnNetworkStatusChange: true,
        fetchPolicy: 'no-cache',
    });

    const [getTotalPlatforms, { data: totalPlatformsData, refetch: totalPlatformsRefetch }] = useLazyQuery(TOTAL_PLATFORM, {
        notifyOnNetworkStatusChange: true, 
        fetchPolicy: 'no-cache',
        onError(error) { console.log('error-', error) }
    });

    const [getPlatforms, { loading, error, data, refetch, networkStatus }] = useLazyQuery(LIST_PLATFORM, {
        notifyOnNetworkStatusChange: true, 
        fetchPolicy: 'no-cache',
        onCompleted(data) {
            if(offset === 0) {
                setPage(1);
            }
            setPlatforms(data?.getPlatforms ? data.getPlatforms : "");
            let totalPageCount = Math.floor(data?.getPlatforms?.totalCount/selectedRowCount);
            totalPageCount = data?.getPlatforms?.totalCount%selectedRowCount > 0 ? totalPageCount +1 : totalPageCount;
            setTotalPageCount(totalPageCount);
         },
        onError(error) { console.log('error-', error) }
    });

    const notAssignedPlatforms = totalPlatformsData?.getPlatforms?.result ? totalPlatformsData.getPlatforms.result.filter(p => !p.managementPortalId).map(p => p.platformKey) : [];

    const [removePlatforms, { loading: delLoading, error: delError, data: delData }] = useMutation(DELETE_PLATFORM, {
        onCompleted(data) {
            if (data?.removePlatforms?.success) {
                if ( offset != 0 && (platforms?.result?.length === 1)) {
                    setPage(page - 1);
                    setOffset(offset - selectedRowCount);
                    getPlatforms({variables: { page: {offset: offset - selectedRowCount, limit: selectedRowCount }}});
                }
                showSuccess(messages.DeletePlatformSuccess);
            }
            else {
                showError(data?.removePlatforms);
            }
        },
        onError(error) {
            showError(error);
        }
    });

    const [removePlatformsFromMP, { loading: unassignLoading, error: unassignError, data: unassignData }] = useMutation(UNASSIGN_MANAGEMENT_PORTAL, {
        onCompleted(data) {
            if (data?.removePlatformsFromMP?.success) {
                showSuccess(messages.UnassignManagementPortalSuccess);
            }
            else {
                showError(data?.removePlatformsFromMP);
            }
        },
        onError(error) {
            showError(error);
        }
    });

    const [removePlatformsFromUser, { loading: unassignPLLoading, error: unassignPLError, data: unassignPLData }] = useMutation(UNASSIGN_PLATFORM, {
        onCompleted(data) {
            if (data?.removePlatformsFromUser?.success) {
                showSuccess(messages.DeletePlatformSuccess);
            }
            else {
                showError(data?.removePlatformsFromUser);
            }
        },
        onError(error) {
            showError(error);
        }
    });
    
    const onDelete = (platformKey, index, action) => event => {
        if (action === "unassign-mp") {
            setRemoveTitle(messages.ManagementPortalUnassignTitle);
            setRemoveContent(messages.ManagementPortalUnassignContent);
        }
        else {
            setRemoveTitle(messages.PlatformRemoveTitle);
            setRemoveContent(messages.PlatformRemoveContent);
        }

        setConfirmRemoveOpen(true);
        setPlatformKey(platformKey);
        setActiveRow(index);
        setAction(action);
    };

    const confirmRemove = async () => {
        if (action === "remove-platform") {
            await removePlatforms({ variables: { platformKeys: [platformKey] } });
        }
        else if (action === "unassign-mp") {
            await removePlatformsFromMP({ variables: { platformKeys: [platformKey] } });
        }
        else if (action === "unassign-platform") {
            await removePlatformsFromUser({ variables: { platformKeys: [platformKey] } });
        }

        setConfirmRemoveOpen(false);
        setPlatformKey(null);
        setActiveRow(null);
        setAction(null);
    };

    const cancelRemove = () => {
        setConfirmRemoveOpen(false);
        setPlatformKey(null);
        setActiveRow(null);
        setAction(null);
    };

    const onPageChange = (event, value) => {
        const offset = (value - 1) * selectedRowCount;
        setOffset(offset);
        setPage(value);

        getPlatforms({variables: { page: {offset: offset, limit: selectedRowCount }, sort: {column: sortModel.column, type: sortModel.type }, searchFilter: {text: searchFilter} }});
    };

    const SortableTableCell = ({ name, classes, label, sortModel, onSort }) => {
        return (
            <TableCell>
                <TableSortLabel
                    className={classes.tableSortlabelColor}
                    classes={{icon: classes.tableSorticonColor}}
                    active={sortModel.column === name}
                    direction={(sortModel.column === name) ? sortModel.type : 'desc'}
                    onClick={() => onSort(name)}
                >
                    {label}
                </TableSortLabel>
            </TableCell>
        );
    }

    const onSort = (column) =>  {
        setOffset(0);
        console.log("onsort called");
        getPlatforms({variables: { page: {offset: 0, limit: selectedRowCount }, sort: {column: column, type:  sortModel.type === 'asc'? 'desc' : 'asc'}, searchFilter: {text: searchFilter} }});
        setSortModel({ column: column, type: sortModel.type === 'asc'? 'desc' : 'asc' });
    };

    const onFilterChange = (event) => {
        setOffset(0)
        setSearchFilter(event.target.value);

        getPlatforms({variables: {page: {offset: 0, limit: selectedRowCount }, searchFilter: {text: event.target.value} } } );
    }


    React.useEffect(() => {
        if(openSuccess || openWarning) {
           refetch({page: {offset: offset, limit: selectedRowCount }});
           totalPlatformsRefetch();
        }
    }, [openSuccess, openWarning]);

    React.useEffect(() => {
        getPlatforms({ variables: { page: {offset: offset, limit: selectedRowCount }, sort: {column: sortModel.column, type: sortModel.type }, searchFiltery: {text: searchFilter} } });
        getTotalPlatforms();
    }, []);

    const onRowCountSelected = (event) => {
        console.log ('Selected ', event.target.value);
        setOffset(0);
        setPage(1);
        setSelectedRowCount(parseInt(event.target.value));
        getPlatforms({ variables: { page: {offset: 0, limit: event.target.value }, sort: {column: sortModel.column, type: sortModel.type }, searchFiltery: {text: searchFilter} } });
    }
    // Not the best way to move progress spinner but it works for now
    /*if ((loading) || (networkStatus === 4)) return (
        <Container maxWidth="xl">
            <Grid container direction="column" justify="center" alignItems="center" spacing={8}>
                <Grid item />
                <Grid item >
                    <CircularProgress />
                </Grid>
            </Grid>
        </Container>
    );*/

    return (
        <Box>
            <Success open={openSuccess} message={messageSuccess} onClose={closeSuccess} />
            <Error open={openError} message={messageError} onClose={closeError} />
            <Warning open={openWarning} message={messageWarning} onClose={closeWarning} />
            <ConfirmRemove open={confirmRemoveOpen} title={removeTitle} content={removeContent} loading={delLoading || unassignLoading || unassignPLLoading} onClose={cancelRemove} onRemove={confirmRemove} />
            <Grid container direction="column" justify="flex-start" alignItems="center" spacing={2} className={classes.gridContainer}>
                <Grid item container direction="column" justify="flex-start" alignItems="center" spacing={2}>

                <Grid container direction="row" justify="flex-start" alignItems="center" spacing={2}>
                    <Grid item xs={6}>
                        <Box display="flex" justifyContent="flex-start">
                            <TextField InputProps={{ startAdornment: (<InputAdornment position="start"> <SearchIcon /> </InputAdornment>) }} margin="dense" size="small" variant="outlined" onChange={onFilterChange} />
                        </Box>
                    </Grid>

                    <Grid item container direction="row" justify="flex-end" alignItems="center" xs={6} spacing={2}>
                        {
                            !isAdmin &&
                            <Grid item>
                                <AssignManagementPortal mpData={mpData?.managementPortals?.managementPortals} notAssignedPlatforms={notAssignedPlatforms} showSuccess={showSuccess} showError={showError} />
                            </Grid>
                        }
                        <Grid item>
                            <ImportPlatform showSuccess={showSuccess} showError={showError} showWarning={showWarning} />
                        </Grid>
                    </Grid>
                </Grid>
                </Grid>
                <Grid item container direction="column" justify="flex-start" alignItems="center" spacing={2} className={classes.gridContainer}>

                    <TableContainer component={Paper}>
                        <Table className={classes.table} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                <SortableTableCell name={'platformKey'} classes={classes} label={formatMessage(messages.PlatformLabelPlatformKey)} sortModel={sortModel} onSort={onSort} />
                                <SortableTableCell name={'platformType'} classes={classes} label={formatMessage(messages.PlatformLabelPlatformType)} sortModel={sortModel} onSort={onSort} />
                                <SortableTableCell name={'cpuSN'} classes={classes} label={formatMessage(messages.PlatformLabelCPUSN)} sortModel={sortModel} onSort={onSort} />
                                <SortableTableCell name={'mac'} classes={classes} label={formatMessage(messages.PlatformLabelMACAddress)} sortModel={sortModel} onSort={onSort} />
                                {/* <TableCell><FormattedMessage {...messages.PlatformLabelMACAddress} /></TableCell> */}
                                <SortableTableCell name={'managementPortalId'} classes={classes} label={formatMessage(messages.PlatformLabelManagementPortalName)} sortModel={sortModel} onSort={onSort} />
                                <SortableTableCell name={'createdAt'} classes={classes} label={formatMessage(messages.PlatformLabelCreatedOn)} sortModel={sortModel} onSort={onSort} />
                                    {/* <TableCell>ID</TableCell> */}
                                   {/*  <TableCell><FormattedMessage {...messages.PlatformLabelPlatformKey} /></TableCell>
                                    <TableCell><FormattedMessage {...messages.PlatformLabelPlatformType} /></TableCell>
                                    <TableCell><FormattedMessage {...messages.PlatformLabelCPUSN} /></TableCell>
                                    <TableCell><FormattedMessage {...messages.PlatformLabelMACAddress} /></TableCell>
                                    <TableCell><FormattedMessage {...messages.PlatformLabelManagementPortalName} /></TableCell> */}
                                    {/* <TableCell>User Id</TableCell> */}
                                    {/* <TableCell><FormattedMessage {...messages.PlatformLabelCreatedOn} /></TableCell> */}
                                    <TableCell></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                            {
                                data === undefined ?
                                (
                                    <TableRow>
                                        <TableCell colSpan={7} display="flex" align="center">
                                            <Box mt={1} mb={1}>
                                                <CircularProgress size={24} />
                                            </Box>
                                        </TableCell>
                                    </TableRow>
                                ) :
                                (
                                    data?.getPlatforms?.result?.length ?
                                    (
                                        data?.getPlatforms?.result?.map((row, i) => (
                                            <TableRow key={i} hover className={clsx({
                                                [classes.showActionsOnHover]:true,
                                                [classes.selected]: activeRow === i ,
                                            })}>
                                                {/* <TableCell>{row._id}</TableCell> */}
                                                <TableCell>{row.platformKey}</TableCell>
                                                <TableCell>{row.platformType}</TableCell>
                                                <TableCell>{row.cpuSN}</TableCell>
                                                <TableCell>{row.mac}</TableCell>
                                                <TableCell>{
                                                            mpData?.managementPortals?.managementPortals?.find(mp => (mp._id === row.managementPortalId))?.mpName
                                                }</TableCell>
                                                {/* <TableCell>{row.userId}</TableCell> */}
                                                <TableCell>{new Date(row.createdAt).toLocaleString()}</TableCell>
                                                <TableCell size="small" align="center">
                                                    {
                                                        isAdmin && 
                                                        <Tooltip title={formatMessage(messages.toolTipDelete)}>
                                                            <IconButton aria-label="Delete" onClick={onDelete(row.platformKey, i, "remove-platform")}>
                                                                <DeleteIcon />
                                                            </IconButton>
                                                        </Tooltip>
                                                    }
                                                    {
                                                        !isAdmin && 
                                                        <Tooltip title={formatMessage(messages.toolTipUnassign)}>
                                                            <IconButton aria-label="Delete" onClick={onDelete(row.platformKey, i, "unassign-mp")} disabled={!row.managementPortalId}>
                                                                <SettingsBackupRestoreOutlinedIcon />
                                                            </IconButton>
                                                        </Tooltip>
                                                    }
                                                    {
                                                        !isAdmin && 
                                                        <Tooltip title={formatMessage(messages.toolTipDelete)}>
                                                            <IconButton aria-label="Delete" onClick={onDelete(row.platformKey, i, "unassign-platform")}>
                                                                <DeleteIcon />
                                                            </IconButton>
                                                        </Tooltip>
                                                    }
                                                </TableCell>
                                            </TableRow>
                                        ))
                                    ) :
                                    (
                                        <TableRow>
                                            <TableCell colSpan={7} display="flex">
                                                <Typography variant="body1" display="block">
                                                    <Box fontWeight="fontWeightBold" m={1} display="flex" justifyContent="center">
                                                        <FormattedMessage {...messages.NoRecordsLabel} />
                                                    </Box>
                                                </Typography>
                                            </TableCell>
                                        </TableRow>
                                    )
                                )
                            }
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            </Grid>
            {   platforms?.result &&
                    <Pagination
                        className={classes.pagination}
                        data={platforms?.result}
                        page={page}
                        selectedRowCount={selectedRowCount}
                        totalPageCount={totalPageCount}
                        onPageChange={onPageChange}
                        onRowCountSelected={onRowCountSelected}
                        hideRowSelection={false}
                    />
            }
        </Box>
    );
};

export default Platform;