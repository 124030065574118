import React from 'react';
import { Route, useHistory } from 'react-router-dom';
import AppDrawer from 'components/AppDrawer';

export const PublicRoute = ({ component: Component, ...rest }) => {
    const history = useHistory();

    if (sessionStorage.getItem('amz-user')) {
        history.push('/');
    }

    return (
        <Route
            {...rest}
            component={Component}
        />
    )
};

export default PublicRoute;
