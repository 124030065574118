import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => {
    return {
        actionSpacer: {
            marginRight: theme.spacing(2),
        },
        dialogPaper: {
            width: '30%'
        },
        formControl: {
            marginLeft: 0,
        },
    }
}, { index: 1 });