import React from 'react';
import clsx from 'clsx';
import { FormattedMessage, defineMessages, useIntl } from 'react-intl';

import {
    Box,
    CircularProgress,
    Grid,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Button,
    IconButton,
    AppBar,
    Toolbar,
    Typography,
    Tooltip,
    Container
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';

import useStyles from './styles';
import useAlert, { Success, Error } from 'components/Alert/CustomAlert';
import ConfirmRemove from 'components/ConfirmRemove';

import { gql } from 'apollo-boost';
import { useQuery, useMutation } from '@apollo/react-hooks';
import UpsertManagementPortal from './UpsertManagementPortal';

const LIST_MANAGEMENT_PORTAL = gql`
    query {
        managementPortals {
            managementPortals {
                _id
                mpName
                url
                accessKey
                rootCA
                platforms {
                    _id
                    platformKey
                }
                createdAt
            }
        }
    }
`;

const DELETE_MANAGEMENT_PORTAL = gql`
    mutation removeManagementPortal($id: String!) {
        removeManagementPortal(id: $id) {
            code
            success
            message
        }
    }
`;

const messages = defineMessages({
    ManagementPortalLabelName: {id: 'ManagementPortal.label.Name', description: 'Management Portal label - Name', defaultMessage:'Name'},
    ManagementPortalLabelURL: {id: 'ManagementPortal.label.URL', description: 'Management Portal label - URL', defaultMessage:'URL'},
    ManagementPortalLabelCertificate: {id: 'ManagementPortal.label.Certificate', description: 'Management Portal label - Certificate', defaultMessage:'Root CA'},
    ManagementPortalLabelAccessKey: {id: 'ManagementPortal.label.AccessKey', description: 'Management Portal label - AccessKey', defaultMessage:'Access Key'},
    ManagementPortalLabelCreatedOn: {id: 'ManagementPortal.label.CreatedOn', description: 'Management Portal label - Created On', defaultMessage:'Created On'},
    
    DeleteManagementPortalSuccess: {id: 'DeleteManagementPortal.Success', description: 'Delete ManagementPortal Success', defaultMessage: 'Management Portal has been deleted successfully'},

    NoRecordsLabel: { id: 'ManagementPortal.label.NoRecords', description: 'ManagementPortal label - NoRecords', defaultMessage: 'No Records Found' },
    toolTipDelete: {id: 'ToolTip.Delete', description: 'ToolTip For Delete Icon', defaultMessage: 'Delete'},
    ManagementPortalRemoveContent: {id: 'ManagementPortal.Remove.content', description: 'Content displaying on the remove dialogue', defaultMessage: 'Are you sure you want to delete the Management Portal ?'},
    ManagementPortalRemoveTitle: {id: 'ManagementPortal.Remove.Title', description: 'Title displaying on the remove dialogue', defaultMessage: 'Delete Management Portal'},
});

const ManagementPortal = () => {
    const classes = useStyles();
    const { formatMessage } = useIntl();
    const {open: openSuccess, message: messageSuccess, handleOpen: showSuccess, handleClose: closeSuccess} = useAlert();
    const {open: openError, message: messageError, handleOpen: showError, handleClose: closeError} = useAlert();
    const [ confirmRemoveOpen, setConfirmRemoveOpen ] = React.useState(false);
    const [ id, setId ] = React.useState(null);
    const [activeRow, setActiveRow] = React.useState(null);
    const [removeContent, setRemoveContent] = React.useState(messages.ManagementPortalRemoveContent);
    const authUser = JSON.parse(sessionStorage.getItem('amz-user'));
    const isUser = authUser?.roles ? (authUser.roles).indexOf('user') > -1 : false;
    
    const { loading, error, data, refetch, networkStatus } = useQuery(LIST_MANAGEMENT_PORTAL, { notifyOnNetworkStatusChange: true, fetchPolicy: 'no-cache', });
    
    const [removeManagementPortal, { loading: delLoading, error: delError, data: delData }] = useMutation(DELETE_MANAGEMENT_PORTAL, {
        onCompleted(data) {
            if (data?.removeManagementPortal?.success) {
                showSuccess(messages.DeleteManagementPortalSuccess);
            }
            else {
                showError(data?.removeManagementPortal);
            }
        },
        onError(error) {
            showError(error);
        }
    });
    
    const onDelete = (id, index) => event => {
        setConfirmRemoveOpen(true);
        setId(id);
        setActiveRow(index);
    };

    const confirmRemove = async () => {
        await removeManagementPortal({ variables: { id: id } });
        setConfirmRemoveOpen(false);
        setId(null);
        setActiveRow(null);
    };

    const cancelRemove = () => {
        setConfirmRemoveOpen(false);
        setId(null);
        setActiveRow(null);
    };
    
    React.useEffect(() => {
        if(openSuccess) {
            refetch();
        }
    }, [openSuccess]);
    
    // Not the best way to move progress spinner but it works for now
    /*if ((loading) || (networkStatus === 4)) return (
        <Container maxWidth="xl">
            <Grid container direction="column" justify="center" alignItems="center" spacing={8}>
                <Grid item />
                <Grid item >
                    <CircularProgress />
                </Grid>
            </Grid>
        </Container>
    );*/

    return (
        <Box>
            <Success open={openSuccess} message={messageSuccess} onClose={closeSuccess} />
            <Error open={openError} message={messageError} onClose={closeError} />
            <ConfirmRemove open={confirmRemoveOpen} title={messages.ManagementPortalRemoveTitle} content={removeContent} loading={delLoading} onClose={cancelRemove} onRemove={confirmRemove} />
            <Grid container direction="column" justify="flex-start" alignItems="center" spacing={2} className={classes.gridContainer}>
                <Grid item container direction="column" justify="flex-start" alignItems="center" spacing={2}>
                    <Grid container direction="row" justify="flex-end" alignItems="center" spacing={2}>
                    {
                        isUser && 
                        <Grid item>
                            <UpsertManagementPortal showSuccess={showSuccess} showError={showError} />
                        </Grid>
                    }
                    </Grid>
                </Grid>
                <Grid item container direction="column" justify="flex-start" alignItems="center" spacing={2} className={classes.gridContainer}>
                    <TableContainer component={Paper}>
                        <Table className={classes.table} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell><FormattedMessage {...messages.ManagementPortalLabelName} /></TableCell>
                                    <TableCell><FormattedMessage {...messages.ManagementPortalLabelURL} /></TableCell>
                                    <TableCell><FormattedMessage {...messages.ManagementPortalLabelAccessKey} /></TableCell>
                                    <TableCell><FormattedMessage {...messages.ManagementPortalLabelCertificate} /></TableCell>
                                    {/*<TableCell>User Id</TableCell>*/}
                                    <TableCell><FormattedMessage {...messages.ManagementPortalLabelCreatedOn} /></TableCell>
                                    <TableCell></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                            {
                                data === undefined ?
                                (
                                    <TableRow>
                                        <TableCell colSpan={7} display="flex" align="center">
                                            <Box mt={1} mb={1}>
                                                <CircularProgress size={24} />
                                            </Box>
                                        </TableCell>
                                    </TableRow>
                                ) :
                                (
                                    data?.managementPortals?.managementPortals?.length ?
                                    (
                                        data?.managementPortals?.managementPortals?.map((row, i) => (
                                            <TableRow key={i} hover className={clsx({
                                                [classes.showActionsOnHover]:true,
                                                [classes.selected]: activeRow === i ,
                                            })}>
                                                <TableCell>{row.mpName}</TableCell>
                                                <TableCell>{row.url?.join(", ")}</TableCell>
                                                <TableCell>{row.accessKey}</TableCell>
                                                <TableCell>{
                                                    row.rootCA?.length > 20 ?
                                                    row.rootCA?.slice(0, 21)?.padEnd(24, '.') :
                                                    (row.rootCA ?? "N/A")
                                                }</TableCell>
                                                {/*<TableCell>{row.userId}</TableCell>*/}
                                                <TableCell>{new Date(row.createdAt).toLocaleString()}</TableCell>
                                                <TableCell size="small" align="center">
                                                {
                                                    <UpsertManagementPortal showSuccess={showSuccess} showError={showError} row={row} setActiveRow={setActiveRow} activeRow={i} />
                                                }
                                                    <Tooltip title={formatMessage(messages.toolTipDelete)}>
                                                        <IconButton aria-label="Delete" onClick={onDelete(row._id, i)}>
                                                            <DeleteIcon />
                                                        </IconButton>
                                                    </Tooltip>
                                                </TableCell>
                                            </TableRow>
                                        ))
                                    ) :
                                    (
                                        <TableRow>
                                            <TableCell colSpan={7} display="flex">
                                                <Typography variant="body1" display="block">
                                                    <Box fontWeight="fontWeightBold" m={1} display="flex" justifyContent="center">
                                                        <FormattedMessage {...messages.NoRecordsLabel} />
                                                    </Box>
                                                </Typography>
                                            </TableCell>
                                        </TableRow>
                                    )
                                )
                            }
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            </Grid>
        </Box>
    );
};

export default ManagementPortal;

