import React from 'react';
import clsx from 'clsx';

import {
    Box,
    CircularProgress,
    Grid,
    Typography,
    Button,
    IconButton,
    Tooltip,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    FormControlLabel,
    FormLabel,
    FormControl,
    FormGroup,
    Chip,
} from '@material-ui/core';

import {
    TextField,
} from 'formik-material-ui';

import useStyles from './UpsertManagementPortal.styles';
import EditIcon from '@material-ui/icons/Edit'
import CloseIcon from '@material-ui/icons/Close';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import { FormattedMessage, defineMessages, useIntl } from 'react-intl';
import { gql } from 'apollo-boost';
import { useQuery, useMutation } from '@apollo/react-hooks'
import { Formik, Form, Field } from 'formik';
import { regex } from 'config';
const uuidAPIKey = require('uuid-apikey');


const messages = defineMessages({

    UpsertManagementPortalAddDialogTitle: {id: 'UpsertManagementPortal.Add.dialog.header', description: 'Upsert Management Portal Add dialog header', defaultMessage:'Add Management Portal'},
    UpsertManagementPortalEditDialogTitle: {id: 'UpsertManagementPortal.Edit.dialog.Title', description: 'Upsert Management Portal Edit dialog Title', defaultMessage: 'Update Management Portal'},
    UpsertManagementPortalButtonAdd: {id: 'UpsertManagementPortal.button.add', description: 'Upsert Management Portal button - Add', defaultMessage:'Add'},
    UpsertManagementPortalButtonUpdate: {id: 'UpsertManagementPortal.button.Update', description: 'Upsert Management Portal button - Update', defaultMessage:'Update'},
    UpsertManagementPortalButtonCancel: {id: 'UpsertManagementPortal.button.Cancel', description: 'Upsert Management Portal button - Cancel', defaultMessage:'Cancel'},

    UpsertManagementPortalLabelName: {id: 'UpsertManagementPortal.label.Name', description: 'Update Portal label - Name', defaultMessage:'Name'},
    UpsertManagementPortalLabelUrl: {id: 'UpsertManagementPortal.label.URL', description: 'Update Portal label - URL', defaultMessage:'URL'},
    UpsertManagementPortalLabelAccessKey: {id: 'UpsertManagementPortal.label.AccessKey', description: 'Update Portal label - AccessKey', defaultMessage:'Access Key'},
    UpsertManagementPortalLabelRootCA: {id: 'UpsertManagementPortal.label.RootCA', description: 'Update Portal label - RootCA', defaultMessage:'Root Certificate'},

    toolTipEdit: {id: 'ToolTip.Edit', description: 'ToolTip For Edit Icon', defaultMessage: 'Edit'},
    toolTipClose: {id: 'ToolTip.Close', description: 'ToolTip For Close Icon', defaultMessage: 'Close'},

    UpsertManagementPortalAddSuccess: {id: 'UpsertManagementPortal.Add.Success', description: 'Add Management Portal Add Success', defaultMessage: 'Management Portal added successfully.'},
    UpsertManagementPortalEditSuccess: {id: 'UpsertManagementPortal.Edit.Success', description: 'EditPortal Success Edit Message', defaultMessage: 'Management Portal has been updated successfully'},
});

const ADD_MANAGEMENT_PORTAL = gql`
    mutation addManagementPortal($managementPortal: ManagementPortalInput!) {
        addManagementPortal(managementPortal: $managementPortal) {
            code
            success
            message
        }
    }
`;

const EDIT_MANAGEMENT_PORTAL = gql`
    mutation editManagementPortal($id: String!, $mpName: String!, $url: [String!]!, $accessKey: String!, $rootCA: String) {
        editManagementPortal(id:$id, mpName:$mpName, url: $url, accessKey:$accessKey, rootCA: $rootCA) {
            code
            success
            message
        }
    }
`;

const UpsertManagementPortal = props => {
    const classes = useStyles();
    const { formatMessage } = useIntl();
    const [open, setOpenDialog] = React.useState(false);
    const { showSuccess, showError, row, activeRow, setActiveRow } = props;
    const [cert, setCert] = React.useState(null);

    const [addManagementPortal, { loading: addLoading, error: addError, data: addData }] = useMutation(ADD_MANAGEMENT_PORTAL, {
        onCompleted(data) {
            if (data && data.addManagementPortal.success === true) {
                showSuccess(messages.UpsertManagementPortalAddSuccess);
            }
            else {
                showError(data?.addManagementPortal);
            }
            setOpenDialog(false);
        },
        onError(error) {
            showError(error);
            setOpenDialog(false);
        }
    });

    const [EditPortal, { loading, error, data }] = useMutation(EDIT_MANAGEMENT_PORTAL, {
        onCompleted(data) {
            if (data?.editManagementPortal?.success === true) {
                showSuccess(messages.UpsertManagementPortalEditSuccess);
            }
            else {
                showError(data?.editManagementPortal);
            }
        },
        onError(error) {
            showError(error);
        }
    });

    const readContent = (FileObject, setFieldValue) => {
        let reader = new FileReader();
        reader.readAsText(FileObject);
        reader.onload = function() {
            setCert(reader.result);
            setFieldValue("certName", FileObject.name);
        };
    }

    const handleClickOpen = event => {
        event.stopPropagation();
        setOpenDialog(true);
        if (Number.isInteger(activeRow) && setActiveRow) {
            setActiveRow(activeRow);
        }
    };

    const handleClose = () => {
        setOpenDialog(false);
        if (Number.isInteger(activeRow) && setActiveRow) {
            setActiveRow(null);
        }
    };

    const handleAddURL = (values, setFieldValue, setFieldError) => {
        console.log(values, "handleAddURL...");
        if (values.url && regex.URL.test(values.url)) {
            const uniqueURLs = [...values.urls, values.url].filter((value, index, arr) => arr.indexOf(value) === index);
            setFieldValue('url', "");
            setFieldValue('urls', uniqueURLs);
        }
    };

    const handleDeleteURL = (value, values, setFieldValue) => (event) => {
        setFieldValue('urls', values.urls.filter(element => (element !== value)));
    };

    return (
        <>
            {
                row ?
                <Tooltip title={formatMessage(messages.toolTipEdit)}>
                    <IconButton aria-label="Update" onClick={handleClickOpen}>
                        <EditIcon />
                    </IconButton>
                </Tooltip>:
                <Button variant="outlined" color="primary" onClick={handleClickOpen}>
                    <FormattedMessage {...messages.UpsertManagementPortalAddDialogTitle} />
                </Button>
            }
            <Formik
                enableReinitialize={true}
                initialValues={{
                    mpName: row?.mpName ? row.mpName : "",
                    url: "",
                    urls: row?.url?.length ? row.url : [],
                    accessKey: row?.accessKey ? row.accessKey : "",
                    rootCA: row?.rootCA ?? "",
                }}

                validate={values => {
                    const errors = {};
                    if (!values.mpName.trim()) {
                        errors.mpName = 'Required';
                    }

                    if (values.url) {
                        if (!regex.URL.test(values.url)) {
                            errors.url = 'Please enter valid URL';
                        }
                        else if(values.urls && !values.urls.length){
                            errors.url = 'Please click add button.';
                        }
                    }
                    else if (!values.urls.length) {
                        errors.url = 'Required';
                    }

                    /* if (!values.rootCA) {
                        errors.rootCA = 'Required';
                    } */

                    if (!values?.accessKey?.trim()) {
                        errors.accessKey = 'Required';
                    }
                    else if (values?.accessKey?.trim() && !uuidAPIKey.isAPIKey(values?.accessKey?.trim())) {
                        errors.accessKey = 'Invalid accesskey';
                    }

                    console.log(errors, "errors...");
                    return errors;
                }}
                onSubmit={async (values, { setSubmitting, resetForm }) => {
                    let params = {
                        mpName: values.mpName,
                        url: values.urls,
                        accessKey: values.accessKey,
                        rootCA: (values.rootCA).trim() ? values.rootCA : null,
                    };

                    if (row) {
                        params['id'] = row._id;
                        await EditPortal({ variables: params });
                    }
                    else {
                        await addManagementPortal({ variables: { managementPortal: params } });
                    }
                    setSubmitting(false);
                    handleClose();
                    resetForm();
                }}
            >
                {({ submitForm, isSubmitting, values, setFieldValue, setFieldError }) => (
                    <Dialog open={open} onClose={handleClose} aria-labelledby="removeIP-dialog" classes={{ paper: classes.dialogPaper }} onKeyPress= {(e) => { if(!isSubmitting && e.which === 13) {submitForm()}}}>
                        <DialogTitle id="removeIP-dialog-title">
                            {
                                row ? <FormattedMessage {...messages.UpsertManagementPortalEditDialogTitle} />
                                : <FormattedMessage {...messages.UpsertManagementPortalAddDialogTitle} />
                            }

                            <Tooltip title={formatMessage(messages.toolTipClose)}>
                                <IconButton aria-label="close" className={classes.closeButton} onClick={handleClose}>
                                    <CloseIcon />
                                </IconButton>
                            </Tooltip>
                        </DialogTitle>
                        <DialogContent dividers>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Field component={TextField} type="text" name="mpName" disabled={row ? true : false} label={formatMessage(messages.UpsertManagementPortalLabelName)} margin="dense" variant="outlined" fullWidth/>
                                </Grid>
                                {/* <Grid item xs={12}>
                                    <FilePicker
                                        onChange={FileObject =>( readContent(FileObject, setFieldValue) )}
                                        onError={errMsg => ( console.log(errMsg, "errMsg") )}
                                        id="select-cert"
                                    >
                                        <Field
                                            component={TextField}
                                            type="text"
                                            name="certName"
                                            variant="outlined"
                                            margin="dense"
                                            label={formatMessage(messages.AddManagementPortalLabelBrowseCertificate)}
                                            InputProps={{
                                                readOnly: true,
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                            <IconButton variant="outlined" size="small">
                                                                <PublishIcon />
                                                            </IconButton>
                                                    </InputAdornment>
                                                )
                                            }}
                                            fullWidth
                                        />
                                    </FilePicker>
                                </Grid> */}
                                <Grid container item xs={12}>
                                    <Grid item xs={11}>
                                        <Field
                                            component={TextField}
                                            type="text"
                                            name="url"
                                            label={formatMessage(messages.UpsertManagementPortalLabelUrl)}
                                            margin="dense"
                                            variant="outlined"
                                            fullWidth
                                            onKeyDown={(e) => { if (e.keyCode == 13) { e.preventDefault(); handleAddURL(values, setFieldValue, setFieldError) } }}
                                        />
                                    </Grid>
                                    <Grid item xs={1}>
                                        <Box mt={0.5}>
                                            <IconButton 
                                                aria-label="Add"
                                                disabled={!values.url?.trim() || isSubmitting}
                                                onClick={() => {handleAddURL(values, setFieldValue, setFieldError)}}
                                            >
                                                <AddCircleIcon />
                                            </IconButton>
                                        </Box>
                                    </Grid>
                                </Grid>
                                {
                                    (values.urls?.length > 0) &&
                                    <Grid item xs={12}>
                                    {
                                        values.urls?.map(element => (
                                            <Chip
                                                label={element}
                                                className={classes.chips}
                                                onDelete={handleDeleteURL(element, values, setFieldValue)}
                                                onClick={() => {
                                                    setFieldValue('url', element);
                                                }}
                                            />
                                        ))
                                    }
                                    </Grid>
                                }
                                <Grid item xs={12}>
                                    <Field component={TextField} type="text" name="accessKey" label={formatMessage(messages.UpsertManagementPortalLabelAccessKey)} margin="dense" variant="outlined" fullWidth/>
                                </Grid>
                                <Grid item xs={12}>
                                    <Field component={TextField} multiline rows={4} type="text" name="rootCA" label={formatMessage(messages.UpsertManagementPortalLabelRootCA)} margin="dense" variant="outlined" fullWidth/>
                                </Grid>
                            </Grid>
                        </DialogContent>
                        <DialogActions>
                            {isSubmitting && <CircularProgress size={24} className={classes.actionSpacer} />}
                                <Button variant="text" onClick={handleClose}>
                                    <FormattedMessage {...messages.UpsertManagementPortalButtonCancel} />
                                </Button>
                                <Button varient="text" onClick={submitForm} disabled={isSubmitting} color="primary">
                                    {
                                        row ?
                                        <FormattedMessage {...messages.UpsertManagementPortalButtonUpdate} />:
                                        <FormattedMessage {...messages.UpsertManagementPortalButtonAdd} />
                                    }
                                </Button>
                        </DialogActions>
                    </Dialog>
                )}
            </Formik>
        </>
    );
}

export default UpsertManagementPortal;