import React from 'react';
import clsx from 'clsx';

import {
    Box,
    CircularProgress,
    Grid,
    Typography,
    Button,
    IconButton,
    Tooltip,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    FormControlLabel,
    FormLabel,
    FormControl,
    FormGroup

} from '@material-ui/core';

import {
    TextField,
} from 'formik-material-ui';
import Checkbox from '@material-ui/core/Checkbox';

import useStyles from './UpdateUserStyles';
import EditIcon from '@material-ui/icons/Edit'
import CloseIcon from '@material-ui/icons/Close';
import { FormattedMessage, defineMessages, useIntl } from 'react-intl';
import { gql } from 'apollo-boost';
import { useQuery, useMutation } from '@apollo/react-hooks'
import { Formik, Form, Field } from 'formik';


const messages = defineMessages({
    title: {id: 'UpdateUser.dialog.Title', description: 'UpdateUser dialog Title', defaultMessage: 'Edit User'},
    UpdateUserButtonUpdate: {id: 'UpdateUser.button.Update', description: 'Update User button - Update', defaultMessage:'Update'},
    UpdateUserButtonCancel: {id: 'UpdateUser.button.Cancel', description: 'Update User button - Cancel', defaultMessage:'Cancel'},

    UpdateUserLabelFN: {id: 'UpdateUser.label.FirstName', description: 'Update User label - First Name', defaultMessage:'First Name'},
    UpdateUserLabelLN: {id: 'UpdateUser.label.LastName', description: 'Update User label - Last Name', defaultMessage:'Last Name'},
    UpdateUserLabelEmail: {id: 'UpdateUser.label.Email', description: 'Update User label - Email', defaultMessage:'Email'},
    UpdateUserLabelRoles: {id: 'UpdateUser.label.Roles', description: 'Update User label - Roles', defaultMessage:'Roles'},
    UpdateUserLabelAdmin: {id: 'UpdateUser.label.Admin', description: 'Update User label - Admin', defaultMessage:'Admin'},
    UpdateUserLabelUser: {id: 'UpdateUser.label.User', description: 'Update User label - User', defaultMessage:'User'},

    toolTipEdit: {id: 'ToolTip.Edit', description: 'ToolTip For Edit Icon', defaultMessage: 'Edit'},
    toolTipClose: {id: 'ToolTip.Close', description: 'ToolTip For Close Icon', defaultMessage: 'Close'},

    UpdateUserSuccess: {id: 'UpdateUser.Success', description: 'UpdateUser Success Message', defaultMessage: 'User has been updated successfully'},
});

const UPDATE_USER = gql`
    mutation updateUser($email: String!, $firstName: String!, $lastName: String!, $roles: [String!]!) {
        updateUser(email: $email, firstName: $firstName, lastName: $lastName, roles: $roles) {
            code
            success
            message
        }
    }
`;

const UpdateUser = props => {
    const classes = useStyles();
    const { formatMessage } = useIntl();
    const [open, setOpenDialog] = React.useState(false);
    const { showSuccess, showError, user, activeRow, setActiveRow } = props;
    const [roles, setRoles] = React.useState([ ...(user.roles) ]);

    const [updateUser, { loading, error, data }] = useMutation(UPDATE_USER, {
        onCompleted(data) {
            if (data.updateUser.success === true) {
                showSuccess(messages.UpdateUserSuccess);
            }
            else {
                showError(data?.updateUser);
            }
        },
        onError(error) {
            showError(error);
        }
    });

    const handleChange = (event) => {
        if (event.target.checked) {
            setRoles([ ...roles, event.target.name ]);
        }
        else {
            const index = roles.indexOf(event.target.name);
            if (index > -1) {
                const newRoles = JSON.parse(JSON.stringify(roles));
                newRoles.splice(index, 1);
                setRoles(newRoles);
            }
        }
    };

    const handleClickOpen = event => {
        event.stopPropagation();
        setOpenDialog(true);
        if (Number.isInteger(activeRow) && setActiveRow) {
            setActiveRow(activeRow);
        }
    };

    const handleClose = () => {
        setOpenDialog(false);
        if (Number.isInteger(activeRow) && setActiveRow) {
            setActiveRow(null);
        }
    };

    return (
        <>
            {
                user &&
                <Tooltip title={formatMessage(messages.toolTipEdit)}>
                    <IconButton aria-label="Update" onClick={handleClickOpen}>
                        <EditIcon />
                    </IconButton>
                </Tooltip>
            }
            <Formik
                enableReinitialize={true}
                initialValues={{
                    firstName: user?.firstName ? user.firstName : "",
                    lastName: user?.lastName ? user.lastName : "",
                    email: user?.email ? user.email : "",
                }}
                validate={values => {
                    const errors = {};

                    if(!values.firstName) {
                        errors.firstName = "Required";
                    }

                    if(!values.lastName) {
                        errors.lastName = "Required";
                    }

                    if(!values.email) {
                        errors.email = "Required"
                    }

                    return errors;
                }}
                onSubmit={async (values, { setSubmitting, resetForm }) => {
                    let params = { firstName: values.firstName, lastName: values.lastName, email: values.email, roles: roles};

                    await updateUser({ variables: params });
                    setSubmitting(false);
                    handleClose();
                    resetForm();
                }}
            >
                {({ submitForm, isSubmitting, values, setFieldValue }) => (
                    <Dialog open={open} onClose={handleClose} aria-labelledby="removeIP-dialog" classes={{ paper: classes.dialogPaper }} onKeyPress= {(e) => { if(!isSubmitting && e.which === 13) {submitForm()}}}>
                        <DialogTitle id="removeIP-dialog-title">
                            <FormattedMessage {...messages.title} />
                            <Tooltip title={formatMessage(messages.toolTipClose)}>
                                <IconButton aria-label="close" className={classes.closeButton} onClick={handleClose}>
                                    <CloseIcon />
                                </IconButton>
                            </Tooltip>
                        </DialogTitle>
                        <DialogContent dividers>
                            <Grid container spacing={2}>
                                <Grid item xs={6}>
                                    <Field component={TextField} type="text" name="firstName" label={formatMessage(messages.UpdateUserLabelFN)} margin="dense" variant="outlined" fullWidth/>
                                </Grid>
                                <Grid item xs={6}>
                                    <Field component={TextField} type="text" name="lastName" label={formatMessage(messages.UpdateUserLabelLN)} margin="dense" variant="outlined" fullWidth/>
                                </Grid>
                                <Grid item xs={12}>
                                    <Field component={TextField} type="text" name="email" label={formatMessage(messages.UpdateUserLabelEmail)} className={classes.disable} disabled margin="dense" variant="outlined" fullWidth/>
                                </Grid>
                                <Grid item xs={12} className={classes.text} direction = "column" alignItems="flex-start">
                                    <Grid>
                                        <Typography variant="h6">
                                            <FormattedMessage {...messages.UpdateUserLabelRoles} />
                                        </Typography>
                                    </Grid>
                                    <Grid>
                                        <FormGroup>
                                            <Grid>
                                                <FormControlLabel
                                                    control={<Checkbox checked={roles.indexOf("admin") > -1} onChange={handleChange} name="admin" />}
                                                    label={formatMessage(messages.UpdateUserLabelAdmin)} />
                                            </Grid>
                                            <Grid>
                                                <FormControlLabel
                                                    control={<Checkbox checked={roles.indexOf("user") > -1} disabled onChange={handleChange} name="user" />}
                                                    label={formatMessage(messages.UpdateUserLabelUser)} />
                                                </Grid>
                                        </FormGroup>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </DialogContent>
                        <DialogActions>
                            {isSubmitting && <CircularProgress size={24} className={classes.actionSpacer} />}
                                <Button variant="text" onClick={handleClose}>
                                    <FormattedMessage {...messages.UpdateUserButtonCancel} />
                                </Button>
                                <Button varient="text" onClick={submitForm} disabled={isSubmitting} color="primary">
                                    <FormattedMessage {...messages.UpdateUserButtonUpdate} />
                                </Button>
                        </DialogActions>
                    </Dialog>
                )}
            </Formik>
        </>
    );
}

export default UpdateUser;