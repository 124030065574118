const regex = {
    MAC: /^([0-9A-Fa-f]{2}[:-]){5}([0-9A-Fa-f]{2})$/,
    //Mail: /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/, //E-mail with special characters
    Mail: /^[a-z0-9]+@[a-z-]+(\.[a-z]{2,3}){1,}$/,
    URL: /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/
};

const global = {
    page: {
        defaultRowCount: 10,               // limiting number of rows in a table by 10
        minRowCount: 5,                    // limiting number of rows in a table by 5
        maxRowCount: 10                    // limiting number of rows in a table by 10
    },
}

export { regex, global };