import React from 'react';
import { Autocomplete } from '@material-ui/lab';
import { TextField, Checkbox } from '@material-ui/core';
import { fieldToTextField } from 'formik-material-ui';

import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';

const icon = <CheckBoxOutlineBlankIcon />;
const checkedIcon = <CheckBoxIcon />;

const FormikMultiAutocomplete = ({ textFieldProps, ...props }) => {

    const { form: { setFieldTouched, setFieldValue } } = props;
    const { error, helperText, ...field } = fieldToTextField(props);
    const { name } = field;

    return (
        <Autocomplete
        multiple
        disableCloseOnSelect
        {...props}
        {...field}
        onChange={ (_, value) => setFieldValue(name, value) }
        onBlur={ () => {
                console.log('[name]', name);
                setFieldTouched(name, true);
            }
        }
        renderInput={ props => (
            <TextField {...props} {...textFieldProps} helperText={helperText} error={error} placeholder="Search" />
        )}
        />
    );
}

export default FormikMultiAutocomplete;