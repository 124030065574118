import { useApolloClient } from '@apollo/react-hooks';
import { useHistory } from 'react-router-dom';

const useAuthUser = () => {
    const client = useApolloClient();
    const history = useHistory();

    const setAuthUser = (authUser) => {
        let user = (({email, firstName, lastName, roles}) => ({email, firstName, lastName, roles}))(authUser?.user);
        user.token = authUser?.token;

        sessionStorage.setItem('amz-user', JSON.stringify(user));
        history.push('/');
    };

    const logout = () => {
        client.resetStore();
        sessionStorage.clear();
        history.push('/login');
    };

    return { logout, setAuthUser };
};

export default useAuthUser;