import React from 'react';
import PropTypes from 'prop-types';
import messages from './SnackbarAlert.messages';

import {
    Snackbar,
    Button
} from '@material-ui/core';

import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';
import { FormattedMessage, useIntl } from 'react-intl';

const SUPPORT_SERVER_URL =  process.env.SUPPORT_SERVER_URL || 'http://support.amzetta.com/cpe/';

/* const decodeError = (error, data, formatMessage) => {

    if (error) { // Error reported by Network or GraphQL etc.  This part need to be refined. TBD - Handle new error messages.
        const temp = error.message.split(":");
        const titleId = temp[0];
        const title = messages[titleId] ? formatMessage(messages[titleId]) : titleId;
        const content = temp.slice (1).join (":");
        const code = messages[titleId] ? messages[titleId].id : messages['Unknown error'].id;
        return {content, title, code};
    } else if (data.success === false) { // GraphQL query/mutation executed, but server reported error status with error code & messages.
        const code = data.code;
        const titleId = messages[code] ? messages[code] : messages['Unknown error'];
        const title = formatMessage(titleId);
        const content = data.message;
        return {content, title, code};
    } else {
      console.error ('Unknow error type, handle this.');
    }
} */

const SnackbarAlert = props => {
    const { open, onClose, message: objMessage, severity } = props;
    const { formatMessage } = useIntl();

    if(objMessage && objMessage.id) {
        /**
         * Show user defined Success or Error message
         */
        return (
            <Snackbar open={open} autoHideDuration={6000} onClose={onClose}>
                <Alert elevation={6} variant="filled" onClose={onClose} severity={severity}>
                    <FormattedMessage {...objMessage} />
                </Alert>
            </Snackbar>
        );
    }
    else if(objMessage && objMessage.code) {
        /**
         * Show the Error message from onCompleted(data) in Query or Mutation
         */
        const code = objMessage.code;
        const unknownError = { ...(messages['Unknown error']), defaultMessage: code };
        const titleId = messages[code] ? messages[code] : unknownError;
        const title = formatMessage(titleId);
        const content = objMessage.message;

        const helpLink = SUPPORT_SERVER_URL + "error?code=" + code;

        return (
            <Snackbar open={open} autoHideDuration={6000} onClose={onClose}>
                <Alert elevation={6} variant="filled" onClose={onClose} severity={severity}
                action= {
                    <>
                        <Button onClick={()=> window.open(helpLink, "_blank")} color="inherit" size="small">
                            HELP
                        </Button>
                        <Button color="inherit" size="small" onClick={onClose}>
                            CLOSE
                        </Button>
                    </>
                }>
                    <AlertTitle>{title}</AlertTitle>
                    {content}
                </Alert>
            </Snackbar>
        );
    }
    else if(objMessage && objMessage.message) {
        /**
         * Show error message from onError(error) in Query or Mutation
         */
        const temp = objMessage.message.split(":");
        const titleId = temp[0];
        const title = messages[titleId] ? formatMessage(messages[titleId]) : titleId;
        const content = temp.slice (1).join (":");
        const code = messages[titleId] ? messages[titleId].id : messages['Unknown error'].id;

        const helpLink = SUPPORT_SERVER_URL + "error?code=" + code;

        return (
            <Snackbar open={open} autoHideDuration={6000} onClose={onClose}>
                <Alert elevation={6} variant="filled" onClose={onClose} severity={severity}
                action= {
                    <>
                        <Button onClick={()=> window.open(helpLink, "_blank")} color="inherit" size="small">
                            HELP
                        </Button>
                        <Button color="inherit" size="small" onClick={onClose}>
                            CLOSE
                        </Button>
                    </>
                }>
                    <AlertTitle>{title}</AlertTitle>
                    {content}
                </Alert>
            </Snackbar>
        );
    }
    else {
        return null;
    }
}

SnackbarAlert.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    message: PropTypes.object,
    severity: PropTypes.string.isRequired
};

export default SnackbarAlert;