import React from 'react';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import { 
    Box,
    Grid,
    Typography,
    MenuItem,
    FormControl,
    Select,
} from '@material-ui/core';


import useStyles from './CustomPagination.styles';
import Pagination from '@material-ui/lab/Pagination';

const messages = defineMessages({
    rowsPerPageLabel: {id: 'rowsPerPage.label', description: 'Rows per page label', defaultMessage: 'Rows per page'},
});


const PaginationComponent = props => {

    const { formatMessage } = useIntl();

    const { data, totalPageCount, page, selectedRowCount, onRowCountSelected, onPageChange, hideRowSelection} = props;
    const classes = useStyles();

    const rowCountValues = [10,25,50,100];

    return (
        <Box>
             <Grid direction="row" alignItems="center" container className={classes.marginTop}>
                    {
                        ((data !== 'null' && data?.length !== 0) && (!hideRowSelection)) ?
                        <Grid item xs={6} container direction="row">
                            <Typography variant="body1" display="block" color="primary">
                                <Box fontWeight="fontWeightMedium" pt={2} pr={1} display="flex" justifyContent="center">
                                    <FormattedMessage {...messages.rowsPerPageLabel} />
                                </Box>
                            </Typography>
                            <Box>
                            {
                                <FormControl size="small" variant="outlined" margin="dense">
                                    <Select
                                    labelId="rowCount-select-label"
                                    id="rowCount-select"
                                    value={selectedRowCount}
                                    onChange={onRowCountSelected}
                                    >
                                        {
                                            rowCountValues?.map(element => (
                                                <MenuItem key={element} value={element}>
                                                    {element}
                                                </MenuItem>
                                            ))
                                        }
                                    </Select>
                                </FormControl>
                            }
                            </Box>
                        </Grid> :
                        <Grid item xs={6} container direction="row" />
                    }
                    { ((data?.length === 0) || (data === undefined)) ||
                        <Grid item xs={6} container justify="flex-end">
                            <Pagination className={classes.pagination} page={page} count={totalPageCount} showFirstButton showLastButton onChange={onPageChange} />
                        </Grid>
                    }
                </Grid>
        </Box>
    )
}

export default PaginationComponent;