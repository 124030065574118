import React from 'react';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import { Formik, Form, Field } from 'formik';
import { TextField } from 'formik-material-ui';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import { FormattedMessage, defineMessages, useIntl } from 'react-intl';
import useAlert, { Success, Error } from 'components/Alert/CustomAlert';

import { gql } from 'apollo-boost';
import { useQuery, useMutation } from '@apollo/react-hooks';

import useStyles from './ResetPassword.styles';
import { UserContext } from 'containers/App/UserContext';
import {  useHistory, useLocation } from 'react-router-dom';

const VERIFY_RESET_PASSWORD_TOKEN =gql`
    mutation verifyResetPasswordToken($token: String!){
        verifyResetPasswordToken (token: $token) {
            code
            success
            message
        }
    }`;

const RESET_PASSWORD = gql`
    mutation resetPassword($token: String!, $newPassword: String!) {
        resetPassword(token: $token, newPassword: $newPassword) {
            code
            success
            message
        }
}`;

const Copyright = () => {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            {'Copyright © '}
            {new Date().getFullYear()}{' '}
            {'AmZetta Technologies, All Rights Reserved.'}
        </Typography>
    );
}

const ResetPassword = () => {
    const classes = useStyles();
    const location = useLocation();
    const history = useHistory();
    const userContext = React.useContext(UserContext);
    const {open: openSuccess, message: messageSuccess, handleOpen: showSuccess, handleClose: closeSuccess} = useAlert();
    const {open: openError, message: messageError, handleOpen: showError, handleClose: closeError} = useAlert();

    const [token, setToken] = React.useState(undefined);

    const [resetPassword, { loading, error, data }] = useMutation(RESET_PASSWORD, {
        onCompleted(data) {
            if (data?.resetPassword?.success === true) {
                userContext.response.status = { code: data?.resetPassword?.code , type: 'SUCCESS' };
                history.push('/');
            }
            else {
                showError(data?.resetPassword);
                userContext.response.status = { code: data?.resetPassword?.code , type: 'ERROR' };
                history.push('/forgot-password');
            }
        },
        onError(error) {
            showError(error);
        }
    });
    const [verifyResetPasswordToken, { loading: verifyLoading, error: verifyError, data: verifyData }] = useMutation(VERIFY_RESET_PASSWORD_TOKEN, {
        onCompleted(data) {
            if (data?.verifyResetPasswordToken?.success === false) {
                showError(data?.verifyResetPasswordToken);
                userContext.response.status = { code: data?.verifyResetPasswordToken?.code , type: 'ERROR' };
                history.push('/forgot-password');
            }
        },
        onError(error) {
            showError(error);
        }
    });

    const passwordToken = async() => {
        const searchParams = new URLSearchParams(location.search);
        const auth_token = searchParams.get('t');
        setToken(auth_token);
        await verifyResetPasswordToken({ variables: { token: auth_token } });
    };

    React.useEffect(() => {
        passwordToken();
    }, []);

    return (
        <Container component="main" maxWidth="xs">
            <Success open={openSuccess} message={messageSuccess} onClose={closeSuccess} />
            <Error open={openError} message={messageError} onClose={closeError} />
            <CssBaseline />
            <Formik
                enableReinitialize={true}
                initialValues={{
                    newPassword: '',
                    confirmPassword: '',
                }}
                validate={values => {
                    const errors = {};

                    const regexPassword = /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,15}$/;

                    if (!values.newPassword) {
                        errors.newPassword = 'Required';
                    }
                    else if (!regexPassword.test(values.newPassword)) {
                        errors.newPassword = 'Password length must be minimum 8 char with one uppercase, one lower case and with one special character';
                    }

                    if (!values.confirmPassword) {
                        errors.confirmPassword = 'Required';
                    }
                    else if (!regexPassword.test(values.confirmPassword)) {
                        errors.confirmPassword = 'Password length must be minimum 8 char with one uppercase, one lower case and with one special character';
                    }
                    else if (!(values.newPassword === values.confirmPassword)){
                        errors.confirmPassword = 'Password does not match';
                    }
                    return errors;
                }}
                onSubmit={async (values, { setSubmitting, resetForm }) => {

                    await resetPassword({ variables: { token: token, newPassword: values.newPassword } });

                    userContext.response.status = undefined;
                    setSubmitting(false);
                    resetForm();
                }}
            >
            {({ submitForm, isSubmitting, values, setFieldValue, }) => (
                <Form>
                    <Box className={classes.paper}>
                        <Box display="flex" justifyContent="center">
                            <Box className={classes.logo} />
                        </Box>
                        <Grid container className={classes.form} spacing={1}>
                            <Grid item xs={12}>
                                <Field
                                    component={TextField}
                                    type="password"
                                    name="newPassword"
                                    label="New Password"
                                    margin="normal"
                                    variant="outlined"
                                    fullWidth
                                />
                                <Field
                                    component={TextField}
                                    type="password"
                                    name="confirmPassword"
                                    label="Confirm Password"
                                    margin="normal"
                                    variant="outlined"
                                    fullWidth
                                />
                            </Grid>

                        </Grid>
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary"
                            className={classes.submit}
                            onClick={submitForm}
                            disabled={isSubmitting}
                        >
                            Reset
                        </Button>
                        <Grid container justify="flex-start">
                            <Grid item>
                                <Link href="/#/forgot-password" variant="body2">
                                    Forgot Password ?
                                </Link>
                            </Grid>
                        </Grid>
                    </Box>
                    <Box mt={5}>
                        <Copyright />
                    </Box>
                </Form>
            )}
            </Formik>
        </Container>
    );
}
export default ResetPassword;