import React from 'react';
import SnackbarAlert from './SnackbarAlert';
import { useState } from 'react';

const withSeverity = (Alert, severity) => props => {
    return (
        <Alert {...props} severity={severity} />
    );
}

const Success = withSeverity(SnackbarAlert, 'success');
const Error = withSeverity(SnackbarAlert, 'error');
const Warning = withSeverity(SnackbarAlert, 'warning');


const useAlert = () => {
    const [open, setOpen] = useState(false);
    const [message, setMessage] = useState('');

    const handleOpen = (message) => {
        setOpen(false);
        setMessage(message);
        setOpen(true);
    }

    const handleClose = () => {
        setOpen(false);
    }

    return {
        open,
        message,
        handleOpen,
        handleClose
    }
};

export default useAlert;

export { Success, Error, Warning };