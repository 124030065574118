import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => {
    return {
        dialogPaper: {
            width: '35%'
        },
        actionSpacer: {
            marginRight: theme.spacing(2),
        },
        text: {
            paddingTop: theme.spacing(2)
        },
        closeButton: {
            position: 'absolute',
            right: theme.spacing(1),
            top: theme.spacing(1),
            color: theme.palette.grey[500],
        },
        disable: {
            opacity: '0.7',
            backgroundColor: '#EEE'
        },
    }
}, { index: 1 });