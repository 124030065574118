import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import CloseIcon from '@material-ui/icons/Close';
import { Formik, Form, Field } from 'formik';
import { TextField } from 'formik-material-ui';
import { FormattedMessage, defineMessages, useIntl } from 'react-intl';
import { FilePicker } from 'react-file-picker';
import parse from 'csv-parse/lib/sync';
import PublishIcon from '@material-ui/icons/Publish';

import {
    Dialog,
    IconButton,
    Typography,
    Button,
    Link,
    Grid,
    Tooltip,
    CircularProgress,
    InputAdornment
} from '@material-ui/core';

import { gql } from 'apollo-boost';
import { useMutation } from '@apollo/react-hooks';

import useStyles from './ImportPlatform.styles';

const styles = theme => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});

const DialogTitle = withStyles(styles)(props => {
    const { children, classes, onClose, ...other } = props;
    const { formatMessage } = useIntl();
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
        <Typography variant="h6">{children}</Typography>
        {onClose ? (
            <Tooltip title={formatMessage(messages.toolTipClose)}>
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            </Tooltip>
        ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles(theme => ({
    root: {
        padding: theme.spacing(3),
    },
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
    },
}))(MuiDialogActions);

const ADD_PLATFORM = gql`
    mutation addPlatforms($platforms: [PlatformInput!]!) {
        addPlatforms(platforms: $platforms) {
            code
            success
            message
        }
    }
`;

const ASSIGN_PLATFORMS_TO_USER = gql`
    mutation assignPlatformsToUser($platformKeys: [String!]!) {
        assignPlatformsToUser(platformKeys: $platformKeys) {
            code
            success
            message
        }
    }
`;

const messages = defineMessages({
    ImportPlatformDialogHeader: {id: 'ImportPlatform.dialog.header', description: 'Import Platform dialog header', defaultMessage:'Import Platform'},
    AssignPlatformDialogHeader: {id: 'AssignPlatform.dialog.header', description: 'Assign Platform dialog header', defaultMessage:'Assign Platform'},


    ImportPlatformLabelBrowseCSVFile: {id: 'ImportPlatform.label.BrowseCSVFile', description: 'Import Platform label - Browse CSV File', defaultMessage:'Browse CSV File'},
    ImportPlatformLabelClickHere: {id: 'ImportPlatform.label.ClickHere', description: 'Import Platform label - ClickHere', defaultMessage:'Click here'},
    ImportPlatformLabelDownload: {id: 'ImportPlatform.label.Download', description: 'Import Platform label - Download', defaultMessage:' to download the sample CSV file'},

    ImportPlatformButtonImport: {id: 'ImportPlatform.button.import', description: 'Import Platform button - Import', defaultMessage:'Import'},
    AssignPlatformButtonAssign: {id: 'AssignPlatform.button.Assign', description: 'Assign Platform button - Assign', defaultMessage:'Assign'},
    ImportPlatformButtonCancel: {id: 'ImportPlatform.button.cancel', description: 'Import Platform button - Cancel', defaultMessage:'Cancel'},
    
    ImportPlatformSuccess: {id: 'ImportPlatform.Success', description: 'Import Platform Success', defaultMessage: 'Platforms imported successfully.'},
    AssignPlatformSuccess: {id: 'AssignPlatform.Success', description: 'Assign Platform Success', defaultMessage: 'Platforms assigned successfully.'},

    toolTipClose: {id: 'ToolTip.Close', description: 'ToolTip For Close Icon', defaultMessage: 'Close'},
});

const ImportPlatform = props => {
    const classes = useStyles();
    const { formatMessage } = useIntl();
    const [open, setOpenDialog] = React.useState(false);
    const [csv, setCSV] = React.useState(undefined);
    const { showSuccess, showError, showWarning } = props;
    const authUser = JSON.parse(sessionStorage.getItem('amz-user'));
    const isAdmin = authUser?.roles ? (authUser.roles).indexOf('admin') > -1 : false;

    const [addPlatforms, { loading, error, data }] = useMutation(ADD_PLATFORM, {
        onCompleted(data) {
            if (data?.addPlatforms?.success === true) {
                messages.ImportPlatformSuccess['defaultMessage'] = data.addPlatforms.message;
                if (data.addPlatforms.code == "I_ADD_PLATFORMS_SUCCESS_WITH_INVALID_PLATFORMS") {
                    showWarning(messages.ImportPlatformSuccess);
                }
                else {
                    showSuccess(messages.ImportPlatformSuccess);
                }
            }
            else {
                showError(data?.addPlatforms);
            }
            setOpenDialog(false);
        },
        onError(error) {
            showError(error);
            setOpenDialog(false);
        }
    });

    const [assignPlatformsToUser, { loading: assignLoading, error: assignError, data: assignData }] = useMutation(ASSIGN_PLATFORMS_TO_USER, {
        onCompleted(data) {
            if (data && data.assignPlatformsToUser.success === true) {
                messages.AssignPlatformSuccess['defaultMessage'] = data.assignPlatformsToUser.message;
                if (data.assignPlatformsToUser.code == "I_ASSIGN_PLATFORMS_TO_USER_WITH_INVALID_PLATFORMS") {
                    showWarning(messages.AssignPlatformSuccess);
                }
                else {
                    showSuccess(messages.AssignPlatformSuccess);
                }
            }
            else {
                if (data.assignPlatformsToUser.code == "E_ASSIGN_PLATFORMS_TO_USER_ERROR") {
                    showWarning(data?.assignPlatformsToUser);
                }
                else {
                    showError(data?.assignPlatformsToUser);
                }
            }
            setOpenDialog(false);
        },
        onError(error) {
            showError(error);
            setOpenDialog(false);
        }
    });

    const handleClickOpen = event => {
        event.stopPropagation();
        setOpenDialog(true);
    };

    const handleClose = () => {
        setOpenDialog(false);
    };

    const readContent = (FileObject, setFieldValue) => {
        console.log(FileObject, 'FileObject');

        let reader = new FileReader();
        reader.readAsText(FileObject);
        reader.onload = function() {
            console.log(reader.result, "File Content");
            const records = parse(reader.result, {
                columns: true,
                skip_empty_lines: true,
                skip_lines_with_empty_values: true,
            });
            console.log(records, "CSV Object");
            setCSV(records);
            setFieldValue("csvName", FileObject.name);
        };
    };

    return (
        <div onClick={e => e.stopPropagation()}>
        <Button variant="outlined" color="primary" onClick={handleClickOpen}>
            {
                isAdmin ?
                    <FormattedMessage {...messages.ImportPlatformDialogHeader} />
                :
                    <FormattedMessage {...messages.AssignPlatformDialogHeader} />
            }
        </Button>
        <Formik
            initialValues={{
                csvName: "",
            }}
            validate={values => {
                const errors = {};

                if (!values.csvName) {
                    errors.csvName = 'Required';
                }
                else if (values.csvName && !csv?.length) {
                    errors.csvName = 'There is no product key(s) in the document';
                }
                else if (csv[0].platformKey === undefined) {
                    errors.csvName = 'Change the column header as "platformKey" in the document';
                }
                else if (isAdmin) {
                    csv.map(e => {
                        if (e.platformType === undefined) {
                            errors.csvName = 'Change the column header as "platformType" in the document';
                            return;
                        }
                        else if (e.cpuSN === undefined) {
                            errors.csvName = 'Change the column header as "cpuSN" in the document';
                            return;
                        }
                        else if (e.mac === undefined) {
                            errors.csvName = 'Change the column header as "mac" in the document';
                            return;
                        }
                        else if (e.platformKey?.trim() === '' || e.platformType?.trim() === '' || e.cpuSN?.trim() === '' || e.mac?.trim() === '') {
                            errors.csvName = 'The document contains invalid values or empty columns';
                            return;
                        }
                    });
                }

                return errors;
            }}
            onSubmit={async (values, { setSubmitting, resetForm }) => {

                if (isAdmin && csv.length) {
                    await addPlatforms({ variables: { platforms: csv } });
                }
                else if (!isAdmin && csv.length) {
                    await assignPlatformsToUser({ variables: { platformKeys: csv.map(obj => obj.platformKey.trim()) } });
                }

                setSubmitting(false);
                resetForm();
            }}
        >
        {({ submitForm, isSubmitting, values, setFieldValue }) => (
            <Form>
                <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open} classes={{ paper: classes.dialogPaper }} onKeyPress= {(e) => { if(!isSubmitting && e.which === 13) {submitForm()}}}>
                    <DialogTitle id="customized-dialog-title" onClose={handleClose}>
                    {
                        isAdmin ?
                            <FormattedMessage {...messages.ImportPlatformDialogHeader} />
                        :
                            <FormattedMessage {...messages.AssignPlatformDialogHeader} />
                    }
                    </DialogTitle>
                    <DialogContent dividers>
                        <Grid container spacing={12}>
                            <Grid item xs={12}>
                                <FilePicker
                                    extensions={['csv']}
                                    onChange={FileObject => ( readContent(FileObject, setFieldValue) )}
                                    onError={errMsg => ( console.log(errMsg, "errMsg") )}
                                    id="select-csv"
                                >
                                    <Field
                                        component={TextField}
                                        type="text"
                                        name="csvName"
                                        variant="outlined"
                                        margin="dense"
                                        label={formatMessage(messages.ImportPlatformLabelBrowseCSVFile)}
                                        InputProps={{
                                            readOnly: true,
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                        <IconButton variant="outlined" size="small">
                                                            <PublishIcon />
                                                        </IconButton>
                                                </InputAdornment>
                                            )
                                        }}
                                        fullWidth
                                    />
                                </FilePicker>
                            </Grid>
                            <Grid item xs={12} className={classes.spacing}>
                                <Link href={isAdmin ? '/import_platforms.csv' : '/assign_platforms.csv'} target="_blank">
                                    <FormattedMessage {...messages.ImportPlatformLabelClickHere} />
                                </Link>
                                <FormattedMessage {...messages.ImportPlatformLabelDownload} />
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        {isSubmitting && <CircularProgress size={24} className={classes.actionSpacer} />}
                        <Button variant="text" onClick={handleClose}>
                            <FormattedMessage {...messages.ImportPlatformButtonCancel} />
                        </Button>
                        <Button varient="text" onClick={submitForm} disabled={isSubmitting} color="primary">
                        {
                            isAdmin ?
                                <FormattedMessage {...messages.ImportPlatformButtonImport} />
                            :
                                <FormattedMessage {...messages.AssignPlatformButtonAssign} />
                        }
                        </Button>
                    </DialogActions>
                </Dialog>
            </Form>
        )}
        </Formik>
        </div>
    );
}

export default ImportPlatform;