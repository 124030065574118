import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => {
    return {
        gridContainer: {
            marginTop: theme.spacing(2)
        },
        container: {
            maxHeight: 440,
        },
        bgWhite: {
            backgroundColor: '#fff',
        },
        table: {
            tableLayout: 'fixed',
        },
        wordWrap: {
            wordBreak: 'break-word',
        },
    }
}, { index: 1 });