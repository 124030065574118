import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => {
    return {
        marginTop: {
            marginTop: theme.spacing(1)
        },
        pagination: {
            paddingRight: theme.spacing(1.5)
        },
    }
}, { index: 1 });