import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => {
    return {
        actionSpacer: {
            marginRight: theme.spacing(2),
        },
        spacing: {
            marginTop: theme.spacing(2),
        },
        dialogPaper: {
            width: '30%'
        },

        linearProgressTableCell: {
            padding: '0px !important'
        },
        linearProgressRoot: {
            height: '2px'
        },
        linearProgressColorPrimary: {
            backgroundColor: theme.overrides.MuiTableCell.head.backgroundColor
        },
        linearProgressBarColorPrimary: {
            backgroundColor: theme.palette.common.white
        },
        linearProgress: {
            marginTop: '-2px'
        },
        pagination: {
            paddingRight: theme.spacing(1.5)
        },
        tableSorticonColor: {
            color: `${theme.palette.common.white} !important`,
            "&:hover": {
                color:`${theme.palette.text.primary} !important`,
            },
        },
        tableSortlabelColor: {
            color: `${theme.palette.common.white} !important`,
            "&:hover": {
                color:`${theme.palette.text.primary} !important`,
            },
        }
    }
}, { index: 1 });