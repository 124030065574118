import React from 'react';
import clsx from 'clsx';
import { FormattedMessage, defineMessages, useIntl } from 'react-intl';

import {
    Box,
    CircularProgress,
    Grid,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Button,
    IconButton,
    AppBar,
    Toolbar,
    Typography,
    Tooltip,
    Container,
    InputAdornment
} from '@material-ui/core';

import useStyles from './styles';
import UpdateUser from './UpdateUser';
import useAlert, { Success, Error } from 'components/Alert/CustomAlert';

import { gql } from 'apollo-boost';
import { useQuery, useMutation, useLazyQuery} from '@apollo/react-hooks';
import { UserContext } from 'containers/App/UserContext';
import { global } from 'config';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import SearchIcon from '@material-ui/icons/Search';
import TextField from '@material-ui/core/TextField';
import Pagination from 'components/Pagination/CustomPagination';

const LIST_USERS = gql`
query getUsers($page: Page, $sort: Sort, $searchFilter: SearchFilter) {
    getUsers(page: $page, sort: $sort, searchFilter: $searchFilter) {
        offset
        limit
        totalCount
        result{
              email
            firstName
           lastName
           roles
            }
        }
    }
`;

const messages = defineMessages({
    UsersLabelFirstName: {id: 'Users.label.FirstName', description: 'Users label - FirstName', defaultMessage:'First Name'},
    UsersLabelLastName: {id: 'Users.label.LastName', description: 'Users label -LastName', defaultMessage:'Last Name'},
    UsersLabelEmail: {id: 'Users.label.Email', description: 'Users label - Email', defaultMessage:'Email'},
    UsersLabelRoles: {id: 'Users.label.Roles', description: 'Users label - Role', defaultMessage:'Roles'},
    NoRecordsLabel: { id: 'Platform.label.NoRecords', description: 'Platform label - NoRecords', defaultMessage: 'No Records Found' },
});

const Users = () => {
    const userContext = React.useContext(UserContext);
    const classes = useStyles();
    const { formatMessage } = useIntl();
    const [activeRow, setActiveRow] = React.useState(null);
    const {open: openSuccess, message: messageSuccess, handleOpen: showSuccess, handleClose: closeSuccess} = useAlert();
    const {open: openError, message: messageError, handleOpen: showError, handleClose: closeError} = useAlert();
    const authUser = JSON.parse(sessionStorage.getItem('amz-user'));
    const isSuperAdmin = authUser?.roles ? (authUser.roles).indexOf('super-admin') > -1 : false;

    const [users, setUsers] = React.useState(0);
    const [totalPageCount, setTotalPageCount] = React.useState(0);
    const [offset, setOffset] = React.useState(0);
    const [sortModel, setSortModel] = React.useState({ column:'', type: 'asc' });
    const [searchFilter, setSearchFilter] = React.useState('');
    const [page, setPage] = React.useState(1);
    const [selectedRowCount, setSelectedRowCount] = React.useState(10);

    //const selectedRowCount = userContext?.rowCount ? userContext?.rowCount : global?.page?.defaultRowCount;
    const [getUsers, { loading, error, data, refetch } ]= useLazyQuery(LIST_USERS, {
        notifyOnNetworkStatusChange: true,
        fetchPolicy: 'no-cache',
        onCompleted(data) {
            if(offset === 0) {
                setPage(1);
            }
            setUsers(data?.getUsers);
            let totalPageCount = Math.floor(data?.getUsers?.totalCount/selectedRowCount);
            totalPageCount = data?.getUsers?.totalCount%selectedRowCount > 0 ? totalPageCount +1 : totalPageCount;
            setTotalPageCount(totalPageCount);
         },
        onError(error) { console.log('error-', error) }
    });

    React.useEffect(() => {
        // Calling the 'refetch' function while loading the Success alert component
        if (openSuccess) {
            refetch();
        }
    }, [openSuccess]);

    React.useEffect(() => {
        getUsers({ variables: { page: {offset: offset, limit: selectedRowCount }, sort: {column: sortModel.column, type: sortModel.type }, searchFiltery: {text: searchFilter} } });
}, []);

    const onPageChange = (event, value) => {
        const offset = (value - 1) * selectedRowCount;
        setOffset(offset);
        setPage(value);

        getUsers({variables: { page: {offset: offset, limit: selectedRowCount }, sort: {column: sortModel.column, type: sortModel.type }, searchFilter: {text: searchFilter} }});
    };

    const SortableTableCell = ({ name, classes, label, sortModel, onSort }) => {
        return (
            <TableCell>
                <TableSortLabel
                    className={classes.tableSortlabelColor}
                    classes={{icon: classes.tableSorticonColor}}
                    active={sortModel.column === name}
                    direction={(sortModel.column === name) ? sortModel.type : 'desc'}
                    onClick={() => onSort(name)}
                >
                    {label}
                </TableSortLabel>
            </TableCell>
        );
    }

    const onSort = (column) =>  {
        setOffset(0);
        console.log("onsort called");
        getUsers({variables: { page: {offset: 0, limit: selectedRowCount }, sort: {column: column, type:  sortModel.type === 'asc'? 'desc' : 'asc'}, searchFilter: {text: searchFilter} }});
        setSortModel({ column: column, type: sortModel.type === 'asc'? 'desc' : 'asc' });
    };

    const onFilterChange = (event) => {
        setOffset(0)
        setSearchFilter(event.target.value);

        getUsers({variables: {page: {offset: 0, limit: selectedRowCount }, searchFilter: {text: event.target.value} } } );
    }

    const onRowCountSelected = (event) => {
        console.log ('Selected ', event.target.value);
        setOffset(0);
        setPage(1);
        setSelectedRowCount(parseInt(event.target.value));
        getUsers({ variables: { page: {offset: 0, limit: event.target.value }, sort: {column: sortModel.column, type: sortModel.type }, searchFiltery: {text: searchFilter} } });
    }

    return (
        <Box>
            <Success open={openSuccess} message={messageSuccess} onClose={closeSuccess} />
            <Error open={openError} message={messageError} onClose={closeError} />
            <Grid item justify="flex-start">
                    <Box display="flex">
                        <TextField InputProps={{startAdornment: ( <InputAdornment position="start"> <SearchIcon /> </InputAdornment> )}} margin="dense" size="small" variant="outlined" onChange={onFilterChange} />
                    </Box>
                </Grid>
            <Grid item container direction="column" justify="flex-start" alignItems="center" spacing={0} className={classes.gridContainer}>
                <TableContainer component={Paper}>
                        <Table aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                <SortableTableCell name={'firstName'} classes={classes} label={formatMessage(messages.UsersLabelFirstName)} sortModel={sortModel} onSort={onSort} />
                                <SortableTableCell name={'lastName'} classes={classes} label={formatMessage(messages.UsersLabelLastName)} sortModel={sortModel} onSort={onSort} />
                                <SortableTableCell name={'email'} classes={classes} label={formatMessage(messages.UsersLabelEmail)} sortModel={sortModel} onSort={onSort} />
                                <SortableTableCell name={'roles'} classes={classes} label={formatMessage(messages.UsersLabelRoles)} sortModel={sortModel} onSort={onSort} />
                                    {/* { <TableCell><FormattedMessage {...messages.UsersLabelFirstName} /></TableCell>
                                    <TableCell><FormattedMessage {...messages.UsersLabelLastName} /></TableCell>
                                    <TableCell><FormattedMessage {...messages.UsersLabelEmail} /></TableCell>
                                    <TableCell><FormattedMessage {...messages.UsersLabelRoles} /></TableCell> */}
                                    <TableCell></TableCell> 
                                </TableRow>
                            </TableHead>
                            <TableBody>
                            {
                                data === undefined ?
                                (
                                    <TableRow>
                                        <TableCell colSpan={7} display="flex" align="center">
                                            <Box mt={1} mb={1}>
                                                <CircularProgress size={24} />
                                            </Box>
                                        </TableCell>
                                    </TableRow>
                                ) :
                                (
                                    data?.getUsers?.result?.length ?
                                    (
                                        data?.getUsers?.result?.map((user, i) => (
                                            <TableRow key={i} hover className={clsx({
                                                [classes.showActionsOnHover]:true,
                                                [classes.selected]: activeRow === i ,
                                            })}>
                                                <TableCell>{user.firstName}</TableCell>
                                                <TableCell>{user.lastName}</TableCell>
                                                <TableCell>{user.email}</TableCell>
                                                <TableCell>{user.roles.join(", ").replace(/\b\w/g, l => l.toUpperCase())}</TableCell>
                                                <TableCell size="small" align="right" padding="none">
                                                    {
                                                        isSuperAdmin &&
                                                            <UpdateUser showSuccess={showSuccess} showError={showError} user={user} setActiveRow={setActiveRow} activeRow={i} />
                                                    }
                                                </TableCell>
                                            </TableRow>
                                        ))
                                    ) :
                                    (
                                        <TableRow>
                                            <TableCell colSpan={7} display="flex">
                                                <Typography variant="body1" display="block">
                                                    <Box fontWeight="fontWeightBold" m={1} display="flex" justifyContent="center">
                                                        <FormattedMessage {...messages.NoRecordsLabel} />
                                                    </Box>
                                                </Typography>
                                            </TableCell>
                                        </TableRow>
                                    )
                                )
                            }
                            </TableBody>
                        </Table>
                </TableContainer>
            </Grid>
            {   users?.result &&
                    <Pagination
                        className={classes.pagination}
                        data={users?.result}
                        page={page}
                        selectedRowCount={selectedRowCount}
                        totalPageCount={totalPageCount}
                        onPageChange={onPageChange}
                        onRowCountSelected={onRowCountSelected}
                        hideRowSelection={false}
                    />
            }
        </Box>
    );
};

export default Users;

