import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import 'typeface-roboto';
import App from 'containers/App';
import * as serviceWorker from './serviceWorker';

import { IntlProvider } from 'react-intl';

import locales from './translations/locales';

const DEFAULT_LOCALE = 'en-US';

const locale = (navigator.languages && navigator.languages[0]) || navigator.language || navigator.userLanguage;

const localeWithoutRegionCode = locale.toLowerCase().split(/[_-]+/)[0];
const messages = locales[localeWithoutRegionCode] || locales[locale]; // undefined if no translation exists - will fallback to default messages in en


ReactDOM.render(
    <IntlProvider
        locale={locale}
        formats={undefined} // TO DO
        messages={messages}
        defaultLocale={DEFAULT_LOCALE}
        defaultFormats={undefined} // TO DO
    >
        <App />
    </IntlProvider>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
