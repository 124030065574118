import { defineMessages } from 'react-intl';

const messages = defineMessages({
    'Network error': { id: 'Error.NetworkError', description: 'Network Error on query/Mutation', defaultMessage: 'Network Error, Check your network connectivity or query.' },
    'GraphQL error': { id: 'Error.GraphQLError', description: 'GraphQl Error reported by Server', defaultMessage: 'GraphQL Error, Check your query or your input.'},
    'Unknown error': { id: 'Error.UnknownError', description: 'UnKnown Error', defaultMessage: 'Unknow Error.'},

    'E_SIGNIN_FAILED': { id: 'Error.SignInFailed', description: 'Error message to display when there is sign-in failed', defaultMessage: 'Sign-In Error'},
    'E_SIGNUP_FAILED': { id: 'Error.SignUpFailed', description: 'Error message to display when there is sign-up failed', defaultMessage: 'Sign-Up Error'},
    'E_RESET_USER_PASSWORD_FAILED': { id: 'Error.ResetUserPasswordFailed', description: 'Error message to display when there is reset password failed', defaultMessage: 'Reset Password Error'},
    /**
     * Common Errors: Repeated in multiple mutations
     */
    'E_INVALID_ARGUMENT': { id: 'Error.InvalidArgument', description: 'Error message to display when there is an invalid argument', defaultMessage: 'Invalid Argument' },
    'E_INVALID_OPERTATION': { id: 'Error.InvalidOperation', description: 'Error message to display when there is an invalid operation', defaultMessage: 'Invalid Operation' },
    'E_GET_CERTIFICATE_FAILED': { id: 'Error.Cert.GetFailed', description: 'Error message to display while geting the certficate', defaultMessage: 'Getting the certificate from Server failed' },
    'E_GET_PIM_CONFIG_FAILED': { id: 'Error.Get.PIMConfig', description: 'Error message to display when getting PIM config failed', defaultMessage: 'Failed to get PIM config' },
    'E_LIST_IPACCESS_ENTRIES_FAILED': { id: 'Error.List.IPAccessEntries', description: 'Error message to display when listing IP access data failed', defaultMessage: 'Failed to list IP access data' },
    'E_FC_CHAIN_LIST_FAILED': { id: 'Error.List.FCChain', description: 'Error message to display when listing the chain failed', defaultMessage: 'Failed to list the chain' },
    'E_QOS_GETINFO_IFC_FAILED': { id: 'Error.Get.QoSInfoOfInterface', description: 'Error message to display when getting QoS interface failed', defaultMessage: 'Failed to get QoS interface' },
    'E_GET_NGGATEWAY_INFO_FAILED': { id: 'Error.Get.NBGateway', description: 'Error message to display when getting the gateway information failed', defaultMessage: 'Failed to get gateway information' },

    'E_ADD_PLATFORMS_FAILED': { id: 'Error.Add.platforms', description: 'Error message to display when there add platform is failed', defaultMessage: 'Failed to add Platfrom'},

    "E_ASSIGN_PLATFORMS_TO_USER_FAILED": { id: 'Error.Assign.platforms', description: 'Error message to display when there Assign platform is failed', defaultMessage: 'Failed to assign Platfrom' },

    "E_REMOVE_PLATFORMS_FAILED": { id: 'Error.Remove.platforms', description: 'Error message to display when there Remove platform is failed', defaultMessage: 'Failed to remove Platfrom' },

    "E_REMOVE_PLATFORMS_FROM_USER_FAILED": { id: 'Error.Unassign.platforms', description: 'Error message to display when there Unassign platform is failed', defaultMessage: 'Failed to remove Platfrom' },

    "E_CREATE_API_KEY_FAILED": { id: 'Error.create.apikey', description: 'Error message to display when there create apikey is failed', defaultMessage: 'Failed to create API key' },

    "I_ADD_MANAGEMENT_PORTAL_FAILED": { id: 'Error.Add.managementPortal', description: 'Error message to display when there add managementPortal is failed', defaultMessage: 'Failed to add management portal'},

    "E_GET_MANGEMENT_PORTAL_INFO_FAILED": { id: 'Error.get.managementPortal', description: 'Error message to display when there get management portal is failed', defaultMessage: 'Failed to get management portal' },

    "EDIT_MANAGEMENT_PORTAL_FAILED": { id: 'Error.update.managementPortal', description: 'Error message to display when there update managementPortal is failed', defaultMessage: 'Failed to udpate management portal'},

    "E_REMOVE_MANAGEMENT_PORTAL_FAILED": { id: 'Error.Remove.managementPortal', description: 'Error message to display when there Remove managementPortal is failed', defaultMessage: 'Failed to remove management portal' },

    "UPDATE_USER_FAILED": { id: 'Error.update.users', description: 'Error message to display when there update users is failed', defaultMessage: 'Failed to udpate user'},

    "E_SEND_RESET_PASSWORD_TOKEN_FAILED": { id: 'Error.user.resettoken', description: 'Error message to display when there is failed to get reset token', defaultMessage: 'Failed to reset password'},

    "E_VERIFY_RESET_PASSWORD_TOKEN_FAILED" : { id: 'Error.user.token', description: 'Error message to display when there is failed to get verify user', defaultMessage: 'Failed to verify the token'},

    "E_SEND_RESET_PASSWORD_TOKEN_FAILED": { id: 'Error.get.resetPassword', description: 'Error message to display when there is failed to reset user password', defaultMessage: 'Failed to reset password'},

    "I_RESEND_EMAIL_TOKEN_FAILED": { id: 'Error.get.token.send', description: 'Error message to display when there is failed to sent user token', defaultMessage: 'Failed to sent token'},


    /**
     * RemoveIGMPInterfaceConfigMutationResponse
     */
    //'I_REMOVE_IGMPINTERFACE_CONFIG_SUCCESS': { id: '', description: '', defaultMessage: 'Failed to' }, //TODO
    'E_REMOVE_IGMPINTERFACE_CONFIG_FAILED': { id: 'Error.Remove.IGMPInterfaceConfig', description: 'Error message to display when removing IGMP interface config', defaultMessage: 'Failed to remove IGMP interface config' },
    //E_GET_PIM_CONFIG_FAILED

    /**
     * AddIGMPInterfaceConfigMutationResponse
     */
    //'I_ADD_IGMPINTERFACE_CONFIG_SUCCESS': { id: '', description: '', defaultMessage: 'Failed to' }, //TODO
    'E_ADD_IGMPINTERFACE_CONFIG_FAILED': { id: 'Error.Add.IGMPInterfaceConfig', description: 'Error message to display when adding IGMP interface config failed', defaultMessage: 'Failed to add IGMP interface config' },
    //E_GET_PIM_CONFIG_FAILED

    /**
     * RemovePIMInterfaceConfigMutationResponse
     */
    //'I_REMOVE_PIMINTERFACE_CONFIG_SUCCESS': { id: '', description: '', defaultMessage: 'Failed to' }, //TODO
    'E_REMOVE_PIMINTERFACE_CONFIG_FAILED': { id: 'Error.Remove.PIMInterfaceConfig', description: 'Error message to display when removing PIM interface config failed', defaultMessage: 'Failed to remove PIM interface config' },
    //E_GET_PIM_CONFIG_FAILED

    /**
     * AddPIMInterfaceConfigMutationResponse
     */
    //'I_ADD_PIMINTERFACE_CONFIG_SUCCESS': { id: '', description: '', defaultMessage: 'Failed to' }, //TODO
    'E_ADD_PIMINTERFACE_CONFIG_FAILED': { id: 'Error.Add.PIMInterfaceConfig', description: 'Error message to display when adding PIM interface config failed', defaultMessage: 'Failed to add PIM interface config' },
    //E_GET_PIM_CONFIG_FAILED

    /**
     * RemoveRPConfigMutationResponse
     */
    //'I_REMOVE_RP_CONFIG_SUCCESS': { id: '', description: '', defaultMessage: 'Failed to' }, //TODO
    'E_REMOVE_RP_CONFIG_FAILED': { id: 'Error.Remove.RPConfig', description: 'Error message to display when removing RP config failed', defaultMessage: 'Failed to remove RP config' },
    //E_GET_PIM_CONFIG_FAILED

    /**
     * AddRPConfigMutationResponse
     */
    //'I_ADD_RP_CONFIG_SUCCESS': { id: '', description: '', defaultMessage: 'Failed to' }, //TODO
    'E_ADD_RP_CONFIG_FAILED': { id: 'Error.Add.RPConfig', description: 'Error message to display when adding RP config failed', defaultMessage: 'Failed to add RP config' },
    //E_GET_PIM_CONFIG_FAILED

    /**
     * RemovePIMConfigMutationResponse
     */
    //'I_REMOVE_PIM_CONFIG_SUCCESS': { id: '', description: '', defaultMessage: 'Failed to' }, //TODO
    'E_REMOVE_PIM_CONFIG_FAILED': { id: 'Error.Remove.PIMConfig', description: 'Error message to display when removing PIM config', defaultMessage: 'Failed to remove PIM config' },
    //E_GET_PIM_CONFIG_FAILED

    /**
     * AddPIMConfigMutationResponse
     */
    //'I_ADD_PIM_CONFIG_SUCCESS': { id: '', description: '', defaultMessage: 'Failed to' }, //TODO
    'E_ADD_PIM_CONFIG_FAILED': { id: 'Error.Add.PIMConfig', description: 'Error message to display when adding PIM config failed', defaultMessage: 'Failed to add PIM config' },
    //E_GET_PIM_CONFIG_FAILED

    /**
     * RemoveAccessEntryMutationResponse
     */
    //'I_REMOVE_IPACCESS_ENTRY_SUCCESS': { id: '', description: '', defaultMessage: 'Failed to' }, //TODO
    'E_REMOVE_IPACCESS_ENTRY_FAILED': { id: 'Error.Remove.IPAccessEntry', description: 'Error message to display when removing IP access failed', defaultMessage: 'Failed to remove IP access' },
    //E_LIST_IPACCESS_ENTRIES_FAILED

    /**
     * UpdateAccessEntryMutationResponse
     */
    //'I_UPDATE_IPACCESS_ENTRY_SUCCESS': { id: '', description: '', defaultMessage: 'Failed to' }, //TODO
    'E_UPDATE_IPACCESS_ENTRY_FAILED': { id: 'Error.Update.IPAccessEntry', description: 'Error message to display when updating IP access failed', defaultMessage: 'Failed to update IP access' },
    //E_LIST_IPACCESS_ENTRIES_FAILED

    /**
     * AddAccessEntryMutationResponse
     */
    //'I_ADD_IPACCESS_ENTRY_SUCCESS': { id: '', description: '', defaultMessage: 'Failed to' }, //TODO
    'E_ADD_IPACCESS_ENTRY_FAILED': { id: 'Error.Add.IPAccessEntry', description: 'Error message to display when adding IP access failed', defaultMessage: 'Failed to add IP access' },
    //E_LIST_IPACCESS_ENTRIES_FAILED

    /**
     * RemovePrefixEntryMutationResponse
     */
    //'I_REMOVE_IPPREFIX_ENTRY_SUCCESS': { id: '', description: '', defaultMessage: 'Failed to' }, //TODO
    'E_REMOVE_IPPREFIX_ENTRY_FAILED': { id: 'Error.Remove.IPPrefixEntry', description: 'Error message to display when removing IPPREFIX failed', defaultMessage: 'Failed to remove IPPREFIX' },
    //E_LIST_IPACCESS_ENTRIES_FAILED

    /**
     * UpdatePrefixEntryMutationResponse
     */
    //'I_UPDATE_IPPREFIX_ENTRY_SUCCESS': { id: '', description: '', defaultMessage: 'Failed to' }, //TODO
    'E_UPDATE_IPPREFIX_ENTRY_FAILED': { id: 'Error.Update.IPPrefixEntry', description: 'Error message to display when updating IPPREFIX failed', defaultMessage: 'Failed to update IPPREFIX' },
    //E_LIST_IPACCESS_ENTRIES_FAILED

    /**
     * AddPrefixEntryMutationResponse
     */
    //'I_ADD_IPPREFIX_ENTRY_SUCCESS': { id: '', description: '', defaultMessage: 'Failed to' }, //TODO
    'E_ADD_IPPREFIX_ENTRY_FAILED': { id: 'Error.Add.IPPrefixEntry', description: 'Error message to display when adding IPPREFIX data failed', defaultMessage: 'Failed to add IPPREFIX' },
    'E_LIST_IPPREFIX_ENTRIES_FAILED': { id: 'Error.List.IPPrefixEntry', description: 'Error message to display when listing IPPREFIX entries failed', defaultMessage: 'Failed to list IPPREFIX data' },

    /**
     * EditIPFixMutationResponse
     */
    //'I_EDIT_IPFIX_CPE_SUCCESS': { id: '', description: '', defaultMessage: 'Failed to' }, //TODO
    'E_EDIT_IPFIX_CPE_FAILED': { id: 'Error.Edit.IPFixCPE', description: 'Error message to display when editing IPFIX failed', defaultMessage: 'Failed to edit IPFIX' },
    //E_INVALID_ARGUMENT

    /**
     * DeleteOSPFConfMutationResponse
     */

    /**
     * UpdateOSPFConfMutationResponse
     */

    /**
     * AddOSPFConfMutationResponse
     */

    /**
    * activateQoSChangesForCPEMutationResponse
    */
    //'I_ACTIVATEQOSCHANGES_FOR_CPE_SUCCESS': { id: '', description: '', defaultMessage: 'Failed to' }, //TODO
    'E_ACTIVATEQOSCHANGES_FOR_CPE_FAILED': { id: 'Error.Activate.QoSChangesForCPE', description: 'Error message to display when activating changes for CPE', defaultMessage: 'Failed to activate changes' },
    //E_INVALID_ARGUMENT

    /**
     * EnableDisableQoSOfInInterfaceMutationResponse
     */
    //'I_ENABLE_DISABLE_QOS_IN_INTERFACE_SUCCESS': { id: '', description: '', defaultMessage: 'Failed to' }, //TODO
    'E_ENABLE_DISABLE_QOS_IN_INTERFACE_FAILED': { id: 'Error.EnableDisable.QoSInInterface', description: 'Error message to display when enable/disable QoS in interface failed', defaultMessage: 'Failed to enable/disable QoS in interface' },
    //E_INVALID_ARGUMENT

    /**
     * EditQoSBandwidthOfInInterfaceMutationResponse
     */
    //'I_EDIT_QOS_BANDWIDHT_OF_INTERFACE_SUCCESS': { id: '', description: '', defaultMessage: 'Failed to' }, //TODO
    'I_EDIT_QOS_BANDWIDHT_OF_INTERFACE_FAILED': { id: 'Error.Edit.QoSBandWidthOfInterface', description: 'Error message to display when editing QoS bandwidth of interface failed', defaultMessage: 'Failed to edit QoS bandwidth of interface' }, //CHECKME
    //E_INVALID_ARGUMENT

    /**
     * EnableDisableClassManagerInInterfaceMutationResponse
     */
    //'I_ENABLE_DISABLE_CM_IN_INTERFACE_SUCCESS': { id: '', description: '', defaultMessage: 'Failed to' }, //TODO
    'E_ENABLE_DISABLE_CM_IN_INTERFACE_FAILED': { id: 'Error.Disable.CMInInterface', description: 'Error message to display when enable/disable class manager failed in interface', defaultMessage: 'Failed to enable/disable class manager in interface' },
    //E_INVALID_ARGUMENT

    /**
     * DeleteClassManagerFromInterfaceMutationResponse
     */
    //'I_DELETE_CM_FROM_INTERFACE_SUCCESS': { id: '', description: '', defaultMessage: 'Failed to' }, //TODO
    'E_DELETE_CM_FROM_INTERFACE_FAILED': { id: 'Error.Delete.CMFromInterface', description: 'Error message to display when deleting class manager from interface failed', defaultMessage: 'Failed to delete class manager from interface' },
    //E_INVALID_ARGUMENT

    /**
     * EditLocalClassManagerOfInterfaceMutationResponse
     */
    //'I_EDIT_LCM_OF_INTERFACE_SUCCESS': { id: '', description: '', defaultMessage: 'Failed to' }, //TODO
    'E_EDIT_LCM_OF_INTERFACE_FAILED': { id: 'Error.Edit.LCMOfInterface', description: 'Error message to display when editing lcm of interface failed', defaultMessage: 'Failed to edit local class manager of interface' },
    //E_QOS_GETINFO_IFC_FAILED
    //E_INVALID_ARGUMENT

    /**
     * AddClassManagerToInterfaceMutationResponse
     */
    //'I_ADD_CM_TO_INTERFACE_SUCCESS': { id: '', description: '', defaultMessage: 'Failed to' }, //TODO
    'E_ADD_CM_TO_INTERFACE_FAILED': { id: 'Error.Add.CMToInterface', description: 'Error message to display when adding class manager to interface failed', defaultMessage: 'Failed to add class manager to interface' },
    //E_QOS_GETINFO_IFC_FAILED
    //E_INVALID_ARGUMENT

    /**
     * EnableDisableClassManagerMutationResponse
     */
    //'I_ENABLE_DISABLE_CM_SUCCESS': { id: '', description: '', defaultMessage: 'Failed to' }, //TODO
    'E_ENABLE_DISABLE_CM_FAILED': { id: 'Error.EnableDisable.CM', description: 'Error message to display when enable/disable class manager failed', defaultMessage: 'Failed to enable/disable class manager' },

    /**
     * RemoveClassManagerMutationResponse
     */
    //'I_REMOVE_CM_SUCCESS': { id: '', description: '', defaultMessage: 'Failed to' }, //TODO
    'E_REMOVE_CM_FAILED': { id: 'Error.Remove.CM', description: 'Error message to display when removing the class manager failed', defaultMessage: 'Failed to remove class manager' },
    'E_REMOVE_CM_FAILED_INVALID_ARG': { id: 'Error.Remove.CM.InvalidArg', description: 'Error message to display when removing class manager failed due to invalid arguments', defaultMessage: 'Failed to remove class manager due to invalid argument(s)' },

    /**
     * EditClassManagerMutationResponse
     */
    //'I_EDIT_CM_SUCCESS': { id: '', description: '', defaultMessage: 'Failed to' }, //TODO
    'E_EDIT_CM_FAILED': { id: 'Error.Edit.CM', description: 'Error message to display when editing the class manager failed', defaultMessage: 'Failed to edit class manager' },
    'E_EDIT_CM_FAILED_INVALID_ARG': { id: 'Error.Edit.CM.InvalidArg', description: 'Error message to display when editing class manager failed due to invalid arguments', defaultMessage: 'Failed to edit class manager due to invalid argument(s)' },
    //E_INVALID_ARGUMENT

    /**
     * CreateClassManagerMutationResponse
     */
    //'I_CREATE_CM_SUCCESS': { id: '', description: '', defaultMessage: 'Failed to' }, //TODO
    'E_CREATE_CM_FAILED': { id: 'Error.Create.CM', description: 'Error message to display when creating class manager failed', defaultMessage: 'Failed to create class manager' },
    'E_QOS_GETINFO_CM_FAILED': { id: 'Error.Get.CMInfo', description: 'Error message to display when creating class manager failed', defaultMessage: 'Failed to create class manager' }, //FIXME
    'E_CREATE_CM_FAILED_INVALID_ARG': { id: 'Error.Create.CM.InvalidArg', description: 'Error message to display when creating class manager failed due to invalid arguments', defaultMessage: 'Failed to create class manager due to invalid argument(s)' },
    //E_INVALID_ARGUMENT

    /**
     * DeleteIPsecMutationResponse
     */

    /**
     * SetStateMutationResponse
     */

    /**
     * CreateIPsecMutationResponse
     */

    /**
     * EditStaticIPInDhcpSubnetMutationResponse!
     */
    'E_EDIT_STATIC_IP_IN_DHCP_SUBNET_FAILED': { id: 'Error.Edit.StaticIPInDHCPSubnet', description: 'Error message to display when editing static IP in DHCP subnet failed', defaultMessage: 'Failed to edit static IP in DHCP subnet' },
    'E_EDIT_STATIC_IP_ALREADY_EXISTS': { id: 'Error.Edit.StaticIP.AlreadyExists', description: 'Error message to display when editing static IP failed because it is already exist', defaultMessage: 'Failed to edit static IP because it is already exist' },
    'E_EDIT_STATIC_IP_IN_DHCP_INAVLID_ARGUMENT': { id: 'Error.Edit.StaticIPInDHCP.InvalidArg', description: 'Error message to display when editing static IP in DHCP due to invalid arguments', defaultMessage: 'Failed to edit static IP in DHCP due to invalid argument(s)' },
    'E_EDIT_STATIC_IP_IN_DHCP_FAILED_TO_UPDATE_CONFIG': { id: 'Error.Edit.StaticIPInDHCP.UpdateConfig', description: 'Error message to display when editing static IP in DHCP subnet failed', defaultMessage: 'Failed to edit static IP in DHCP subnet' }, //FIXME
    'E_EDIT_STATIC_IP_IN_DHCP_GET_SUBNET_INFO_FAILED': { id: 'Error.Edit.StaticIPInDHCP.GetSubnetInfo', description: 'Error message to display when editing static IP in DHCP subnet failed', defaultMessage: 'Failed to edit static IP in DHCP subnet' }, //FIXME

    /**
     * RemoveStaticIPFromDhcpSubnetMutationResponse
     */
    'E_REMOVE_STATIC_IP_FROM_DHCP_SUBNET_FAILED': { id: 'Error.Remove.StaticIPFromDHCP.Subnet', description: 'Error message to display when removing static IP from DHCP subnet failed', defaultMessage: 'Failed to remove static IP from DHCP subnet' },
    'E_REMOVE_STATIC_IP_ALREADY_EXISTS': { id: 'Error.Remove.StaticIP.AlreadyExists', description: 'Error message to display when removing static IP because it is already exist', defaultMessage: 'Failed to remove static IP because it is already exist' },
    'E_REMOVE_STATIC_IP_FROM_DHCP_INAVLID_ARGUMENT': { id: 'Error.Remove.StaticIPFromDHCP.InvalidArg', description: 'Error message to display when removing static IP from DHCP due to invalid arguments', defaultMessage: 'Failed to remove static IP from DHCP due to invalid argument(s)' },
    'E_REMOVE_STATIC_IP_FROM_DHCP_FAILED_TO_UPDATE_CONFIG': { id: 'Error.Remove.StaticIPFromDHCP.UpdateConfig', description: 'Error message to display when removing static IP from DHCP subnet failed', defaultMessage: 'Failed to remove static IP from DHCP subnet' }, //FIXME
    'E_REMOVE_STATIC_IP_FROM_DHCP_GET_SUBNET_INFO_FAILED': { id: 'Error.Remove.StaticIPFromDHCP.GetSubnetInfo', description: 'Error message to display when removing static IP from DHCP subnet failed', defaultMessage: 'Failed to remove static IP from DHCP subnet' }, //FIXME

    /**
     * AddStaticIPToDhcpSubnetMutationResponse
     */
    'E_ADD_STATIC_IP_TO_DHCP_SUBNET_FAILED': { id: 'Error.Add.StaticIPToDHCP.Subnet', description: 'Error message to display when adding static IP to DHCP subnet failed', defaultMessage: 'Failed to add static IP to DHCP subnet' },
    'E_ADD_STATIC_IP_ALREADY_EXISTS': { id: 'Error.Add.StaticIP.AleadyExists', description: 'Error message to display when adding static IP failed because it is already exist', defaultMessage: 'Failed to add static IP because it is already exist' },
    'E_ADD_STATIC_IP_TO_DHCP_INAVLID_ARGUMENT': { id: 'Error.Add.StaticIPToDHCP.InvalidArg', description: 'Error message to display when adding static IP to DHCP failed due to invalid arguments', defaultMessage: 'Failed to add static IP due to invalid argument(s)' },
    'E_ADD_STATIC_IP_TO_DHCP_FAILED_TO_UPDATE_CONFIG': { id: 'Error.Add.StaticIPToDHCP.UpdateConfig', description: 'Error message to display when adding static IP to DHCP failed', defaultMessage: 'Failed to add static IP to DHCP' }, //FIXME
    'E_ADD_STATIC_IP_TO_DHCP_GET_SUBNET_INFO_FAILED': { id: 'Error.Add.StaticIPToDHCP.GetSubnetInfo', description: 'Error message to display when adding static IP to DHCP failed', defaultMessage: 'Failed to add static IP to DHCP' }, //FIXME

    /**
     * SaveDhcpSubnetMutationResponse
     */
    'E_SAVE_DHCP_SUBNET_FAILED': { id: 'Error.Save.DHCPSubnet', description: 'Error message to display when saving DHCP subnet failed', defaultMessage: 'Failed to save DHCP subnet' },
    'E_SAVE_DHCP_SUBNET_ALREADY_EXISTS': { id: 'Error.Save.DHCPSubnet.AlreadyExists', description: 'Error message to display when saving DHCP failed because it is already exist', defaultMessage: 'Failed to save DHCP subnet because it is already exist' },
    'E_SAVE_DHCP_SUBNET_INAVLID_ARGUMENT': { id: 'Error.Save.DHCPSubnet.InvalidArg', description: 'Error message to display when saving DHCP subnet failed due to invalid arguments', defaultMessage: 'Failed to save DHCP subnet due to invalid argument(s)' },
    'E_SAVE_DHCP_SUBNET_OVERLAP': { id: 'Error.Save.DHCPSubnet.OverLap', description: 'Error message to display when saving DHCP subnet failed due to overlap', defaultMessage: 'Failed to save DHCP subnet due to overlap' },
    'E_SAVE_DHCP_SUBNET_INTERFACE_DOESNT_EXIST': { id: 'Error.Save.DHCPSubnet.InterfaceDoesNotExist', description: 'Error message to display when saving DHCP subnet because the interface does not exist', defaultMessage: 'Failed to save DHCP subnet because the interface does not exist' },
    'E_SAVE_DHCP_SUBNET_FAILED_TO_UPDATE_CONFIG': { id: 'Error.Save.DHCPSubnet.UpdateConfig', description: 'Error message to display when saving DHCP subnet failed', defaultMessage: 'Failed to save DHCP subnet' }, //FIXME
    'E_SAVE_DHCP_SUBNET_FAILED_GET_SUBNET_INFO_FAILED': { id: 'Error.Save.DHCPSubnet.GetSubnetInfo', description: 'Error message to display when saving DHCP subnet failed', defaultMessage: 'Failed to save DHCP subnet' }, //FIXME

    /**
     * DeleteDhcpSubnetMutationResponse
     */
    'E_DELETE_DHCP_SUBNET_FAILED': { id: 'Error.Delete.DHCPSubnet', description: 'Error message to display when deleting DHCP subnet failed', defaultMessage: 'Failed to delete DHCP subnet' },
    'E_DELETE_DHCP_SUBNET_DOESNT_EXIST': { id: 'Error.Delete.DHCPSubnetDoesNotExist', description: 'Error message to display when deleting DHCP subnet because currently it does not exist', defaultMessage: 'Failed to delete DHCP subnet because currently it does not exist' },
    'E_DELETE_DHCP_SUBNET_INAVLID_ARGUMENT': { id: 'Error.Delete.DHCPSubnet.InvalidArg', description: 'Error message to display when deleting DHCP subnet due to invalid arguments', defaultMessage: 'Failed to delete DHCP subnet due to invalid argument(s)' },
    'E_DELETE__DHCP_SUBNET_FAILED_TO_UPDATE_CONFIG': { id: 'Error.Delete.DHCPSubnet.UpdateConfig', description: 'Error message to display when deleting DHCP subnet', defaultMessage: 'Failed to delete DHCP subnet' }, //FIXME

    /**
     * CreateDhcpSubnetMutationResponse
     */
    'E_CREATE_DHCP_SUBNET_FAILED': { id: 'Error.Create.DHCPSubnet', description: 'Error message to display when creating DHCP subnet failed', defaultMessage: 'Failed to create DHCP subnet' },
    'E_CREATE_DHCP_SUBNET_ALREADY_EXISTS': { id: 'Error.Create.DHCPSubnet.AlreadyExists', description: 'Error message to display when creating DHCP subnet failed because it is already exist', defaultMessage: 'Failed to create DHCP subnet because it is already exist' },
    'E_CREATE_DHCP_SUBNET_INAVLID_ARGUMENT': { id: 'Error.Create.DHCPSubnet.InvalidArg', description: 'Error message to display when creating DHCP subnet failed due to invalid arguments', defaultMessage: 'Failed to create DHCP subnet due to invalid argument(s)' },
    'E_CREATE_DHCP_SUBNET_OVERLAP': { id: 'Error.Create.DHCPSubnet.OverLap', description: 'Error message to display when creating DHCP subnet failed due to overlap', defaultMessage: 'Failed to create DHCP subnet due to overlap' },
    'E_CREATE_DHCP_SUBNET_INTERFACE_DOESNT_EXIST': { id: 'Error.Create.DHCPSubnet.InterfaceDoesNotExist', description: 'Error message to display when creating DHCP subnet failed due to the interface does not exist', defaultMessage: 'Failed to create DHCP subnet due to the interface does not exist' },
    'E_CREATE_DHCP_SUBNET_FAILED_TO_UPDATE_CONFIG': { id: 'Error.Create.DHCPSubnet.UpdateConfig', description: 'Error message to display when creating DHCP subnet failed', defaultMessage: 'Failed to create DHCP subnet' }, //FIXME
    'E_CREATE_DHCP_SUBNET_GET_SUBNET_INFO_FAILED': { id: 'Error.Create.DHCPSubnet.GetSubnetInfo', description: 'Error message to display when creating DHCP subnet failed', defaultMessage: 'Failed to create DHCP subnet' }, //FIXME

    /**
     * enabledisableFWChainMutationResponse
     */
    //'I_ENABLE_DISABLE_FW_CHAIN_SUCCESS': { id: '', description: '', defaultMessage: 'Failed to' }, //TODO
    'E_ENABLE_DISABLE_FW_CHAIN_FAILED': { id: 'Error.EnableDisable.FWChain', description: 'Error message to display when enable/disable FW chain failed', defaultMessage: 'Failed to enable/disable FW chain' },
    //E_FC_CHAIN_LIST_FAILED

    /**
     * RemoveFWChainMutationResponse
     */
    //'I_REMOVE_FW_CHAIN_SUCCESS': { id: '', description: '', defaultMessage: 'Failed to' }, //TODO
    'E_REMOVE_FW_CHAIN_FAILED': { id: 'Error.Remove.FWChain', description: 'Error message to display when removing the FW chain', defaultMessage: 'Failed to remove FW chain' },
    'E_INVALID_OPERATION': { id: 'Error.InvalidOperation', description: 'Error message to display when removing FW chain due to invalid operation', defaultMessage: 'Failed to remove FW chain due to invalid operation' },
    //E_FC_CHAIN_LIST_FAILED

    /**
     * AddFWChainMutationResponse
     */
    //'I_ADD_FW_CHAIN_SUCCESS': { id: '', description: '', defaultMessage: 'Failed to' }, //TODO
    'E_ADD_FW_CHAIN_FAILED': { id: 'Error.Add.FWChain', description: 'Error message to display when adding FW chain failed', defaultMessage: 'Failed to add FW chain' },
    //E_FC_CHAIN_LIST_FAILED

    /**
     * ChangeChainDefaultPolicyMutationResponse
     */
    //'I_CHANGE_POLICY_CHAIN_SUCCESS': { id: '', description: '', defaultMessage: 'Failed to' }, //TODO
    'E_CHANGE_POLICY_CHAIN_FAILED': { id: 'Error.Change.PolicyChain', description: 'Error message to display when changing the FW chain default policy failed', defaultMessage: 'Failed to change the FW chain default policy' },
    //E_FC_CHAIN_LIST_FAILED

    /**
     * EnableDisableFCRuleMutationResponse
     */
    //'I_ENABLE_DISABLE_FCRULE_SUCCESS': { id: '', description: '', defaultMessage: 'Failed to' }, //TODO
    'E_ENABLE_DISABLE_FCRULE_FAILED': { id: 'Error.EnableDisable.FCRule', description: 'Error message to display when enable/disable fule failed', defaultMessage: 'Failed to enable/disable rule' },
    'E_ENABLE_DISABLE_FCRULE_FAILED_INVALID_SEQ': { id: 'Error.EnableDisable.FCRule.InvalidSeq', description: 'Error message to display when enable/disable FCRule failed due to invalid sequence number', defaultMessage: 'Failed to enable/disable rule due to invalid sequence number' },

    /**
     * DeleteFCRuleMutationResponse
     */
    //'I_DELETE_FCRULE_SUCCESS': { id: '', description: '', defaultMessage: 'Failed to' }, //TODO
    'E_DELETE_FCRULE_FAILED': { id: 'Error.Delete.FCRule', description: 'Error message to display when deleting FCRule', defaultMessage: 'Failed to delete rule' },
    'E_DELETE_FCRULE_FAILED_INVALID_SEQ': { id: 'Error.Delete.FCRule.InvalidSeq', description: 'Error message to display when deleting FCRule due to invalid sequence number', defaultMessage: 'Failed to delete rule due to invalid sequence number' },

    /**
     * EditFCRuleMutationResponse
     */
    //'I_EDIT_FCRULE_SUCCESS': { id: '', description: '', defaultMessage: 'Failed to' }, //TODO
    'E_EDIT_FCRULE_FAILED': { id: 'Error.Edit.FCRule', description: 'Error message to display when editing FCRule failed', defaultMessage: 'Failed to edit rule' },
    'E_EDIT_FCRULE_FAILED_INVALID_SEQ': { id: 'Error.Edit.FCRule.InvalidSeq', description: 'Error message to display when editing FCRule failed due to invalid sequence number', defaultMessage: 'Failed to edit rule due to invalid sequence number' },
    //E_INVALID_ARGUMENT

    /**
     * AddFCRuleMutationResponse
     */
    //'I_ADD_FCRULE_SUCCESS': { id: '', description: '', defaultMessage: 'Failed to' }, //TODO
    'E_ADD_FCRULE_FAILED': { id: 'Error.Add.FCRule', description: 'Error message to display when adding FCRule failed', defaultMessage: 'Failed to add rule' },
    'E_ADD_FCRULE_FAILED_INVALID_SEQ': { id: 'Error.Add.FCRule.InvalidSeq', description: 'Error message to display when adding FCRule failed due to invalid sequence number', defaultMessage: 'Failed to add rule due to invalid sequence number' },
    //E_INVALID_ARGUMENT

    /**
     * UpdateBondMutationResponse
     */
    //'I_BOND_CONFIG_SUCCESS': { id: '', description: '', defaultMessage: 'Failed to' }, //TODO
    'E_BOND_CONFIG_FAILED': { id: 'Error.Bond.Config', description: 'Error message to display when updating the bond failed due to configuration', defaultMessage: 'Failed to update bond due to configuration' },
    'E_BOND_MODE_CURRENTLY_NOT_SUPPORTED': { id: 'Error.Bond.ModeCurrentlyNotSupported', description: 'Error message to display when updating the bond failed because the bond type is currently not supported', defaultMessage: 'Failed to update bond because the bond type is currently not supported' },
    //E_INVALID_ARGUMENT

    /**
     * DeleteBondMutationResponse
     */
    //'I_BOND_DELETION_SUCCESS': { id: '', description: '', defaultMessage: 'Failed to' }, //TODO
    'E_BOND_DOESNT_EXIST': { id: 'Error.BondDoesNotExist', description: 'Error message to display when deleting bond failed becuase currently it is not available', defaultMessage: 'Failed to delete bond due to unavailability' },
    'E_BOND_DELETION_FAILED': { id: 'Error.Delete.Bond', description: 'Error message to display when deleting the bond failed', defaultMessage: 'Failed to delete bond' },
    //E_INVALID_ARGUMENT

    /**
     * CreateBondMutationResponse
     */
    //'I_BOND_CREATION_SUCCESS': { id: '', description: '', defaultMessage: 'Failed to' }, //TODO
    'E_BOND_CREATION_FAILED': { id: 'Error.Create.Bond', description: 'Error message to display when creating bond failed', defaultMessage: 'Failed to create bond' },
    'E_GET_BOND_INFO_FAILED': { id: 'Error.Get.BondInfo', description: 'Error message to display when getting bond ', defaultMessage: 'Failed to get bond information' },
    'E_BOND_MODE_CURRENTLY_NOT_SUPPORTED': { id: 'Error.Bond.ModeCurrentlyNotSupported', description: 'Error message to display when creating bond failed', defaultMessage: 'Failed to create bond' }, //FIXME
    //E_INVALID_ARGUMENT

    /**
     * DNSLookupMutationResponse
     */
    'E_DNS_LOOKUP_FAILED': { id: 'Error.DNS.Lookup', description: 'Error message to display when DNS lookup failed', defaultMessage: 'DNS lookup failed' },
    'E_CONNECTION_REFUSED': { id: 'Error.Connection.Refused', description: 'Error message to display when connection refused', defaultMessage: 'Connection refused' },
    //E_INVALID_ARGUMENT

    /**
     * TracepathMutationResponse
     */
    'E_TRACEPATH_FAILED': { id: 'Error.TracePath', description: 'Error message to display when processing the trace path failed', defaultMessage: 'Failed to process the trace path' },
    //E_INVALID_ARGUMENT

    /**
     * PingMutationResponse
     */
    'E_PING_FAILED': { id: 'Error.Ping', description: 'Error message to display when processing the ping failed', defaultMessage: 'Failed to process the ping' },
    //E_INVALID_ARGUMENT

    /**
     * ARPCheckMutationResponse
     */
    'E_ARP_CHECK_FAILED': { id: 'Error.ARPCheck', description: 'Error message to display when procesing the ARP check failed', defaultMessage: 'Failed to process ARP check' },
    //E_INVALID_ARGUMENT

    /**
     * SetStatusNetBalancerConfigMutationResponse
     * SaveNetBalancerConfigMutationResponse
     */
    //'I_SAVE_NBCONFIG_SUCCESS': { id: '', description: '', defaultMessage: 'Failed to' }, //TODO
    'E_SAVE_NBCONFIG_FAILED': { id: 'Error.Save.NBConfig', description: 'Error message to display when saving the Net Balancer failed', defaultMessage: 'Failed to save Net Balancer config' },
    //E_INVALID_ARGUMENT

    /**
     * SetStatusNBGatewayMutationResponse
     */
    //'I_SET_STATUS_NBGATEWAY_SUCCESS': { id: '', description: '', defaultMessage: 'Failed to' }, //TODO
    'E_SET_STATUS_NBGATEWAY_FAILED': { id: 'Error.Set.Status.NBGateway', description: 'Error message to display when setting the gateway status failed', defaultMessage: 'Failed to set gateway status' },
    //E_GET_NGGATEWAY_INFO_FAILED
    //E_INVALID_ARGUMENT

    /**
     * UpdateNBGatewayMutationResponse
     */
    //'I_UPDATE_NBGATEWAY_SUCCESS': { id: '', description: '', defaultMessage: 'Failed to' }, //TODO
    'E_UPDATE_NBGATEWAY_FAILED': { id: 'Error.Update.NBGateway', description: 'Error message to display when updating gateway failed', defaultMessage: 'Failed to update gateway' },
    //E_GET_NGGATEWAY_INFO_FAILED
    //E_INVALID_ARGUMENT

    /**
     * RemoveNBGatewayMutationResponse
     */
    //'I_REMOVE_NBGATEWAY_SUCCESS': { id: '', description: '', defaultMessage: 'Failed to' }, //TODO
    'E_REMOVE_NBGATEWAY_FAILED': { id: 'Error.Remove.NBGateway', description: 'Error message to display when removing gateway', defaultMessage: 'Failed to remove gateway' },
    //E_INVALID_ARGUMENT

    /**
     * AddNBGatewayMutationResponse
     */
    //'I_ADD_NGGATEWAY_SUCCESS': { id: '', description: '', defaultMessage: 'Failed to' }, //TODO
    'E_ADD_NGGATEWAY_FAILED': { id: 'Error.Add.NBGateway', description: 'Error message to display when adding gateway failed', defaultMessage: 'Failed to add gateway' },
    //E_GET_NGGATEWAY_INFO_FAILED
    //E_INVALID_ARGUMENT

    /**
     * EnableNatInterfacesMutationResponse
     */
    //'I_ENABLE_NAT_INTERFACES_SUCCESS': { id: '', description: '', defaultMessage: 'Failed to' }, //TODO
    'E_ENABLE_NAT_INTERFACES_FAILED': { id: 'Error.Enable.NATInterfaces', description: 'Error message to display when enabling/disabling NAT interfaces failed', defaultMessage: 'Failed to enable/disable NAT interfaces' },
    'E_GET_NAT_INTERFACES_FAILED': { id: 'Error.Get.NATInterfaces', description: 'Error message to display when getting NAT interfaces', defaultMessage: 'Failed to get NAT interface(s)' },

    /**
     * SetDefaultGatewayMutationResponse
     */
    //'I_SET_DEFAULT_GATEWAY_SUCCESS': { id: '', description: '', defaultMessage: 'Failed to' }, //TODO
    'E_SET_DEFAULT_GATEWAY_FAILED': { id: 'Error.Set.DefaultGateway', description: 'Error message to display when setting default gateway failed', defaultMessage: 'Failed to set default gateway' },
    'E_DEFAULT_ROUTE_ADLREADY_ACTIVE': { id: 'Error.DefaultRoute.AlreadyActive', description: 'Error message to display when setting default route because already it is active', defaultMessage: 'Failed to set default route because already it is active' },

    /**
     * DelStaticRouteMutationResponse
     */
    //'I_DEL_STATIC_ROUTE_SUCCESS': { id: '', description: '', defaultMessage: 'Failed to' }, //TODO
    'E_DEL_STATIC_ROUTE_FAILED': { id: 'Error.Delete.StaticRoute', description: 'Error message to display when deleting static route failed', defaultMessage: 'Failed to delete static route' },
    'E_ROUTE_ENTRY_NOT_PRESENT': { id: 'Error.Route.EntryNotPresent', description: 'Error message to display when deleting static route because currently it is not available', defaultMessage: 'Failed to delete static route because currently it is not available' },
    //E_INVALID_ARGUMENT

    /**
     * AddStaticRouteMutationResponse
     */
    //'I_ADD_STATIC_ROUTE_SUCCESS': { id: '', description: '', defaultMessage: 'Failed to' }, //TODO
    'E_ADD_STATIC_ROUTE_FAILED': { id: 'Error.Add.StaticRoute', description: 'Error message to display when adding static route failed', defaultMessage: 'Failed to add static route' },
    'E_GET_ROUTE_INFO_FAILED': { id: 'Error.Get.RouteInfo', description: 'Error message to display when getting static route info failed', defaultMessage: 'Failed to get static route information' },
    //E_INVALID_ARGUMENT

    /**
     * UpdateBridgeMutationResponse
     */
    //'I_BRIDGE_CONFIG_SUCCESS': { id: '', description: '', defaultMessage: 'Failed to' }, //TODO
    'E_BRIDGE_CONFIG_FAILED': { id: 'Error.Bridge.ConfigFailed', description: 'Error to dispaly when Bridge update failed', defaultMessage: 'Bridge update config failed' },
    //E_INVALID_ARGUMENT

    /**
     * DeleteBridgeMutationResponse
     */
    //'I_BRIDGE_DELETION_SUCCESS': { id: '', description: '', defaultMessage: 'Failed to' }, //TODO
    'E_BRIDGE_DELETION_FAILED': { id: 'Error.Bridge.DeletionFailed', description: 'Error to display when Bridge deletion failed', defaultMessage: 'Bridge deletion failed' },
    //E_INVALID_ARGUMENT

    /**
     * CreateBridgeMutationResponse
     */
    //'I_BRIDGE_CREATION_SUCCESS': { id: '', description: '', defaultMessage: 'Failed to' }, //TODO
    'E_BRIDGE_CREATION_FAILED': { id: 'Error.Bridge.CreationFailed', description: 'Error to display when Bridge creation failed', defaultMessage: 'Bridge creation failed' },
    'E_GET_BRIDGE_INFO_FAILED': { id: 'Error', description: 'Error message to display when getting bridge info failed', defaultMessage: 'Failed to get bridge information' },
    //E_INVALID_ARGUMENT

    /**
     * DeleteOpenVPNMutationResponse
     */
    //'I_VPN_TUNNEL_DELETED': { id: '', description: '', defaultMessage: 'Failed to' }, //TODO
    //'E_VPN_INTERFACE_PART_OF_BRIDGE_OR_BOND': { id: 'Error.VPNInterface.PartOfBridgeOrBond', description: 'Error message to display when', defaultMessage: 'Failed to' }, //FIXME
    'E_VPN_TUNNEL_DELETION_FAILED': { id: 'Error.VPN.DeletionFailed', description: 'Error message to display when deleting VPN failed', defaultMessage: 'Deleting VPN tunnel failed' },

    /**
     * CreateOpenVPNMutationResponse
     */
    //'I_VPN_TUNNEL_CREATED': { id: '', description: '', defaultMessage: 'Failed to' }, //TODO
    'E_VPN_ALREADY_USES_PORT_PROTO': { id: 'Error.VPN.PortAlreadyInUse', description: 'VPN Port already in use while createing new VPN', defaultMessage: 'VPN Port Already in Use' },
    'E_GET_VPN_INFO_FAILED': { id: 'Error.VPN.GetFailed', description: 'Error message to display when getting VPN information failed', defaultMessage: 'Getting VPN information failed' },
    'E_VPN_TUNNEL_CREATION_FAILED': { id: 'Error.VPN.CreationFailed', description: 'Error message to display when creating VPN failed', defaultMessage: 'Creating VPN tunnel failed' },
    //E_INVALID_ARGUMENT

    /**
     * EditVLANMutationResponse
     */
    'E_FAILED_TO_EDIT_VLAN': { id: 'Error.Edit.VLAN', description: 'Error message to display when editing VLAN failed', defaultMessage: 'Failed to edit VLAN' },
    'E_EDIT_VLAN_INAVLID_ARGUMENT': { id: 'Error.Edit.VLAN.InvalidArg', description: 'Error message to display when editing VLAN failed due to invalid argument', defaultMessage: 'Failed to edit VLAN due to invalid argument(s)' },
    'E_EDIT_VLAN_IP_INVALID_INTERFACE': { id: 'Error.Edit.VLAN.IPInvalidInterface', description: 'Error message to display when editing VALN failed due to invalid interface', defaultMessage: 'Failed to edit VLAN due to invalid interface' },
    'E_EDIT_VLAN_FAILED_TO_UPDATE_CONFIG': { id: 'Error.Edit.VLAN.UpdateConfig', description: 'Error message to display when editing VLAN failed', defaultMessage: 'Failed to edit VLAN' }, //FIXME
    'E_EDIT_VLAN_IN_USE': { id: 'Error.Edit.VLAN.InUse', description: 'Error message to display when editing VLAN failed because it is currently in use', defaultMessage: 'Failed to edit VLAN because it is currently in use' },

    /**
     * DeleteVLANMutationResponse
     */
    'E_FAILED_TO_DELETE_VLAN': { id: 'Error.Delete.VLAN', description: 'Error message to display when deleting VLAN failed', defaultMessage: 'Failed to delete VLAN' },
    'E_DELETE_VLAN_INAVLID_ARGUMENT': { id: 'Error.Delete.VLAN.InvalidArg', description: 'Error message to display when deleting VLAN failed due to invalid argument', defaultMessage: 'Failed to delete VLAN due to invalid argument(s)' },
    'E_DELETE_VLAN_IP_INVALID_INTERFACE': { id: 'Error.Delete.VLAN.IPInvalidInterface', description: 'Error message to display when deleting failed due to invalid interface', defaultMessage: 'Failed to delete VLAN due to invalid interface' },
    'E_DELETE_VLAN_FAILED_TO_UPDATE_CONFIG': { id: 'Error.Delete.VLAN.UpdateConfig', description: 'Error message to display when deleting VLAN failed', defaultMessage: 'Failed to delete VLAN' }, //FIXME
    'E_DELETE_VLAN_IN_USE': { id: 'Error.Delete.VLAN.InUse', description: 'Error message to display when deleting VLAN failed because it is currently in use', defaultMessage: 'Failed to delete VLAN because it is currently in use' },

    /**
     * CreateVLANMutationResponse
     */
    'E_FAILED_TO_CREATE_VLAN': { id: 'Error.Create.VLAN', description: 'Error message to display when creating VLAN failed', defaultMessage: 'Failed to create VLAN' },
    'E_CREATE_VLAN_INAVLID_ARGUMENT': { id: 'Error.Create.VLAN.InvalidArg', description: 'Error message to display when creating VLAN failed due to invalid argument', defaultMessage: 'Failed to create VLAN due to invalid argument(s)' },
    'E_CREATE_VLAN_IP_INVALID_INTERFACE': { id: 'Error.Create.VLAN.IPInvalidInterface', description: 'Error message to display when creating VLAN failed due to invalid interface', defaultMessage: 'Failed to create VLAN due to invalid interface' },
    'E_CREATE_VLAN_FAILED_TO_UPDATE_CONFIG': { id: 'Error.Create.VLAN.UpdateConfig', description: 'Error message to display when creating VLAN failed', defaultMessage: 'Failed to create VLAN' }, //FIXME
    'E_CREATE_VLAN_MAX_LIMIT_REACHED': { id: 'Error.Create.VLAN.MaxLimitReached', description: 'Error message to display when creating VLAN failed becuase it reaches the maximum limit', defaultMessage: 'Failed to create VLAN due to maximum limit' },
    'E_CREATE_VLAN_INVALID_TAG_ID': { id: 'Error.Create.VLAN.InvalidTagID', description: 'Error message to display when creating VLAN due to invalid tag Id', defaultMessage: 'Failed to create VLAN due to invalid tag Id' },

    /**
     * ReleaseDHCPIfcMutationResponse
     */
    'E_FAILED_TO_RELEASE_DHCP': { id: 'Error.ReleaseDHCP.Failed', description: 'Error to display when releasing DHCP failed', defaultMessage: 'Releasing DHCP failed' },
    'E_RELEASE_DHCP_INAVLID_ARGUMENT': { id: 'Error.ReleaseDHCP.InvalidArgument', description: 'Error to display when releasing DHCP, Invalid argument', defaultMessage: 'Error releasing DHCP : Invalid argument' },
    'E_RELEASE_DHCP_IP_INVALID_INTERFACE': { id: 'Error.ReleaseDHCP.InvalidInterface', description: 'Error to display when releasing DHCP, invalid interface', defaultMessage: 'Error releasing DHCP : Invalid interface' },
    'E_RELEASE_DHCP_FAILED_TO_CONNECT_DHCP_SERVER': { id: 'Error.ReleaseDHCP.InvalidInterface', description: 'Error to display when releasing DHCP, failed to connect to DHCP server', defaultMessage: 'Error releasing DHCP : Failed to connect to DHCP' },
    'E_RELEASE_DHCP_FAILED_TO_UPDATE_CONFIG': { id: 'Error.ReleaseDHCP.FailedToUpdate', description: 'Error to display when releasing DHCP, Failed to update config', defaultMessage: 'Error releasing DHCP : Failed to update config' },
    'E_RELEASE_DHCP_INTERFACE_IS_DOWN': { id: 'Error.ReleaseDHCP.InterfaceDown', description: 'Error to display when releasing DHCP, Interface down', defaultMessage: 'Error releasing DHCP : Interface Down' },

    /**
     * RenewDHCPIfcMutationResponse
     */
    'E_FAILED_TO_RENEW_DHCP': { id: 'Error.RenewDHCP.Failed', description: 'Error to display when renewing DHCP failed', defaultMessage: 'Renewing DHCP failed' },
    'E_RENEW_DHCP_INAVLID_ARGUMENT': { id: 'Error.RenewDHCP.InvalidArgument', description: 'Error to display when renewing DHCP, Invalid argument', defaultMessage: 'Error renewing DHCP : Invalid argument' },
    'E_RENEW_DHCP_IP_INVALID_INTERFACE': { id: 'Error.RenewDHCP.InvalidInterface', description: 'Error to display when renewing DHCP, invalid interface', defaultMessage: 'Error renewing DHCP : Invalid interface' },
    'E_RENEW_DHCP_FAILED_TO_CONNECT_DHCP_SERVER': { id: 'Error.RenewDHCP.InvalidInterface', description: 'Error to display when renewing DHCP, failed to connect to DHCP server', defaultMessage: 'Error renewing DHCP : Failed to connect to DHCP' },
    'E_RENEW_DHCP_FAILED_TO_UPDATE_CONFIG': { id: 'Error.RenewDHCP.FailedToUpdate', description: 'Error to display when renewing DHCP, Failed to update config', defaultMessage: 'Error renewing DHCP : Failed to update config' },
    'E_RENEW_DHCP_INTERFACE_IS_DOWN': { id: 'Error.RenewDHCP.InterfaceDown', description: 'Error to display when renewing DHCP, Interface down', defaultMessage: 'Error renewing DHCP : Interface Down' },

    /**
     * DisableDHCPIfcMutationResponse
     */
    'E_FAILED_TO_DISABLE_DHCP': { id: 'Error.DisableDHCP.Failed', description: 'Error to display when disabling DHCP failed', defaultMessage: 'Disabling DHCP failed' },
    'E_DISABLE_DHCP_INAVLID_ARGUMENT': { id: 'Error.DisableDHCP.InvalidArgument', description: 'Error to display when disabling DHCP, Invalid argument', defaultMessage: 'Error disabling DHCP : Invalid argument' },
    'E_DISABLE_DHCP_IP_INVALID_INTERFACE': { id: 'Error.DisableDHCP.InvalidInterface', description: 'Error to display when disabling DHCP, invalid interface', defaultMessage: 'Error disabling DHCP : Invalid interface' },
    'E_DISABLE_DHCP_IP_IN_USE': { id: 'Error.DisableDHCP.InUse', description: 'Error to display when disabling DHCP, IP in use', defaultMessage: 'Error disabling DHCP : Ip address in use' },
    'E_DISABLE_DHCP_FAILED_TO_UPDATE_CONFIG': { id: 'Error.DisableDHCP.FailedToUpdate', description: 'Error to display when disabling DHCP, Failed to update config', defaultMessage: 'Error disabling DHCP : Failed to update config' },

    /**
     * EnableDHCPIfcMutationResponse
     */
    'E_FAILED_TO_ENABLE_DHCP': { id: 'Error.EnableDHCP.Failed', description: 'Error to display when enabling DHCP failed', defaultMessage: 'Enabling DHCP failed' },
    'E_ENABLE_DHCP_INAVLID_ARGUMENT': { id: 'Error.EnableDHCP.InvalidArgument', description: 'Error to display when enabling DHCP, Invalid argument', defaultMessage: 'Error enabling DHCP : Invalid argument' },
    'E_ENABLE_DHCP_IP_INVALID_INTERFACE': { id: 'Error.EnableDHCP.InvalidInterface', description: 'Error to display when enabling DHCP, invalid interface', defaultMessage: 'Error enabling DHCP : Invalid interface' },
    'E_ENABLE_DHCP_FAILED_TO_CONNECT_DHCP_SERVER': { id: 'Error.EnableDHCP.InvalidInterface', description: 'Error to display when enabling DHCP, failed to connect to DHCP server', defaultMessage: 'Error enabling DHCP : Failed to connect to DHCP' },
    'E_ENABLE_DHCP_FAILED_TO_UPDATE_CONFIG': { id: 'Error.EnableDHCP.FailedToUpdate', description: 'Error to display when enabling DHCP, Failed to update config', defaultMessage: 'Error enabling DHCP : Failed to update config' },
    'E_ENABLE_DHCP_INTERFACE_IS_DOWN': { id: 'Error.EnableDHCP.InterfaceDown', description: 'Error to display when enabling DHCP, Interface down', defaultMessage: 'Error enabling DHCP : Interface Down' },

    /**
     * SetInterfaceStateMutationResponse
     */

    'E_SET_INTERFACE_STATE_FAILED': { id: 'Error.SetInterfaceState.Failed', description: 'Error to display when setting interface state IP failed', defaultMessage: 'Setting interface state failed' },
    'E_SET_INTERFACE_STATE_IP_IN_USE': { id: 'Error.SetInterfaceState.InUse', description: 'Error to display when setting interface state IP, IP address in use', defaultMessage: 'Error setting interface state : IP Address in use' },
    'E_SET_INTERFACE_STATE_INAVLID_ARGUMENT': { id: 'Error.SetInterfaceState.InvalidArgument', description: 'Error to display when setting interface state IP, invalid argument', defaultMessage: 'Error setting interface state : Invalid argument' },
    'E_SET_INTERFACE_STATE_IP_INVALID_INTERFACE': { id: 'Error.SetInterfaceState.InvalidInterface', description: 'Error to display when setting interface state IP, invalid interface', defaultMessage: 'Error setting interface state : Invalid Interface' },
    'E_SET_INTERFACE_STATE_FAILED_TO_UPDATE_CONFIG': { id: 'Error.SetInterfaceState.FailedToUpdate', description: 'Error to display when setting interface state IP, Failed to Update', defaultMessage: 'Error setting interface state : Failed to update config' },

    /**
     * ModifyIPIfcMutationResponse
     */

    'E_MODIFY_IP_FAILED': { id: 'Error.ModifyIP.Failed', description: 'Error to display when modifying IP failed', defaultMessage: 'modifying IP failed' },
    'E_MODIFY_IP_IN_USE': { id: 'Error.ModifyIP.InUse', description: 'Error to display when modifying IP, IP address in use', defaultMessage: 'Error modifying IP : IP Address in use' },
    'E_MODIFY_IP_INAVLID_ARGUMENT': { id: 'Error.ModifyIP.InvalidArgument', description: 'Error to display when modifying IP, invalid argument', defaultMessage: 'Error modifying IP : Invalid argument' },
    'E_MODIFY_IP_INVALID_INTERFACE': { id: 'Error.ModifyIP.InvalidInterface', description: 'Error to display when modifying IP, invalid interface', defaultMessage: 'Error modifying IP : Invalid Interface' },
    'E_MODIFY_IP_FAILED_TO_UPDATE_CONFIG': { id: 'Error.ModifyIP.FailedToUpdate', description: 'Error to display when modifying IP, Failed to Update', defaultMessage: 'Error modifying IP : Failed to update config' },
    'E_MODIFY_IP_ALREADY_EXISTS': { id: 'Error.ModifyIP.AlreadyExist', description: 'Error to display when IP Already exist', defaultMessage: 'Error modifying IP : IP Address Already Exists' },
    'E_MODIFY_IP_INTERFACE_DOESNT_EXIST': { id: 'Error.ModifyIP.InterfaceDoesNotExist', description: 'Error to display when modifying IP, Interface does not exist', defaultMessage: 'Error modifying IP : Interface does not exist' },
    'E_MODIFY_IP_DOESNT_EXIST': { id: 'Error', description: 'Error message to display when modifying IP does not exist', defaultMessage: 'Failed to modify IP due to unavailable' },
    'E_MODIFY_IP_INVALID_ID': { id: 'Error.ModifyIP.InvalidId', description: 'Error to display when modifying IP, Invalid Id', defaultMessage: 'Error modifying IP : Invalid ID' },

    /**
     * RemoveIPIfcMutationResponse
     */

    'E_REMOVE_IP_FAILED': { id: 'Error.RemoveIP.Failed', description: 'Error to display when removing IP failed', defaultMessage: 'Removing IP failed' },
    'E_REMOVE_IP_IN_USE': { id: 'Error.RemoveIP.InUse', description: 'Error to display when removing IP, IP address in use', defaultMessage: 'Error removing IP : IP Address in use' },
    'E_REMOVE_IP_INAVLID_ARGUMENT': { id: 'Error.RemoveIP.InvalidArgument', description: 'Error to display when removing IP, invalid argument', defaultMessage: 'Error removing IP : Invalid argument' },
    'E_REMOVE_IP_INVALID_INTERFACE': { id: 'Error.RemoveIP.InvalidInterface', description: 'Error to display when removing IP, invalid interface', defaultMessage: 'Error removing IP : Invalid Interface' },
    'E_REMOVE_IP_FAILED_TO_UPDATE_CONFIG': { id: 'Error.RemoveIP.FailedToUpdate', description: 'Error to display when removing IP, Failed to Update', defaultMessage: 'Error removing IP : Failed to update config' },
    'E_REMOVE_IP_INTERFACE_DOESNT_EXIST': { id: 'Error.RemoveIP.InterfaceDoesNotExist', description: 'Error to display when removing IP, Interface does not exist', defaultMessage: 'Error removing IP : Interface does not exist' },
    'E_REMOVE_IP_DOESNT_EXIST': { id: 'Error', description: 'Error message to display when removing IP does not exist', defaultMessage: 'Failed to remove IP due to unavailable' },
    'E_REMOVE_IP_INVALID_ID': { id: 'Error.RemoveIP.InvalidId', description: 'Error to display when removing IP, Invalid Id', defaultMessage: 'Error removing IP : Invalid ID' },

    /**
     * AddIPIfcMutationResponse
     */

    'E_ADD_IP_FAILED': { id: 'Error', description: 'Error message to display when adding IP failed', defaultMessage: 'Failed to Add IP' },
    'E_ADD_IP_ALREADY_EXISTS': { id: 'Error.AddIP.AlreadyExist', description: 'Error to display when IP Already exist', defaultMessage: 'Error Adding IP : IP address Already Exists' },
    'E_ADD_IP_INAVLID_ARGUMENT': { id: 'Error.AddIP.AlreadyExist', description: 'Error to display when IP provided Invalid Argument', defaultMessage: 'Error adding IP : Invalid argument' },
    'E_ADD_IP_INVALID_INTERFACE': { id: 'Error.AddIP.AlreadyExist', description: 'Error to display when invalid interface provided', defaultMessage: 'Error adding IP : Invalid Interface' },
    'E_ADD_IP_OVERLAP': { id: 'Error.AddIP.AlreadyExist', description: 'Error to display when IP overlap', defaultMessage: 'Error adding IP : IP Overlap' },
    'E_ADD_IP_INTERFACE_DOESNT_EXIST': { id: 'Error.AddIP.AlreadyExist', description: 'Error to display when interface does not exist', defaultMessage: 'Error adding IP : Interface does not exist' },
    'E_ADD_IP_FAILED_TO_UPDATE_CONFIG': { id: 'Error.AddIP.AlreadyExist', description: 'Error to display when IP failed to update', defaultMessage: 'Error adding IP : Failed to update config' },

    /**
     * importCRLMutationResponse
     */
    //'I_IMPORT_CRL_SUCCESS': { id: '', description: '', defaultMessage: 'Failed to' }, //TODO
    'E_IMPORT_CRL_FAILED': { id: 'Error.Import.CRL', description: 'Error message to display when importing CRL failed', defaultMessage: 'Failed to import CRL' },
    'E_MATCHING_CA_CERTIFICATE_NOT_AVAILABLE': { id: 'Error.Matching.CACertificateNotAvailable', description: 'Error message to display when matching CA certificate is not available', defaultMessage: 'Failed to import due to matching CA certificate is not available' },
    'E_INVALID_CRL': { id: 'Error.Invalid.CRL', description: 'Error message to display when importing invalid CRL', defaultMessage: 'Failed to import due to invalid CRL' },
    //E_GET_CERTIFICATE_FAILED

    /**
     * RemoveCertificateMutationResponse
     */

    //'I_REMOVE_CERTIFICATE_SUCCESS': { id: '', description: '', defaultMessage: 'Failed to' }, //TODO
    'E_CERTIFICATE_NOT_AVAILABLE': { id: 'Error.Cert.NotAvailable', description: 'Error message to display when removing unavailable certifcate', defaultMessage: 'Deleting certificate failed, Certificate not available' },
    'E_INVALID_CERTIFICATE_ID': { id: 'Error.Cert.InvalidId', description: 'Error message to display when removing invalid id certifcate', defaultMessage: 'Deleting certificate failed, Invalid Certificate Id' },
    'E_REMOVE_CERTIFICATE_FAILED': { id: 'Error.Cert.RemoveFailed', description: 'Error message to display when removing certifcate failed', defaultMessage: 'Deleting certificate failed' },
    //E_INVALID_OPERTATION

    /**
     * ImportCertificateMutationResponse
     */

    //'I_IMPORT_CERTIFICATE_SUCCESS': { id: '', description: '', defaultMessage: 'Failed to' }, //TODO
    'E_INVALID_CERTIFICATE': { id: 'Error.Cert.Invalid', description: 'Error message to display when importing invalid certifcate', defaultMessage: 'Invalid certificate' },
    'E_IMPORT_CERTIFICATE_FAILED': { id: 'Error.Cert.ImportFailed', description: 'Error message to display when importing certifcate failed', defaultMessage: 'Importing certificate failed' },
    //E_GET_CERTIFICATE_FAILED

    /**
     * saveSshAclMutationResponse
     */

    'E_SAVE_SSH_ACL_FAILED': { id: 'Error.Save.SSH.ACLFailed', description: 'Error message to display when saving SSH ACL failed', defaultMessage: 'Failed to save SSH ACL' },
    'E_SAVE_SSH_ACL_INAVLID_ARGUMENT': { id: 'Error.Save.SSH.ACLInvalidArg', description: 'Error message to display when saving SSH ACL failed due to invalid argument', defaultMessage: 'Failed to SSH ACL due to invalid argument(s)' },
    'E_SAVE_SSH_ACL_INVALID_INTERFACE': { id: 'Error.Save.SSH.ACLInvalidInterface', description: 'Error message to display when saving SSH ACL failed due to invalid interfaces', defaultMessage: 'Failed to save SSH ACL due to invalid interfaces' },
    'E_SAVE_SSH_ACL_OVERLAP': { id: 'Error.Save.SSH.ACLOverLap', description: 'Error message to display when saving SSH ACL failed due to overlap', defaultMessage: 'Failed to save SSH ACL due to overlap' },
    'E_SAVE_SSH_ACL_INTERFACE_DOESNT_EXIST': { id: 'Error.Save.SSH.ACLInterfaceDoesNotExist', description: 'Error message to display when saving SSH ACL failed due to interface does not exist', defaultMessage: 'Failed to save SSH ACL due to interface does not exist' },
    'E_SAVE_SSH_ACL_INVALID_SUBNET': { id: 'Error.Save.SSH.ACLInvalidSubnet', description: 'Error message to display when saving SSH ACL failed due to invalid subnet', defaultMessage: 'Failed to save SSH ACL due to invalid subnet' },

    /**
     * setNTPMutationResponse
     * SetTimeMutationResponse
     */

    //'I_SET_TIME_SUCCESS': { id: '', description: '', defaultMessage: 'Failed to' }, //TODO
    'E_SET_TIME_FAILED': { id: 'Error.Set.Time', description: 'Error message to display when setting the time failed', defaultMessage: 'Failed to set time' },
    'E_GET_TIME_FAILED': { id: 'Error.Get.Time', description: 'Error message to display when getting time failed', defaultMessage: 'Failed to get time' },
    //E_INVALID_ARGUMENT

    /**
     * SetHWCTimeScaleMutationResponse
     */

    //'I_SET_HWCLOCK_TIMESCALE_SUCCESS': { id: '', description: '', defaultMessage: 'Failed to' }, //TODO
    'E_SET_HWCLOCK_TIMESCALE_FAILED': { id: 'Error.Set.HWClock.TimeScale', description: 'Error message to display when setting Hardware Clock failed', defaultMessage: 'Failed to set hardware clock' },
    //E_INVALID_ARGUMENT

    /**
     * SetTimeZoneMutationResponse
     */

    //'I_SET_TIMEZONE_SUCCESS': { id: '', description: '', defaultMessage: 'Failed to' }, //TODO
    'E_SET_TIMEZONE_FAILED': { id: 'Error.Set.TimeZone', description: 'Error message to display when setting TimeZone failed', defaultMessage: 'Failed to set TimeZone' },
    'E_GET_TIMEZONE_INFO_FAILED': { id: 'Error.Get.TimeZoneInfo', description: 'Error message to display when getting TimeZone info failed', defaultMessage: 'Failed to get TimeZone Information' },
    //E_INVALID_ARGUMENT

    'E_ASSIGN_PLATFORMS_TO_USER_FAILED': { id: 'Error.Assign.Platform', description: 'Error message to display when assign platform failed', defaultMessage: 'Failed to assign platform' },
    'E_ASSIGN_PLATFORMS_TO_USER_ERROR': { id: 'Error.Assign.Platform', description: 'Error message to display when assign platform failed', defaultMessage: 'Failed to assign platform' }
});

export default messages;